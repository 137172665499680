import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { updateSale } from '../../api/sales';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { InfoModalContent } from '../InfoModalContent';
import { newSaleHeader, validateSaleData } from '../../config/sales';

export const SaleDetailsModal = ({ show, onClose, data: venteData }) => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const queryClient = useQueryClient();

    const [updateData, setUpdateData] = useState();

    useEffect(() => {
        if (venteData) setUpdateData({ ...venteData });
        else setUpdateData(null);
    }, [venteData]);

    const closeAndReset = () => {
        setSubmitLoading(false);
        onClose();
    };

    // UPDATE SALE
    const updateMutation = useMutation(updateSale, {
        onSuccess: (data) => {
            console.log('update successful: ', data);
            queryClient.invalidateQueries('sales');
            toast.success('Vente a été modifié avec succès!');
            closeAndReset();
        },
        onError: (err) => {
            console.log('update failed with error: ', err.response);
            toast.error('update failed with error: ' + JSON.stringify(err.response?.data));
            closeAndReset();
        },
    });

    const buttons = (
        <>
            <Button
                onClick={() => {
                    setUpdateData(venteData);
                    onClose();
                }}
                label={'Annuler'}
            />
            <Button
                variant="green-full"
                onClick={() => {
                    setSubmitLoading(true);
                    updateMutation.mutate(validateSaleData(updateData));
                }}
                label={submitLoading ? 'En cours ...' : 'confirmer'}
                loading={submitLoading}
                disabled={submitLoading}
            />
        </>
    );

    const updateClient_saleDetails = (newClient) => {
        setUpdateData((oldData) => ({
            ...oldData,
            client: newClient,
            client__name: newClient?.name,
        }));
    };

    const getHeaders_saleDetails = () => {
        const newHeaders = [...newSaleHeader];
        newHeaders.find((el) => el.accessor === 'client__name').onLineChange =
            updateClient_saleDetails;
        return newHeaders;
    };

    return (
        <Modal
            closeOnOutsideClick
            show={show}
            onClose={closeAndReset}
            title={'Informations de vente'}
            buttons={buttons}
        >
            <InfoModalContent
                write
                data={updateData}
                setData={setUpdateData}
                lines={getHeaders_saleDetails()}
            />
        </Modal>
    );
};
