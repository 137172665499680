import { authAxios } from '../auth';

export const getProducts = async ({ pageParam, queryKey }) => {
    const queryString = queryKey[1]?.length > 1 ? queryKey[1] : '';
    const res = await authAxios(`/products/?page_size=50&page=${pageParam || 1}${queryString}`);
    return res?.data;
};

export const addProduct = async (newProduct) => {
    const res = await authAxios.post('/products/', newProduct);
    return res?.data;
};

export const updateProduct = async (productData) => {
    const res = await authAxios.put(`/products/${productData.id_product}/`, productData);
    return res?.data;
};

export const deleteProduct = async (productID) => {
    const res = await authAxios.delete(`/products/${productID}/`);
    return res;
};

export const getPaniers = async () => {
    const res = await authAxios(`/paniers/`);
    return res?.data;
};

export const updatePaniers = async (panierData) => {
    const id = panierData.id_panier;
    delete panierData.id_panier;
    const res = await authAxios.put(`/paniers/${id}/`, panierData);
    return res?.data;
};

export const addPanier = async (newPanier) => {
    const res = await authAxios.post('/paniers/', newPanier);
    return res?.data;
};
