import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { getFacture } from '../../api/factures';
import { PrintButton } from '../../components/PrintButton';
import { formatDate, getDisplayValue } from '../../utils';
import { NumberToLetter } from 'convertir-nombre-lettre';
import { Logos } from '../../components/Logos';
import { FootersInfo } from '../../components/FootersInfo';

const spellNumber = (num) => {
    const parts = (num + '').split('.');
    let result = '';
    if (parts.length > 1) {
        const centsPart = parts[1].length === 1 ? parseInt(parts[1]) * 10 : parseInt(parts[1]);
        result =
            NumberToLetter(parts[0]) + ' dirhams et ' + NumberToLetter(centsPart) + ' CTS TTC ----';
    } else result = NumberToLetter(parts[0]) + ' dirhams TTC ----';
    return result.charAt(0).toUpperCase() + result.slice(1);
};

const StyledFacture = styled.div`
    width: 210mm;
    margin: auto;
    box-shadow: 0px 0px 200px 0px rgba(0, 0, 0, 0.25);
    min-height: 100vh;
    @page {
        size: A4;
        margin: 2mm;
    }
    .page-header {
        height: 155px;
        display: flex;
        flex-direction: column;
        .main-header {
            flex: 1;
            display: flex;
            .left-part {
                flex: 1;
            }
            .right-part {
                padding: 24px;
                text-align: right;
                .date-container {
                    font-weight: bold;
                    margin-bottom: 16px;
                }
                .facture-number {
                    font-weight: bold;
                    font-size: 24px;
                }
            }
        }
        > div {
            display: flex;
            align-items: center;
            border-top: 1px solid lightgray;
        }
        .client-block {
            justify-content: center;
            padding: 4px 0;
            font-weight: bold;
            font-size: 14px;
            .client-title {
                opacity: 0.5;
                margin-right: 16px;
                display: inline-block;
            }
        }
    }
    .page-header-space {
        height: 200px;
    }

    .page-footer {
        height: 50px;
    }
    .page-footer-space {
        height: 80px;
    }

    .page-footer {
        position: fixed;
        bottom: 0;
        width: 210mm;

        border-top: 1px solid black;
        background: white;
    }

    .page-header {
        position: fixed;
        top: 0mm;
        width: 210mm;

        border-bottom: 1px solid black;
        background: white;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    .page {
        font-size: 14px;
        page-break-after: always;
        .content {
            padding: 0 16px;
            .main-table {
                border: 1px solid black;
                td {
                    padding: 8px;
                    text-align: right;
                    border-right: 1px solid black;
                }
                th {
                    padding: 8px;
                    border-bottom: 1px solid black;
                    white-space: nowrap;
                    border-right: 1px solid black;
                }
                tbody {
                    tr {
                        border-bottom: 1px solid lightgray;
                        &:last-child {
                            border-bottom: none;
                        }
                        &:first-child {
                            border-top: 1px solid lightgray;
                        }
                    }
                }
                td.prod-name-cell {
                    text-align: left;
                }
                th.header-price-ttc {
                    text-align: right;
                }

                td {
                    white-space: nowrap;
                    &:first-child {
                        white-space: normal;
                    }
                }

                th.header-total-price {
                    text-align: right;
                }

                th.header-quantity {
                    text-align: right;
                }
            }
            .total-table {
                margin-top: 16px;
                display: flex;
                justify-content: space-between;
                div {
                    white-space: nowrap;
                    text-align: right;
                }
                .total-left {
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    .total-line {
                        &:first-child div {
                            text-align: left;
                        }
                        > div:first-child {
                            width: 130px;
                        }
                        > div:nth-child(2) {
                            width: 80px;
                        }
                        > div:last-child {
                            width: 130px;
                        }
                    }
                }
                .total-right {
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    .total-line {
                        > div:first-child {
                            width: 100px;
                            text-align: left;
                        }
                        > div:nth-child(2) {
                            width: 130px;
                        }
                        &:last-child {
                            font-weight: bold;
                        }
                    }
                }

                .total-line {
                    display: flex;

                    > div {
                        border: 1px solid black;
                        padding: 6px 16px;
                        border-right: 0;
                        border-bottom: 0;
                    }
                }
            }
            .amount-spelled {
                margin: 30px 0;
                > div:first-child {
                    font-weight: bold;
                    margin-bottom: 8px;
                }
            }
        }
    }

    .spacer {
        height: 4cm;
    }

    @media print {
        box-shadow: none;
        counter-reset: page;

        thead {
            display: table-header-group;
        }
        tfoot {
            display: table-footer-group;
        }

        button {
            display: none;
        }
    }
`;

export const Facture = () => {
    const history = useHistory();
    const id = new URLSearchParams(history.location.search).get('id');

    const [data, setData] = useState([]);

    const { data: factureData } = useQuery(['facture', id], getFacture);

    useEffect(() => {
        const factures = factureData?.result;
        const facture = factures?.find((f) => String(f.id_facture) === String(id));
        setData(facture || []);
    }, [factureData, id]);

    console.log(data);

    return (
        <StyledFacture>
            <PrintButton />
            <div className="page-header">
                <div className="main-header">
                    <div className="left-part">
                        <Logos
                            company={data?.company}
                            style={{ paddingLeft: 20, height: 110, width: 300 }}
                        />
                    </div>
                    <div className="right-part">
                        <div className="date-container">{formatDate(data?.facture_date)}</div>
                        <div className="facture-number-container">
                            <div className="facture-number-title">FACTURE Nº</div>
                            <div className="facture-number">{data?.num_facture}</div>
                        </div>
                    </div>
                </div>
                <div className="client-block">
                    <span className="client-title">Client</span>
                    {data?.client}
                </div>
            </div>

            <div className="page-footer">
                <FootersInfo company={data?.company} />
            </div>

            <table>
                <thead>
                    <div className="page-header-space"></div>
                </thead>

                <tbody>
                    <div className="page">
                        <div className="content">
                            <table className="main-table">
                                <thead>
                                    <tr>
                                        <th className="header-name">Désignation</th>
                                        <th className="header-tva">TVA</th>
                                        <th className="header-price-ttc">Prix U. TTC</th>
                                        <th className="header-quantity">Qté</th>
                                        <th className="header-total-price">Prix Total TTC</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.productline?.map((r, i) => (
                                        <tr key={`row_${i}`}>
                                            <td className="prod-name-cell">{r.product_name}</td>
                                            <td>{getDisplayValue(r.tva, 'percent')}</td>
                                            <td>{getDisplayValue(r.selling_price, 'amount')}</td>
                                            <td>{r.quantity}</td>
                                            <td>{getDisplayValue(r.total_ttc, 'amount')}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="total-table">
                                <div className="total-left">
                                    <div className="total-line">
                                        <div>Base HT</div>
                                        <div>% TVA</div>
                                        <div>Montant TVA</div>
                                    </div>
                                    <div className="total-line">
                                        <div>{getDisplayValue(data?.base_ht_7, 'amount')}</div>
                                        <div>{getDisplayValue(7, 'percent')}</div>
                                        <div>{getDisplayValue(data?.total_tva_7, 'amount')}</div>
                                    </div>
                                    <div className="total-line">
                                        <div>{getDisplayValue(data?.base_ht_20, 'amount')}</div>
                                        <div>{getDisplayValue(20, 'percent')}</div>
                                        <div>{getDisplayValue(data?.total_tva_20, 'amount')}</div>
                                    </div>
                                </div>
                                <div className="total-right">
                                    <div className="total-line">
                                        <div>Total HT</div>
                                        <div>
                                            {getDisplayValue(
                                                data?.base_ht_7 + data?.base_ht_20,
                                                'amount'
                                            )}
                                        </div>
                                    </div>
                                    <div className="total-line">
                                        <div>Total TVA</div>
                                        <div>
                                            {getDisplayValue(
                                                data?.total_tva_7 + data?.total_tva_20,
                                                'amount'
                                            )}
                                        </div>
                                    </div>
                                    <div className="total-line">
                                        <div>Total TTC</div>
                                        <div>{getDisplayValue(data?.total_amount, 'amount')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="amount-spelled">
                                <div>La présente facture est arrétée à la somme de :</div>
                                <div>{spellNumber(data?.total_amount || 0)}</div>
                                {/* <div>{spellNumber(133.3)}</div> */}
                            </div>
                        </div>
                    </div>
                </tbody>

                <tfoot>
                    <div className="page-footer-space"></div>
                </tfoot>
            </table>
            <div className="spacer" />
        </StyledFacture>
    );
};
