import { ProductSelect } from '../components/ProductSelect';
import { deleteEmptyValues } from '../utils';
import { ClientsSelect } from '../components/ClientsSelect';
import { MethodSelect } from '../components/MethodSelect';
import { CompanySelect } from '../components/CompanySelect';
import { ClientRender } from '../components/ClientRender';

export const salesHeaders = [
    {
        label: 'Numero BL',
        accessor: 'id_bl',
        width: 130,
        inputWidth: 130,
        writeable: false,
        spaced: true,
        style: {
            textAlign: 'right',
        },
    },
    {
        label: 'Date de vente',
        accessor: 'selling_date',
        width: 200,
        writeable: true,
        format: 'date',
    },
    {
        label: 'Client',
        accessor: 'client__name',
        getter: (data) => data?.client?.name,
        render: ClientRender,
        width: 220,
        writeable: true,
    },
    {
        label: 'Total',
        accessor: 'total_amount',
        width: 140,
        inputWidth: 120,
        format: 'amount',
    },
    {
        label: 'Nbr prods',
        accessor: 'total_products',
        width: 110,
        inputWidth: 120,
        format: 'number',
    },
    {
        label: 'Société',
        accessor: 'company',
        width: 150,
        writeable: true,
    },
    {
        label: 'Commentaires',
        accessor: 'comments',
        width: 250,
        writeable: true,
    },
];

export const salesHeadersDetails = [
    {
        label: 'Numero BL',
        accessor: 'bon_livraison__num_bl',
        getter: (data) => data?.bon_livraison?.num_bl,
        width: 130,
        inputWidth: 130,
        writeable: false,
        spaced: true,
        style: {
            textAlign: 'right',
        },
    },
    {
        label: 'Date de vente',
        accessor: 'bon_livraison__selling_date',
        getter: (data) => data?.bon_livraison?.selling_date,
        width: 160,
        writeable: true,
        format: 'date',
    },
    {
        label: 'Client',
        accessor: 'bon_livraison__client__name',
        getter: (data) => data?.bon_livraison?.client?.name,
        render: ClientRender,
        width: 220,
        writeable: true,
    },
    {
        label: 'Designation',
        accessor: 'product__name',
        getter: (data) => data?.product?.name,
        width: 350,
        inputWidth: 120,
    },
    {
        label: 'P.Vente TTC',
        accessor: 'selling_price',
        getter: (data) => data?.selling_price,
        width: 125,
        inputWidth: 120,
        format: 'amount',
    },
    {
        label: 'Qté',
        accessor: 'quantity',
        getter: (data) => data?.quantity,
        width: 80,
        inputWidth: 120,
        format: 'number',
    },
];

export const productsHeaders = [
    {
        label: 'Produit',
        accessor: 'product_name',
        getter: (data) => data,
        width: 450,
        writeable: true,
        unsortable: true,
        unfilterable: true,
        editable: true,
        render: ProductSelect,
    },
    {
        label: 'Prix de vente',
        accessor: 'selling_price',
        width: 130,
        format: 'amount',
        writeable: true,
        unsortable: true,
        unfilterable: true,
        editable: true,
    },
    {
        label: 'Quantité',
        accessor: 'quantity',
        width: 90,
        writeable: true,
        format: 'number',
        unsortable: true,
        unfilterable: true,
        editable: true,
    },
    // {
    //     label: 'Remise',
    //     accessor: 'discount',
    //     width: 80,
    //     format: 'percent',
    //     writeable: true,
    //     unsortable: true,
    //     unfilterable: true,
    //     editable: true,
    // },
    {
        label: 'Prix total',
        accessor: 'total_amount',
        width: 140,
        format: 'amount',
        writeable: true,
        unfilterable: true,
        unsortable: true,
    },
];

export const validateSaleData = (data) => {
    const newData = { ...data };
    if (!newData.global_discount) delete newData.global_discount;
    if (!newData.comments) delete newData.comments;
    const newProducts = [...newData.products];
    const validProducts = newProducts.map(deleteEmptyValues);
    return { ...newData, products: validProducts };
};

export const prepareSaleData = (data) => {
    const newData = { ...data };
    if (!newData.global_discount && newData.global_discount !== 0) delete newData.global_discount;
    if (!newData.comments) delete newData.comments;
    return newData;
};

export const addProductsheaders = [
    {
        label: 'Produit',
        accessor: 'name',
        width: 350,
        unsortable: true,
        unfilterable: true,
    },
    {
        label: 'Prix de vente',
        accessor: 'selling_price',
        width: 115,
        format: 'amount',
        unsortable: true,
        unfilterable: true,
        editable: true,
    },
    {
        label: 'Quantité',
        accessor: 'quantity',
        width: 90,
        format: 'number',
        unsortable: true,
        unfilterable: true,
        editable: true,
    },
    // {
    //     label: 'Remise',
    //     accessor: 'discount',
    //     width: 80,
    //     format: 'percent',
    //     unsortable: true,
    //     unfilterable: true,
    //     editable: true,
    // },
    {
        label: 'Prix total',
        accessor: 'total_amount',
        width: 140,
        format: 'amount',
        unfilterable: true,
        unsortable: true,
    },
];

export const getProductToAdd = (productData) => {
    return {
        product: productData.id_product,
        name: productData.name,
        selling_price: productData.selling_price,
        total_amount: productData.selling_price,
        quantity: 1,
    };
};

export const newSaleHeader = [
    {
        label: 'Date de vente',
        accessor: 'selling_date',
        width: 200,
        writeable: true,
        format: 'date',
    },
    {
        label: 'Client',
        accessor: 'client__name',
        width: 220,
        getter: (data) => data?.client,
        renderLine: ClientsSelect,
        writeable: true,
    },
    {
        label: 'Société',
        accessor: 'company',
        width: 220,
        getter: (data) => data?.company,
        renderLine: CompanySelect,
        writeable: true,
    },
    {
        label: 'Commentaires',
        accessor: 'comments',
        width: 250,
        writeable: true,
    },
];

// Payments

export const paymentsHeaders = [
    {
        label: 'Date de paiement',
        accessor: 'payment_date',
        width: 170,
        writeable: true,
        format: 'date',
    },
    {
        label: 'Client',
        accessor: 'client__name',
        getter: (data) => data?.client,
        width: 250,
        render: ClientRender,
        renderLine: ClientsSelect,
        writeable: true,
    },
    {
        label: 'Montant',
        accessor: 'total',
        width: 140,
        inputWidth: 120,
        writeable: true,
        format: 'amount',
    },
    {
        label: 'Méthode',
        accessor: 'method',
        width: 150,
        renderLine: MethodSelect,
        writeable: true,
    },
    {
        label: 'Ref. chèque',
        accessor: 'cheque__number',
        getter: (data) => data?.cheque?.number,
        width: 150,
        inputWidth: 150,
        writeable: true,
        spaced: true,
    },
    {
        label: 'Ref. virement',
        accessor: 'virement__number',
        getter: (data) => data?.virement?.number,
        width: 150,
        inputWidth: 150,
        writeable: true,
        spaced: true,
    },
    {
        label: 'Société',
        accessor: 'company',
        width: 150,
        renderLine: CompanySelect,
        writeable: true,
    },
    {
        label: 'Commentaires',
        accessor: 'comments',
        width: 250,
        unsortable: true,
        writeable: true,
    },
];

export const preparePaymentData = (data) => {
    const newData = { ...data };
    if (newData.method === 'cheque' && newData.cheque__number) {
        newData.cheque = {
            ...newData.cheque,
            number: newData.cheque__number,
        };
        delete newData.virement;
    }
    if (newData.method === 'virement' && newData.virement__number) {
        newData.virement = {
            ...newData.virement,
            number: newData.virement__number,
        };
        delete newData.cheque;
    }
    if (!newData.method) {
        newData.method = 'cash';
        delete newData.cheque;
        delete newData.virement;
    }

    return newData;
};

// Echeancier

export const echeancierHeaders = [
    {
        label: 'Num. de chèque',
        accessor: 'number',
        width: 160,
        writeable: true,
        spaced: true,
    },
    {
        label: "Date d'émission",
        accessor: 'date_emission',
        width: 155,
        writeable: true,
        format: 'date',
    },
    {
        label: "Date d'échéance",
        accessor: 'date_echeance',
        width: 155,
        writeable: true,
        format: 'date',
    },
    {
        label: 'Date encaissement',
        accessor: 'date_encaissement',
        width: 170,
        writeable: true,
        format: 'date',
    },
    {
        label: 'Montant',
        accessor: 'total',
        getter: (data) => data?.bon_commande?.total_amount,
        width: 130,
        format: 'amount',
    },
    {
        label: 'Commentaires',
        accessor: 'comments',
        width: 250,
        writeable: true,
        unsortable: true,
    },
];
