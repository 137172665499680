import React, { useEffect, useState } from 'react';
import { Button } from '../../components/Button';
import { PageContent } from '../../components/PageContent';
import { Table } from '../../components/Table';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { getFiltersSortingQuery } from '../../utils';
import { clientsHeaders } from '../../config/clients';
import { getClients } from '../../api/clients';
import { AddClientModal } from '../../components/Clients/AddClientModal';
import { UpdateClientModal } from '../../components/Clients/UpdateClientModal';
import { FiltersList } from '../../components/FiltersList';

export const GestionClients = () => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [clientData, setClientData] = useState(null);

    const [filters, setFilters] = useState([]);
    const [sorting, setSorting] = useState({});
    const queryClient = useQueryClient();

    // GET CLIENTS
    const { data, fetchNextPage, isLoading, isFetching } = useInfiniteQuery(
        ['clients', getFiltersSortingQuery(filters, sorting)],
        getClients,
        {
            getNextPageParam: (lastPage) => {
                return lastPage.has_next ? parseInt(lastPage.current_page) + 1 : undefined;
            },
        }
    );

    useEffect(() => {
        queryClient.resetQueries('clients');
    }, []);

    return (
        <PageContent>
            <AddClientModal show={showAddModal} onClose={() => setShowAddModal(false)} />
            <UpdateClientModal
                show={clientData !== null}
                onClose={() => setClientData(null)}
                data={clientData}
            />
            <div className="page-title-container">
                <div className="page-title">Gestion des clients</div>
                <div className="title-buttons">
                    <Button
                        variant="green-full"
                        label="Ajouter un client"
                        onClick={() => setShowAddModal(true)}
                    />
                </div>
            </div>
            <FiltersList filters={filters} setFilters={setFilters} headers={clientsHeaders} />
            <Table
                tableStyles={{
                    maxHeight: `calc(100vh - 180px)`,
                }}
                filters={filters}
                onFiltersChange={setFilters}
                style={{ marginTop: 24 }}
                headers={clientsHeaders}
                data={data?.pages.map((p) => p.result).flat() || []}
                onLineSelect={(index, data) => {
                    setClientData(data);
                }}
                loadMore={() => fetchNextPage()}
                sorting={sorting}
                onSortingChange={setSorting}
                loading={isLoading || isFetching}
            />
        </PageContent>
    );
};
