import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { routes } from '../routes';
import styled from 'styled-components';

const StyledSideMenu = styled.div`
    width: 250px;
    position: fixed;
    left: 0;
    top: 40px;
    height: 100%;
    background-color: #f6f7f8;
    box-shadow: 0px 0px 25px #d1d6db;
    z-index: 5;
    > .title {
        font-weight: 600;
        font-size: 24px;
        margin: 32px;
        margin-top: 64px;
    }
    > .link {
        height: 55px;
        display: flex;
        align-items: center;
        padding-left: 32px;
        font-weight: 600;
        font-size: 12px;
        color: #8a95a8;
        transition: all 0.15s;
        &:hover {
            background: #f0f2f4;
            color: black;
            box-shadow: inset -2px 0px #d3d9de;
        }
        &.active {
            box-shadow: inset -2px 0px #0090e1;
            background: #e8eaee;
            color: black;
        }
    }
`;

export const SideMenu = () => {
    const location = useLocation();
    const [currentRoute, setCurrentRoute] = useState();

    useEffect(() => {
        setCurrentRoute(routes.find((r) => r.route === location.pathname.split('/')[1]));
    }, [location]);

    return (
        <StyledSideMenu>
            <div className="title">{currentRoute && currentRoute.label}</div>
            {currentRoute &&
                currentRoute.subroutes.map((subroute) => (
                    <Link
                        key={`/${currentRoute.route}/${subroute.route}`}
                        to={`/${currentRoute.route}/${subroute.route}`}
                        className={
                            location.pathname === `/${currentRoute.route}/${subroute.route}`
                                ? 'link active'
                                : 'link'
                        }
                    >
                        {subroute.label}
                    </Link>
                ))}
        </StyledSideMenu>
    );
};
