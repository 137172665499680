import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { addPayment } from '../../api/sales';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { InfoModalContent } from '../InfoModalContent';
import { paymentsHeaders, preparePaymentData } from '../../config/sales';

const intialData = {
    payment_date: new Date().toISOString(),
    client: {},
    client__name: '',
    company: 'H2SSMED',
    method: 'cheque',
};

export const AddPaymentModal = ({ show, onClose }) => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const queryClient = useQueryClient();
    const [data, setData] = useState({ ...intialData });

    useEffect(() => {
        setData({ ...intialData });
    }, [show]);

    useEffect(() => {
        setData((oldData) => ({ ...oldData, virement__number: null, cheque__number: null }));
    }, [data.method]);

    const createMutation = useMutation(addPayment, {
        onSuccess: (data) => {
            console.log('create successful: ', data);
            setSubmitLoading(false);
            queryClient.invalidateQueries('payments');
            queryClient.invalidateQueries('cheques');
            toast.success('Paiement a été ajouté avec succès!');
            onClose();
        },
        onError: (err) => {
            console.log('create failed with error: ', err.response);
            setSubmitLoading(false);
            toast.error('create failed with error: ' + JSON.stringify(err.response?.data));
            onClose();
        },
    });

    const updateClient_addPayment = (newClient) => {
        newClient &&
            setData((oldData) => ({
                ...oldData,
                id_client: newClient?.id_client,
                client: newClient,
                client__name: newClient?.name,
            }));
    };

    const isSubmitDisabled = () => {
        if (!data?.method) return true;
        if (!data?.total) return true;
        if (data?.method === 'cheque' && !data?.cheque__number && !data?.cheque?.number) {
            return true;
        }
        if (data?.method === 'virement' && !data?.virement__number && !data?.virement?.number)
            return true;
        return false;
    };

    const getHeaders_addPayment = () => {
        const newHeaders = paymentsHeaders.map((el) => ({ ...el }));
        newHeaders.find((el) => el.accessor === 'client__name').onLineChange =
            updateClient_addPayment;
        const showChequeInput = data?.method === 'cheque';
        const showVirementInput = data?.method === 'virement';
        return newHeaders.filter((h) => {
            if (!showChequeInput && h.accessor === 'cheque__number') return false;
            if (!showVirementInput && h.accessor === 'virement__number') return false;
            return true;
        });
    };
    return (
        <Modal
            key="new-payment-modal"
            closeOnOutsideClick={false}
            show={show}
            onClose={onClose}
            title="Nouveau paiement"
            buttons={
                <>
                    <Button label="annuler" onClick={onClose} />
                    <Button
                        variant="green-full"
                        onClick={() => {
                            setSubmitLoading(true);
                            const newData = { ...data };
                            if (!newData?.comments) delete newData.comments;
                            createMutation.mutate(preparePaymentData(newData));
                        }}
                        label={submitLoading ? 'En cours ...' : 'Ajouter'}
                        loading={submitLoading}
                        disabled={isSubmitDisabled() || submitLoading}
                    />
                </>
            }
        >
            <InfoModalContent
                key="new-payment-info-modal"
                write={true}
                data={data}
                setData={setData}
                lines={getHeaders_addPayment()}
            />
        </Modal>
    );
};
