import React from 'react';
import styled from 'styled-components';

const StyledModal = styled.div`
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    .modal-content {
        position: absolute;
        min-width: 500px;
        background: white;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1), 0px 14px 44px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        left: 50%;
        transform: translate3d(-50%, 100px, 0);
        max-height: calc(100vh - 140px);
        .modal-header {
            position: sticky;
            border-bottom: 1px solid #e2e4e9;
            top: 0;
            left: 0;
            width: 100%;
            border-radius: 8px;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 32px;
            .modal-title {
                font-size: 18px;
                font-weight: 600;
            }
            .modal-buttons {
                display: flex;
                button {
                    margin-left: 12px;
                }
            }
        }
        .modal-body {
            .filter-container {
                padding: 8px;
                .inputs-container {
                    input {
                        height: 30px;
                        width: 105px;
                        padding: 0 12px;
                    }
                    display: flex;
                    font-size: 14px;
                    font-weight: 600;
                    > .arrow {
                        flex: 100px;
                        margin: 0 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
`;

export const Modal = ({
    closeOnOutsideClick = true,
    show,
    onClose,
    style,
    buttons,
    title,
    modalContainerStyle,
    children,
}) => {
    const handleOutsideClick = () => {
        closeOnOutsideClick && onClose();
    };

    return show ? (
        <StyledModal style={modalContainerStyle} onClick={handleOutsideClick}>
            <div style={style} className="modal-content" onClick={(e) => e.stopPropagation()}>
                {(title || buttons) && (
                    <div className="modal-header">
                        <div className="modal-title">{title}</div>
                        <div className="modal-buttons">{buttons}</div>
                    </div>
                )}
                <div className="modal-body">{children}</div>
            </div>
        </StyledModal>
    ) : null;
};
