import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { InfoModalContent } from '../InfoModalContent';
import {
    newPurchaseHeaders,
    preparePurchaseData,
    validatePurchaseData,
} from '../../config/purchases';
import { updatePurchase } from '../../api/purchases';

export const PurchaseDetailsModal = ({ show, onClose, data: purchaseData }) => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const queryClient = useQueryClient();

    const [updateData, setUpdateData] = useState();

    useEffect(() => {
        if (purchaseData) setUpdateData({ ...purchaseData });
        else setUpdateData(null);
    }, [purchaseData, show]);

    const closeAndReset = () => {
        setSubmitLoading(false);
        onClose();
    };

    // UPDATE PURCHASE
    const updateMutation = useMutation(updatePurchase, {
        onSuccess: (data) => {
            console.log('update successful: ', data);
            queryClient.invalidateQueries('purchases');
            queryClient.invalidateQueries('cheques_achats');
            toast.success('Achat a été modifié avec succès!');
            closeAndReset();
        },
        onError: (err) => {
            console.log('update failed with error: ', err.response);
            toast.error('update failed with error: ' + JSON.stringify(err.response?.data));
            closeAndReset();
        },
    });

    const isSubmitDisabled = () => {
        if (
            updateData?.method === 'cheque' &&
            !updateData?.cheque__number &&
            !updateData?.cheque?.number
        ) {
            return true;
        }
        if (
            updateData?.method === 'virement' &&
            !updateData?.virement__number &&
            !updateData?.virement?.number
        )
            return true;
        return false;
    };

    const buttons = (
        <>
            <Button
                onClick={() => {
                    setUpdateData(purchaseData);
                    onClose();
                }}
                label={'Annuler'}
            />
            <Button
                variant="green-full"
                onClick={() => {
                    setSubmitLoading(true);
                    updateMutation.mutate(validatePurchaseData(preparePurchaseData(updateData)));
                }}
                label={submitLoading ? 'En cours ...' : 'confirmer'}
                loading={submitLoading}
                disabled={isSubmitDisabled() || submitLoading}
            />
        </>
    );

    const updateSupplier = (newSupplier) => {
        setUpdateData((oldData) => ({
            ...oldData,
            supplier: newSupplier,
            supplier__name: newSupplier.name,
        }));
    };

    const getHeaders = () => {
        const newHeaders = [...newPurchaseHeaders];
        newHeaders.find((el) => el.accessor === 'supplier__name').onLineChange = updateSupplier;
        return newHeaders;
    };

    return (
        <Modal
            closeOnOutsideClick
            show={show}
            onClose={closeAndReset}
            title={"Informations de l'achat"}
            buttons={buttons}
        >
            <InfoModalContent
                write
                data={updateData}
                setData={setUpdateData}
                lines={getHeaders()}
            />
        </Modal>
    );
};
