import React, { useState } from 'react';
import { PageContent } from '../../components/PageContent';
import { Table } from '../../components/Table';
import { useInfiniteQuery } from 'react-query';
import { getFiltersSortingQuery } from '../../utils';
import { echeancierHeaders } from '../../config/sales';
import { getChequesAchat } from '../../api/purchases';
import { UpdateChequeModal } from '../../components/EcheancierAchats/UpdateChequeModal';
import { FiltersList } from '../../components/FiltersList';

export const EcheancierAchat = () => {
    const [chequeData, setChequeData] = useState(null);

    const [filters, setFilters] = useState([]);
    const [sorting, setSorting] = useState({});

    // GET CHEQUES
    const { data, fetchNextPage, isLoading, isFetching } = useInfiniteQuery(
        ['cheques_achats', getFiltersSortingQuery(filters, sorting)],
        getChequesAchat,
        {
            getNextPageParam: (lastPage) => {
                return lastPage.has_next ? parseInt(lastPage.current_page) + 1 : undefined;
            },
        }
    );

    return (
        <PageContent>
            <UpdateChequeModal
                show={chequeData !== null}
                onClose={() => setChequeData(null)}
                data={chequeData}
            />
            <div className="page-title-container">
                <div className="page-title">Echéancier - Chèques émis</div>
            </div>
            <FiltersList filters={filters} setFilters={setFilters} headers={echeancierHeaders} />
            <Table
                tableStyles={{
                    maxHeight: `calc(100vh - 180px)`,
                }}
                filters={filters}
                onFiltersChange={setFilters}
                style={{ marginTop: 24 }}
                headers={echeancierHeaders}
                data={data?.pages.map((p) => p.result).flat() || []}
                onLineSelect={(index, data) => {
                    setChequeData(data);
                }}
                loadMore={() => fetchNextPage()}
                sorting={sorting}
                onSortingChange={setSorting}
                loading={isLoading || isFetching}
            />
        </PageContent>
    );
};
