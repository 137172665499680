import { authAxios } from '../auth';

export const getDepenses = async ({ pageParam, queryKey }) => {
    const queryString = queryKey[1]?.length > 1 ? queryKey[1] : '';
    const res = await authAxios(`/depenses/?page_size=50&page=${pageParam || 1}${queryString}`);
    return res?.data;
};

export const addDepense = async (newDepense) => {
    const res = await authAxios.post('/depenses/', newDepense);
    return res?.data;
};

export const updateDepense = async (depenseData) => {
    const res = await authAxios.put(`/depenses/${depenseData.id}/`, depenseData);
    return res?.data;
};

export const deleteDepense = async (depenseID) => {
    const res = await authAxios.delete(`/depenses/${depenseID}/`);
    return res;
};

export const getCaisseJour = async () => {
    const res = await authAxios(`/caisse`);
    return res?.data;
};
