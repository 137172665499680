import React from 'react';
import styled from 'styled-components';

const StyledShell = styled.div`
    padding: 60px;
    padding-top: 80px;
    background: #f0f2f4;
    padding-left: 300px;
    min-height: 100vh;
`;

export const Shell = ({ children }) => <StyledShell>{children}</StyledShell>;
