import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { updatePayment, deletePayment } from '../../api/sales';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { InfoModalContent } from '../InfoModalContent';
import { paymentsHeaders, preparePaymentData } from '../../config/sales';

export const UpdatePaymentModal = ({ show, onClose, data: paymentData }) => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const queryClient = useQueryClient();

    const [updateData, setUpdateData] = useState();

    useEffect(() => {
        if (paymentData) setUpdateData({ ...paymentData });
        else setUpdateData(null);
    }, [paymentData]);

    const closeAndReset = () => {
        setSubmitLoading(false);
        setIsUpdating(false);
        onClose();
    };

    // UPDATE PAYMENT
    const updateMutation = useMutation(updatePayment, {
        onSuccess: (data) => {
            console.log('update successful: ', data);
            queryClient.invalidateQueries('payments');
            queryClient.invalidateQueries('cheques');
            toast.success('Paiement a été modifié avec succès!');
            closeAndReset();
        },
        onError: (err) => {
            console.log('update failed with error: ', err.response);
            toast.error('update failed with error: ' + JSON.stringify(err.response?.data));
            closeAndReset();
        },
    });

    // DELETE PAYMENT
    const deleteMutation = useMutation(deletePayment, {
        onSuccess: (res) => {
            console.log('delete mutation successful: ', res);
            queryClient.invalidateQueries('payments');
            toast.success('Paiement a été supprimé avec succès!');
            closeAndReset();
        },
        onError: (err) => {
            console.log('delete failed with error: ', err.response);
            toast.error('delete failed with error: ' + JSON.stringify(err.response?.data));
            closeAndReset();
        },
    });

    const isSubmitDisabled = () => {
        if (!updateData?.method) return true;
        if (!updateData?.total) return true;
        if (
            updateData?.method === 'cheque' &&
            !updateData?.cheque__number &&
            !updateData?.cheque?.number
        ) {
            return true;
        }
        if (
            updateData?.method === 'virement' &&
            !updateData?.virement__number &&
            !updateData?.virement?.number
        )
            return true;
        return false;
    };

    const updateButtons = (
        <>
            <Button
                label="annuler"
                onClick={() => {
                    setIsUpdating(false);
                    setUpdateData(paymentData);
                }}
            />
            <Button
                variant="green-full"
                onClick={() => {
                    setSubmitLoading(true);
                    const newData = { ...updateData };
                    if (!newData?.comments) delete newData.comments;
                    updateMutation.mutate(preparePaymentData(newData));
                }}
                label={submitLoading ? 'En cours ...' : 'confirmer'}
                loading={submitLoading}
                disabled={isSubmitDisabled() || submitLoading}
            />
        </>
    );

    const viewButtons = (
        <>
            <Button
                variant="red-full"
                onClick={() => {
                    setSubmitLoading(true);
                    deleteMutation.mutate(paymentData.id_payment);
                }}
                label={submitLoading ? 'En cours ...' : 'supprimer'}
                loading={submitLoading}
                disabled={submitLoading}
            />
            <Button variant="green-full" onClick={() => setIsUpdating(true)} label="modifier" />
        </>
    );

    const updateClient_updatePayment = (newClient) => {
        newClient &&
            setUpdateData((oldData) => ({
                ...oldData,
                id_client: newClient?.id_client,
                client: newClient,
                client__name: newClient?.name,
            }));
    };

    const getHeaders_updatePayment = () => {
        const newHeaders = paymentsHeaders.map((el) => ({ ...el }));
        newHeaders.find((el) => el.accessor === 'client__name').onLineChange =
            updateClient_updatePayment;
        const showChequeInput = updateData?.method === 'cheque';
        const showVirementInput = updateData?.method === 'virement';
        return newHeaders.filter((h) => {
            if (!showChequeInput && h.accessor === 'cheque__number') return false;
            if (!showVirementInput && h.accessor === 'virement__number') return false;
            return true;
        });
    };

    return (
        <Modal
            key="update-payment-modal"
            closeOnOutsideClick={!isUpdating}
            show={show}
            onClose={closeAndReset}
            title={isUpdating ? 'Modifier un paiement' : ''}
            buttons={isUpdating ? updateButtons : viewButtons}
        >
            <InfoModalContent
                key="update-payment-info-modal"
                write={isUpdating}
                data={isUpdating ? updateData : paymentData}
                setData={setUpdateData}
                lines={getHeaders_updatePayment()}
            />
        </Modal>
    );
};
