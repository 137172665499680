import React from 'react';

export const ClientRender = ({ value }) => {
    return (
        value?.name ||
        value || (
            <div
                style={{
                    opacity: 0.5,
                    fontSize: 11,
                    letterSpacing: '0.05em',
                    textAlign: 'center',
                    fontStyle: 'italic',
                }}
            >
                CLIENT COMPTOIR
            </div>
        )
    );
};
