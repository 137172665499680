import React, { useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { PageContent } from '../../components/PageContent';
import { Button } from '../../components/Button';
import { Table } from '../../components/Table';
import { deleteEmptyValues, getFiltersSortingQuery } from '../../utils';
import { validateFactureData } from '../../config/factures';
import { facturesHeaders, factureProductsHeaders } from '../../config/factures';

import { getFactures, updateFacture } from '../../api/factures';
import { FiltersList } from '../../components/FiltersList';
import { toast } from 'react-toastify';
import { FactureDetailsModal } from '../../components/Factures/FactureDetailsModal';
import { DeleteConfirmationModal } from '../../components/DeleteConfirmationModal';
// import { useHistory } from 'react-router';

export const GestionFactures = () => {
    const [filters, setFilters] = useState([]);
    const [sorting, setSorting] = useState({
        key: 'id_facture',
        type: 'desc',
    });
    const [factureData, setFactureData] = useState({});
    const [selectedLines, setSelectedLines] = useState([]);
    const [productsWriteable, setProductsWriteable] = useState(false);
    const [tempFactureData, setTempFactureData] = useState({});
    const [facturesData, setFacturesData] = useState([]);
    const [saveProductsLoading, setSaveProductsLoading] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const queryClient = useQueryClient();
    // const history = useHistory();

    useEffect(() => {
        setFactureData({});
        setSelectedLines(null);
    }, [sorting, filters]);

    useEffect(() => {
        queryClient.invalidateQueries('factures');
    }, []);

    useEffect(() => {
        queryClient.resetQueries('categories');
    }, []);

    // GET FACTURES
    const { data, fetchNextPage, isLoading, isFetching } = useInfiniteQuery(
        ['factures', getFiltersSortingQuery(filters, sorting)],
        getFactures,
        {
            getNextPageParam: (lastPage) => {
                return lastPage.has_next ? parseInt(lastPage.current_page) + 1 : undefined;
            },
        }
    );

    useEffect(() => {
        setFacturesData(data?.pages.map((p) => p.result).flat() || []);
    }, [data]);

    useEffect(() => {
        const data = facturesData[selectedLines];
        if (data) setFactureData(getFactureData(data));
    }, [facturesData, selectedLines]);

    // UPDATE FACTURE
    const updateMutation = useMutation(updateFacture, {
        onSuccess: (data) => {
            console.log('update successful: ', data);
            queryClient.invalidateQueries('factures');
            toast.success('Facture a été modifiée avec succès!');
            setProductsWriteable(false);
            setSaveProductsLoading(false);
        },
        onError: (err) => {
            console.log('update failed with error: ', err.response);
            toast.error('update failed with error: ' + JSON.stringify(err.response?.data));
            setProductsWriteable(false);
            setSaveProductsLoading(false);
        },
    });

    const handleProductDelete = (i) => {
        setTempFactureData((old) => {
            const productline = [...old.productline];
            productline.splice(i, 1);
            return { ...old, productline };
        });
    };

    const handleNewProduct = () => {
        setTempFactureData((old) => ({ ...old, productline: [...old.productline, {}] }));
    };

    const handleProductChange = (val, i, key) => {
        setTempFactureData((old) => {
            const productline = [...old.productline];
            const productToUpdate = { ...productline[i] };
            productToUpdate[key] = val;
            productline[i] = productToUpdate;
            return getFactureData({ ...old, productline });
        });
    };

    const handleSaveProducts = () => {
        setSaveProductsLoading(true);
        const dataToSet = validateFactureData(getFactureData(tempFactureData));
        setFactureData(dataToSet);
        updateMutation.mutate(deleteEmptyValues({ ...dataToSet }));
    };

    const getFactureData = (data) => {
        const productline = data.productline.map((p) => ({
            ...p,
            total_amount: p.selling_price * p.quantity,
        }));
        return { ...data, productline };
    };

    return (
        <PageContent style={{ paddingBottom: 300 }}>
            {showDeleteConfirmation && (
                <DeleteConfirmationModal
                    content={'Facture Num ' + factureData.num_facture}
                    onClose={() => setShowDeleteConfirmation(false)}
                    onDelete={() => setShowDeleteConfirmation(false)}
                />
            )}
            <FactureDetailsModal
                show={showDetailsModal}
                onClose={() => {
                    setShowDetailsModal(false);
                    setFactureData({});
                    setSelectedLines(null);
                }}
                data={factureData}
            />
            <div className="page-title-container">
                <div className="page-title">Gestion des factures</div>
            </div>
            <FiltersList filters={filters} setFilters={setFilters} headers={facturesHeaders} />
            <Table
                tableStyles={{
                    maxHeight: `235px`,
                }}
                filters={filters}
                onFiltersChange={setFilters}
                style={{ marginTop: 24 }}
                headers={facturesHeaders}
                data={facturesData}
                onLineSelect={(id, data) => {
                    setFactureData(getFactureData(data));
                    setSelectedLines([id]);
                }}
                selectedLines={selectedLines}
                loadMore={() => fetchNextPage()}
                sorting={sorting}
                onSortingChange={setSorting}
                loading={isLoading || isFetching}
            />
            {factureData.id_facture && (
                <>
                    <div className="separator" />
                    <div className="page-title-container">
                        <div className="page-title">
                            <span style={{ opacity: 0.2, fontWeight: 400 }}>Facture #</span>{' '}
                            {factureData.num_facture}
                        </div>
                        <div className="title-buttons">
                            {productsWriteable ? (
                                <>
                                    <Button
                                        label="annuler"
                                        onClick={() => setProductsWriteable(false)}
                                    />

                                    <Button
                                        variant="green-full"
                                        label="Enregistrer"
                                        disabled={saveProductsLoading}
                                        loading={saveProductsLoading}
                                        onClick={handleSaveProducts}
                                    />
                                </>
                            ) : (
                                <>
                                    <Button
                                        variant="green"
                                        label="modifier produits"
                                        onClick={() => {
                                            setTempFactureData({ ...factureData });
                                            setProductsWriteable(true);
                                        }}
                                    />
                                    <Button
                                        onClick={() => setShowDetailsModal(true)}
                                        variant="blue"
                                        label="modifier informations"
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <Table
                        key={factureData.id_facture + productsWriteable ? '_temp' : ''}
                        style={{ marginTop: 32 }}
                        tableStyles={{ maxHeight: 'unset' }}
                        headers={factureProductsHeaders}
                        data={
                            productsWriteable
                                ? [...tempFactureData.productline]
                                : [...factureData.productline]
                        }
                        canEdit={productsWriteable}
                        onDeleteLine={productsWriteable && handleProductDelete}
                        onCellEdit={handleProductChange}
                    />
                    {productsWriteable && (
                        <Button
                            style={{ marginTop: 16 }}
                            onClick={handleNewProduct}
                            label="Ajouter un produit"
                        />
                    )}
                    <div className="bottom-bar">
                        <div className="left-part">
                            <Button
                                onClick={() => setShowDeleteConfirmation(true)}
                                variant="red"
                                label="supprimer cette facture"
                            />
                        </div>
                        <div className="right-part">
                            <Button
                                icon="print"
                                label="imprimer facture"
                                onClick={() => {
                                    const win = window.open(
                                        `/docs/facture?id=${factureData.id_facture}`,
                                        '_blank'
                                    );
                                    win.focus();
                                }}
                            />
                        </div>
                    </div>
                </>
            )}
        </PageContent>
    );
};
