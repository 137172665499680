import React, { useState } from 'react';
import { Button } from '../../components/Button';
import { PageContent } from '../../components/PageContent';
import { Table } from '../../components/Table';
import { useInfiniteQuery } from 'react-query';
import { getFiltersSortingQuery } from '../../utils';
import { suppliersHeaders } from '../../config/suppliers';
import { getSuppliers } from '../../api/suppliers';
import { AddSupplierModal } from '../../components/Suppliers/AddSupplierModal';
import { UpdateSupplierModal } from '../../components/Suppliers/UpdateSupplierModal';
import { FiltersList } from '../../components/FiltersList';

export const GestionFournisseurs = () => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [supplierData, setSupplierData] = useState(null);

    const [filters, setFilters] = useState([]);
    const [sorting, setSorting] = useState({});

    // GET SUPPLIERS
    const { data, fetchNextPage, isLoading, isFetching } = useInfiniteQuery(
        ['suppliers', getFiltersSortingQuery(filters, sorting)],
        getSuppliers,
        {
            getNextPageParam: (lastPage) => {
                return lastPage.has_next ? parseInt(lastPage.current_page) + 1 : undefined;
            },
        }
    );

    return (
        <PageContent>
            <AddSupplierModal show={showAddModal} onClose={() => setShowAddModal(false)} />
            <UpdateSupplierModal
                show={supplierData !== null}
                onClose={() => setSupplierData(null)}
                data={supplierData}
            />
            <div className="page-title-container">
                <div className="page-title">Gestion des fournisseurs</div>
                <div className="title-buttons">
                    <Button
                        variant="green-full"
                        label="Ajouter un fournisseur"
                        onClick={() => setShowAddModal(true)}
                    />
                </div>
            </div>
            <FiltersList filters={filters} setFilters={setFilters} headers={suppliersHeaders} />
            <Table
                tableStyles={{
                    maxHeight: `calc(100vh - 180px)`,
                }}
                filters={filters}
                onFiltersChange={setFilters}
                style={{ marginTop: 24 }}
                headers={suppliersHeaders}
                data={data?.pages.map((p) => p.result).flat() || []}
                onLineSelect={(index, data) => {
                    setSupplierData(data);
                }}
                loadMore={() => fetchNextPage()}
                sorting={sorting}
                onSortingChange={setSorting}
                loading={isLoading || isFetching}
            />
        </PageContent>
    );
};
