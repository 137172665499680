import { authAxios } from '../auth';

// sales

export const getSales = async ({ pageParam, queryKey }) => {
    const queryString = queryKey[1]?.length > 1 ? queryKey[1] : '';
    const res = await authAxios(`/vente/?page_size=50&page=${pageParam || 1}${queryString}`);
    return res?.data;
};

export const getSale = async ({ queryKey }) => {
    const res = await authAxios(`/vente/?id_bl=${queryKey[1]}&page_size=50&sort=id_bl`);
    return res?.data;
};

export const addSale = async (newSale) => {
    const res = await authAxios.post('/vente/', newSale);
    return res?.data;
};

export const updateSale = async (saleData) => {
    const res = await authAxios.put(`/vente/`, saleData);
    return res?.data;
};

export const deleteSale = async (saleID) => {
    const res = await authAxios.delete(`/vente/${saleID}/`);
    return res;
};

// payments

export const getPayments = async ({ pageParam, queryKey }) => {
    const queryString = queryKey[1]?.length > 1 ? queryKey[1] : '';
    const res = await authAxios(`/payments/?page_size=50&page=${pageParam || 1}${queryString}`);
    return res?.data;
};

export const addPayment = async (newPayment) => {
    const res = await authAxios.post('/payments/', newPayment);
    return res?.data;
};

export const updatePayment = async (paymentData) => {
    const res = await authAxios.put(`/payments/`, paymentData);
    return res?.data;
};

export const deletePayment = async (clientID) => {
    const res = await authAxios.delete(`/payments/${clientID}/`);
    return res;
};

//  echeancier

export const getChequesVente = async ({ pageParam, queryKey }) => {
    const queryString = queryKey[1]?.length > 1 ? queryKey[1] : '';
    const res = await authAxios(`/cheques/?page_size=50&page=${pageParam || 1}${queryString}`);
    return res?.data;
};

export const updateChequeVente = async (chequeData) => {
    const res = await authAxios.put(`/cheques/${chequeData.id}/`, chequeData);
    return res?.data;
};

export const getSalesDetails = async ({ pageParam, queryKey }) => {
    const queryString = queryKey[1]?.length > 1 ? queryKey[1] : '';
    const res = await authAxios(`/vente/details?page_size=50&page=${pageParam || 1}${queryString}`);
    return res?.data;
};
