import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { updateChequeVente } from '../../api/sales';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { InfoModalContent } from '../InfoModalContent';
import { echeancierHeaders } from '../../config/sales';

export const UpdateChequeModal = ({ show, onClose, data: chequeData }) => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const queryClient = useQueryClient();

    const [updateData, setUpdateData] = useState({});

    useEffect(() => {
        if (chequeData) setUpdateData({ ...chequeData });
        else setUpdateData(null);
    }, [chequeData]);

    const closeAndReset = () => {
        setSubmitLoading(false);
        setIsUpdating(false);
        onClose();
    };

    const isSubmitDisabled = () => {
        if (!updateData?.number) return true;
        return false;
    };

    // UPDATE CHEQUE
    const updateMutation = useMutation(updateChequeVente, {
        onSuccess: (data) => {
            console.log('update successful: ', data);
            queryClient.invalidateQueries('cheques');
            queryClient.invalidateQueries('payments');
            toast.success('Chèque a été modifié avec succès!');
            closeAndReset();
        },
        onError: (err) => {
            console.log('update failed with error: ', err.response);
            toast.error('update failed with error: ' + JSON.stringify(err.response?.data));
            closeAndReset();
        },
    });

    const updateButtons = (
        <>
            <Button label="annuler" onClick={closeAndReset} />
            <Button
                variant="green-full"
                onClick={() => {
                    setSubmitLoading(true);
                    const newData = { ...updateData };
                    if (!newData?.comments) delete newData.comments;
                    if (newData.total) newData.payment.total = newData.total;
                    updateMutation.mutate(newData);
                }}
                label={submitLoading ? 'En cours ...' : 'confirmer'}
                loading={submitLoading}
                disabled={isSubmitDisabled() || submitLoading}
            />
        </>
    );

    return (
        <Modal
            closeOnOutsideClick={!isUpdating}
            show={show}
            onClose={closeAndReset}
            title="Modifier un cheque"
            buttons={updateButtons}
        >
            <InfoModalContent
                write
                data={updateData}
                setData={setUpdateData}
                lines={echeancierHeaders}
            />
        </Modal>
    );
};
