import React from 'react';
import styled from 'styled-components';
import { getDisplayValue } from '../utils';
import { Input } from './Input';

const StyledInfoModalContent = styled.div`
    .info-line {
        border-bottom: 1px solid #e2e4e9;
        padding: 8px 32px;
        min-height: 52px;
        display: flex;
        align-items: center;
        &:last-child {
            margin-bottom: 8px;
        }
        > .label {
            width: 150px;
            font-size: 12px;
            font-weight: 600;
        }
        > .input {
            flex: 1;
        }
    }
`;

const LineField = (props) => {
    return <Input {...props} width={props.inputWidth} />;
};

export const InfoModalContent = ({ write = false, lines, data, setData }) => {
    return (
        <StyledInfoModalContent>
            {lines.map((line) => (
                <div key={line.accessor} className="info-line">
                    <div className="label">{line.label}</div>
                    <div className="input">
                        {line.renderLine ? (
                            <line.renderLine
                                {...line}
                                write={write}
                                value={line.getter ? line.getter(data) : data?.[line.accessor]}
                                onChange={(val) => {
                                    console.log('infomodalcontent val', val);
                                    line?.onLineChange
                                        ? line.onLineChange(val, data, setData)
                                        : setData({ ...data, [line.accessor]: val });
                                }}
                            />
                        ) : write && line.writeable ? (
                            <LineField
                                {...line}
                                value={line.getter ? line.getter(data) : data?.[line.accessor]}
                                onChange={(val) => setData({ ...data, [line.accessor]: val })}
                            />
                        ) : (
                            <div>
                                {getDisplayValue(
                                    line.getter ? line.getter(data) : data?.[line.accessor],
                                    line.format
                                )}
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </StyledInfoModalContent>
    );
};
