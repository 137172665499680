import React, { useEffect, useState } from 'react';

import { Button } from '../Button';
import { Modal } from '../Modal';
import { InfoModalContent } from '../InfoModalContent';
import { newSaleHeader } from '../../config/sales';

export const NewSaleModal = ({ loading, setLoading, show, onClose, data: venteData, onChange }) => {
    const [updateData, setUpdateData] = useState();

    useEffect(() => {
        if (venteData) setUpdateData({ ...venteData });
        else setUpdateData(null);
    }, [venteData]);

    const closeAndReset = () => {
        setLoading(false);
        onClose();
    };

    const buttons = (
        <>
            <Button
                onClick={() => {
                    setUpdateData(venteData);
                    closeAndReset();
                }}
                label={'Annuler'}
            />
            <Button
                variant="green-full"
                onClick={() => {
                    setLoading(true);
                    onChange(updateData);
                }}
                label={loading ? 'En cours ...' : 'confirmer'}
                loading={loading}
                disabled={loading}
            />
        </>
    );

    const updateClient_newSale = (newClient) => {
        console.log('newClient newSale', newClient);
        newClient &&
            setUpdateData((oldData) => ({
                ...oldData,
                id_client: newClient?.id_client,
                client: newClient,
                client__name: newClient?.name,
            }));
    };

    const getHeaders_newSale = () => {
        const newHeaders = [...newSaleHeader];
        newHeaders.find((el) => el.accessor === 'client__name').onLineChange = updateClient_newSale;
        return [...newHeaders];
    };

    return (
        <Modal
            closeOnOutsideClick
            show={show}
            onClose={closeAndReset}
            title={
                <>
                    Informations
                    <br />
                    supplémentaires
                </>
            }
            buttons={buttons}
        >
            <InfoModalContent
                write
                data={updateData}
                setData={setUpdateData}
                lines={getHeaders_newSale()}
            />
        </Modal>
    );
};
