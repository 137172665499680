import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { routes } from '../routes';
import styled from 'styled-components';

const StyledNavbar = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */

    padding: 0 60px;
    background: #ffffff;
    box-shadow: 0 1px 0 #d3d7de;
    z-index: 6;
    > .link {
        height: 40px;
        display: flex;
        align-items: center;
        margin-right: 60px;
        font-weight: 500;
        color: #8a95a8;
        transition: color 0.15s;
        &:hover {
            color: black;
        }
        &.active {
            box-shadow: 0 1px #0090e1;
            color: #0090e1;
        }
    }
`;

export const Navbar = () => {
    const location = useLocation();
    return (
        <StyledNavbar>
            {routes.map((route) => (
                <Link
                    key={route.label}
                    to={`/${route.route}/${route.subroutes[0].route}`}
                    className={location.pathname.includes(route.route) ? 'link active' : 'link'}
                >
                    {route.label}
                </Link>
            ))}
        </StyledNavbar>
    );
};
