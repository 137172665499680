import React from 'react';

import { routes } from './routes';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { SideMenu } from './components/SideMenu';
import { Shell } from './components/Shell';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BL } from './pages/Documents/BL';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { setToken } from './auth';
import { Facture } from './pages/Documents/Facture';
import { Situation } from './pages/Documents/Situation';
import { FicheClient } from './pages/Documents/FicheClient';

// Date locale setup
registerLocale('fr', fr);
setDefaultLocale('fr');

// ReactQuery setup
const queryClient = new QueryClient();

// TODO: replace token fetching technique
setToken();

const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <Switch>
                    <Redirect exact from="/" to="/ventes/saisie" />
                    <Route exact path={`/docs/bl`}>
                        <BL />
                    </Route>
                    <Route exact path={`/docs/facture`}>
                        <Facture />
                    </Route>
                    <Route exact path={`/docs/situation`}>
                        <Situation />
                    </Route>
                    <Route exact path={`/docs/fiche`}>
                        <FicheClient />
                    </Route>

                    {routes.map((parentRoute) =>
                        parentRoute.subroutes.map((subroute) => {
                            return (
                                <Route
                                    key={`/${parentRoute.route}/${subroute.route}`}
                                    exact
                                    path={`/${parentRoute.route}/${subroute.route}`}
                                >
                                    <div>
                                        <Navbar />
                                        <SideMenu />
                                        <Shell>
                                            {subroute.component
                                                ? subroute.component
                                                : subroute.label}
                                        </Shell>
                                    </div>
                                </Route>
                            );
                        })
                    )}
                </Switch>
            </Router>
            <ToastContainer autoClose={2500} />
        </QueryClientProvider>
    );
};

export default App;
