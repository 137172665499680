import React from 'react';
import styled from 'styled-components';

const StyledPrintButton = styled.div`
    position: fixed;
    z-index: 99;
    /* height: 70px; */
    /* width: 70px; */
    padding: 8px;
    top: 15px;
    right: 15px;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 5px 20px #00000054;
    text-align: center;
    cursor: pointer;
    transition: 0.15s;
    &:hover {
        transform: scale(1.1);
    }
    &:active {
        transform: scale(1);
    }
    @media print {
        display: none;
    }
`;

export const PrintButton = () => {
    return (
        <StyledPrintButton
            onClick={() => {
                window.print();
            }}
        >
            <span className="material-icons" style={{ fontSize: 40 }}>
                print
            </span>
            <div>Imprimer</div>
        </StyledPrintButton>
    );
};
