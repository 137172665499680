import React, { useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { getProducts } from '../../api/products';
import { PageContent } from '../../components/PageContent';
import { getFiltersSortingQuery } from '../../utils';
import { Table } from '../../components/Table';
import { addProductsheaders, getProductToAdd, prepareSaleData } from '../../config/sales';
import { FiltersList } from '../../components/FiltersList';
import { productsHeaders } from '../../config/products';
import { Button } from '../../components/Button';
import { NewSaleModal } from '../../components/Ventes/NewSaleModal';
import { addSale } from '../../api/sales';
import { toast } from 'react-toastify';

export const SaisieVentes = () => {
    const [filters, setFilters] = useState([]);
    const [sorting, setSorting] = useState({});
    const [productsData, setProductsData] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [newSaleLoading, setNewSaleLoading] = useState(false);

    const [saleDetails, setSaleDetails] = useState({
        selling_date: new Date().toISOString(),
        company: 'H2SSMED',
    });

    const queryClient = useQueryClient();

    // GET PRODUCTS
    const {
        data: rawProductsData,
        fetchNextPage,
        isLoading,
        isFetching,
    } = useInfiniteQuery(['products', getFiltersSortingQuery(filters, sorting)], getProducts, {
        getNextPageParam: (lastPage) => {
            return lastPage.has_next ? parseInt(lastPage.current_page) + 1 : undefined;
        },
    });

    useEffect(() => {
        setProductsData(rawProductsData?.pages.map((p) => p.result).flat() || []);
    }, [rawProductsData]);

    // CREATE SALE
    const createMutation = useMutation(addSale, {
        onSuccess: (data) => {
            console.log('creation successful: ', data);
            queryClient.invalidateQueries('sales');
            queryClient.invalidateQueries('products');
            toast.success('Vente a été créée avec succès!');
            reset();
        },
        onError: (err) => {
            console.log('update failed with error: ', err.response);
            toast.error('update failed with error: ' + JSON.stringify(err.response?.data));
            reset();
        },
    });

    const reset = () => {
        setShowDetailsModal(false);
        setSaleDetails({
            selling_date: new Date().toISOString(),
        });
        setSelectedProducts([]);
        setNewSaleLoading(false);
    };

    const handleProductDelete = (i) => {
        setSelectedProducts((old) => {
            const newProducts = [...old];
            newProducts.splice(i, 1);
            return newProducts;
        });
    };

    const handleProductChange = (val, i, key) => {
        setSelectedProducts((old) => {
            const newProducts = [...old];
            const productToUpdate = { ...newProducts[i] };
            productToUpdate[key] = val;
            productToUpdate.total_amount = productToUpdate.selling_price * productToUpdate.quantity;
            newProducts[i] = productToUpdate;
            return newProducts;
        });
    };

    const getTotalAmount = () => {
        let total = 0;
        selectedProducts.forEach((p) => (total += p.selling_price * p.quantity));
        return total;
    };

    const getTotalProducts = () => {
        let total = 0;
        selectedProducts.forEach((p) => (total += p.quantity));
        return total;
    };

    const getProductsForMutation = () => {
        console.log(selectedProducts);
        return selectedProducts.map((p) => ({ ...p, id_product: p.product, product_name: p.name }));
    };

    return (
        <PageContent style={{ paddingBottom: 100 }}>
            <NewSaleModal
                loading={newSaleLoading}
                setLoading={setNewSaleLoading}
                show={showDetailsModal}
                onClose={() => setShowDetailsModal(false)}
                data={saleDetails}
                onChange={(data) => {
                    createMutation.mutate({
                        ...prepareSaleData(data),
                        products: getProductsForMutation(),
                    });
                }}
            />
            <div className="page-title-container">
                <div className="page-title">Saisir une vente</div>
            </div>

            <FiltersList filters={filters} setFilters={setFilters} headers={productsHeaders} />
            <Table
                tableStyles={{
                    maxHeight: `235px`,
                }}
                filters={filters}
                onFiltersChange={setFilters}
                style={{ marginTop: 24 }}
                headers={productsHeaders}
                data={productsData}
                onLineSelect={(id, data) => {
                    setSelectedProducts((old) => {
                        const newData = [...old];
                        const newProductIndex = old.findIndex(
                            (el) => el.product === data.id_product
                        );
                        if (newProductIndex !== -1) {
                            newData.splice(newProductIndex, 1);
                        } else {
                            newData.push(getProductToAdd(data));
                        }
                        return newData;
                    });
                }}
                selectedLines={selectedProducts.map((el) => {
                    const index = productsData.findIndex((p) => p.id_product === el.product);
                    return index === -1 || index;
                })}
                loadMore={() => fetchNextPage()}
                sorting={sorting}
                onSortingChange={setSorting}
                loading={isLoading || isFetching}
            />
            {selectedProducts?.length > 0 && (
                <>
                    <div className="separator" />
                    <div className="page-title-container">
                        <div className="page-title">Produits séléctionnés</div>
                    </div>
                    <Table
                        style={{ marginTop: 32 }}
                        tableStyles={{ maxHeight: 'unset' }}
                        headers={addProductsheaders}
                        data={selectedProducts}
                        canEdit
                        onDeleteLine={handleProductDelete}
                        onCellEdit={handleProductChange}
                    />
                    <div className="bottom-bar">
                        <div className="left-part">
                            <span className="footer-info-line">
                                <span className="info-line-key">Total</span>
                                <span className="info-line-value">
                                    {getTotalAmount().toFixed(2)}
                                </span>
                            </span>
                            <span className="footer-info-line">
                                <span className="info-line-key">Nb. Produits</span>
                                <span className="info-line-value">{getTotalProducts()}</span>
                            </span>
                        </div>
                        <div className="right-part">
                            <Button
                                icon="skip_next"
                                onClick={() => setShowDetailsModal(true)}
                                variant="green-full"
                                label="étape suivante"
                            />
                        </div>
                    </div>
                </>
            )}
        </PageContent>
    );
};
