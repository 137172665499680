import React from 'react';

import { GestionAchats } from './pages/Achats/Gestion';
import { SaisieAchats } from './pages/Achats/Saisie';
import { GestionProduits } from './pages/Produits/Gestion';
import { GestionClients } from './pages/Clients/Gestion';
import { GestionVentes } from './pages/Ventes/Gestion';
import { GestionFournisseurs } from './pages/Fournisseurs/Gestion';
import { SaisieVentes } from './pages/Ventes/Saisie';
import { Paiements } from './pages/Ventes/Paiements';
import { Echeancier } from './pages/Ventes/Echeancier';
import { EcheancierAchat } from './pages/Achats/Echeancier';
import { Depenses } from './pages/Caisse/Depenses';
import { Situation } from './pages/Clients/Situation';
import { Caisse } from './pages/Caisse/Caisse';
import { GestionFactures } from './pages/Utilitaires/GestionFactures';
import { DetailsAchats } from './pages/Achats/Details';
import { DetailsVentes } from './pages/Ventes/Details';
import { CategoriesClients } from './pages/Clients/Categories';
import { PaniersProduits } from './pages/Produits/Paniers';
import { SaisiePanier } from './pages/Produits/SaisiePanier';
import { SaisieCategorie } from './pages/Clients/SaisieCategorie';

export const routes = [
    {
        label: 'Ventes',
        route: 'ventes',
        subroutes: [
            {
                label: 'Gestion des ventes',
                route: 'gestion',
                component: <GestionVentes />,
            },
            {
                label: 'Saisir une vente',
                route: 'saisie',
                component: <SaisieVentes />,
            },
            { label: 'Gestion des paiements', route: 'paiements', component: <Paiements /> },
            { label: 'Echéancier', route: 'echeancier', component: <Echeancier /> },
            {
                label: 'Gestion des ventes (avec details)',
                route: 'details',
                component: <DetailsVentes />,
            },
        ],
    },
    {
        label: 'Achats',
        route: 'achats',
        subroutes: [
            {
                label: 'Gestion des achats',
                route: 'gestion',
                component: <GestionAchats />,
            },
            {
                label: 'Saisir un achat',
                route: 'saisie',
                component: <SaisieAchats />,
            },
            { label: 'Echéancier', route: 'echeancier', component: <EcheancierAchat /> },
            {
                label: 'Gestion des achats (avec details)',
                route: 'details',
                component: <DetailsAchats />,
            },
        ],
    },
    {
        label: 'Clients',
        route: 'clients',
        subroutes: [
            {
                label: 'Gestion des clients',
                route: 'gestion',
                component: <GestionClients />,
            },
            { label: 'Situation client', route: 'situation', component: <Situation /> },
            {
                label: 'Categories de clients',
                route: 'categories',
                component: <CategoriesClients />,
            },
            {
                label: 'Saisir une categorie',
                route: 'saisiecategorie',
                component: <SaisieCategorie />,
            },
        ],
    },
    {
        label: 'Fournisseurs',
        route: 'fournisseurs',
        subroutes: [
            {
                label: 'Gestion des fournisseurs',
                route: 'gestion',
                component: <GestionFournisseurs />,
            },
        ],
    },
    {
        label: 'Produits',
        route: 'produits',
        subroutes: [
            {
                label: 'Gestion des produits',
                route: 'gestion',
                component: <GestionProduits />,
            },
            {
                label: 'Paniers de produits',
                route: 'paniers',
                component: <PaniersProduits />,
            },
            {
                label: 'Saisir un panier',
                route: 'saisiepanier',
                component: <SaisiePanier />,
            },
        ],
    },
    {
        label: 'Caisse',
        route: 'caisse',
        subroutes: [
            { label: 'Dépenses', route: 'depenses', component: <Depenses /> },
            { label: 'Caisse du jour', route: 'jour', component: <Caisse /> },
        ],
    },
    {
        label: 'Utilitaires',
        route: 'utilitaires',
        subroutes: [
            { label: 'Gestion des factures', route: 'factures', component: <GestionFactures /> },
        ],
    },
];
