import { ClientRender } from '../components/ClientRender';

export const depensesHeaders = [
    {
        label: 'Date de dépense',
        accessor: 'depense_date',
        width: 170,
        inputWidth: 160,
        writeable: true,
        format: 'date',
    },
    {
        label: 'Montant',
        accessor: 'montant',
        width: 110,
        inputWidth: 100,
        writeable: true,
        format: 'amount',
    },
    {
        label: 'Nature',
        accessor: 'nature',
        width: 200,
        inputWidth: 200,
        writeable: true,
    },
    {
        label: 'Commentaires',
        accessor: 'comments',
        width: 300,
        writeable: true,
    },
];

export const chequesHeaders = [
    {
        label: 'Num. de chèque',
        accessor: 'number',
        width: 160,
        writeable: true,
        spaced: true,
    },
    {
        label: "Date d'émission",
        accessor: 'date_emission',
        width: 155,
        writeable: true,
        format: 'date',
    },
    {
        label: "Date d'échéance",
        accessor: 'date_echeance',
        width: 155,
        writeable: true,
        format: 'date',
    },
    {
        label: 'Date encaissement',
        accessor: 'date_encaissement',
        width: 170,
        writeable: true,
        format: 'date',
    },
    {
        label: 'Montant',
        accessor: 'total',
        getter: (data) => data?.payment?.total,
        width: 130,
        format: 'amount',
    },
    {
        label: 'Commentaires',
        accessor: 'comments',
        width: 250,
        writeable: true,
    },
];

export const paymentsCashHeaders = [
    {
        label: 'Date de paiement',
        accessor: 'payment_date',
        width: 170,
        writeable: true,
        format: 'date',
    },
    {
        label: 'Client',
        accessor: 'client__name',
        getter: (data) => data?.client?.name,
        width: 250,
        render: ClientRender,
        writeable: true,
    },
    {
        label: 'Montant',
        accessor: 'total',
        width: 140,
        inputWidth: 120,
        writeable: true,
        format: 'amount',
    },
    {
        label: 'Méthode',
        accessor: 'method',
        width: 150,
        writeable: true,
    },
    {
        label: 'Commentaires',
        accessor: 'comments',
        width: 250,
        unsortable: true,
        writeable: true,
    },
];

export const ventesHeaders = [
    {
        label: 'Numero BL',
        accessor: 'id_bl',
        width: 130,
        inputWidth: 130,
        writeable: false,
        spaced: true,
        style: {
            textAlign: 'right',
        },
    },
    {
        label: 'Date de vente',
        accessor: 'selling_date',
        width: 200,
        writeable: true,
        format: 'date',
    },
    {
        label: 'Client',
        accessor: 'client__name',
        getter: (data) => data?.client?.name,
        render: ClientRender,
        width: 220,
        writeable: true,
    },
    {
        label: 'Total',
        accessor: 'total_amount',
        width: 140,
        inputWidth: 120,
        format: 'amount',
    },
    {
        label: 'Nbr prods',
        accessor: 'total_products',
        width: 110,
        inputWidth: 120,
        format: 'number',
    },
    {
        label: 'Remise',
        accessor: 'global_discount',
        format: 'percent',
        width: 100,
        inputWidth: 60,
        unfilterable: true,
        writeable: true,
    },
    {
        label: 'Commentaires',
        accessor: 'comments',
        width: 250,
        writeable: true,
    },
];

export const achatsHeaders = [
    {
        label: 'Numero BL',
        accessor: 'num_bl',
        width: 130,
        inputWidth: 130,
        writeable: false,
        spaced: true,
        style: {
            textAlign: 'right',
        },
    },
    {
        label: "Date d'achat",
        accessor: 'buying_date',
        width: 200,
        writeable: true,
        format: 'date',
    },
    {
        label: 'Fournisseur',
        accessor: 'supplier__name',
        getter: (data) => data?.supplier?.name,
        width: 220,
        writeable: true,
    },
    {
        label: 'Total',
        accessor: 'total_amount',
        width: 140,
        inputWidth: 120,
        format: 'amount',
    },
    {
        label: 'Nbr prods',
        accessor: 'total_products',
        width: 110,
        inputWidth: 120,
        format: 'number',
    },
    {
        label: 'Commentaires',
        accessor: 'comments',
        width: 250,
        writeable: true,
    },
];

export const TYPES_DEPENSES = [
    {
        value: 'transport',
        label: 'Transport',
    },
    {
        value: 'telecom',
        label: 'Communications',
    },
    {
        value: 'catering',
        label: 'Restauration',
    },
    {
        value: 'material',
        label: 'Materiel',
    },
    {
        value: 'delivery',
        label: 'Livraison',
    },
];
