import React, { useState } from 'react';
import { Button } from '../../components/Button';
import { PageContent } from '../../components/PageContent';
import { Table } from '../../components/Table';
import { useInfiniteQuery } from 'react-query';
import { getFiltersSortingQuery } from '../../utils';
import { productsHeaders } from '../../config/products';
import { getProducts } from '../../api/products';
import { AddProductModal } from '../../components/Products/AddProductModal';
import { UpdateProductModal } from '../../components/Products/UpdateProductModal';
import { FiltersList } from '../../components/FiltersList';

export const GestionProduits = () => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [productData, setProductData] = useState(null);

    const [filters, setFilters] = useState([]);
    const [sorting, setSorting] = useState({});

    // GET PRODUCTS
    const { data, fetchNextPage, isLoading, isFetching } = useInfiniteQuery(
        ['products', getFiltersSortingQuery(filters, sorting)],
        getProducts,
        {
            getNextPageParam: (lastPage) => {
                return lastPage.has_next ? parseInt(lastPage.current_page) + 1 : undefined;
            },
        }
    );

    return (
        <PageContent>
            <AddProductModal show={showAddModal} onClose={() => setShowAddModal(false)} />
            <UpdateProductModal
                show={productData !== null}
                onClose={() => setProductData(null)}
                data={productData}
            />
            <div className="page-title-container">
                <div className="page-title">Gestion des produits</div>
                <div className="title-buttons">
                    <Button
                        variant="green-full"
                        label="Ajouter un produit"
                        onClick={() => setShowAddModal(true)}
                    />
                </div>
            </div>
            <FiltersList filters={filters} setFilters={setFilters} headers={productsHeaders} />
            <Table
                tableStyles={{
                    maxHeight: `calc(100vh - 180px)`,
                }}
                filters={filters}
                onFiltersChange={setFilters}
                style={{ marginTop: 24 }}
                headers={productsHeaders}
                data={data?.pages.map((p) => p.result).flat() || []}
                onLineSelect={(index, data) => {
                    setProductData(data);
                }}
                loadMore={() => fetchNextPage()}
                sorting={sorting}
                onSortingChange={setSorting}
                loading={isLoading || isFetching}
            />
        </PageContent>
    );
};
