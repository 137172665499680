import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { Modal } from './Modal';
import { Input } from './Input';
import { DateInput } from './DateInput';

const getFilterPos = (caller) => {
    if (!caller) return { x: 0, y: 0 };
    const targetCoordinates = caller.getBoundingClientRect();
    let x = targetCoordinates.left + targetCoordinates.width / 2;
    let y = targetCoordinates.bottom;
    return { x, y };
};

export const FilterModal = ({
    filterCaller,
    onClose,
    filterKey,
    label,
    show,
    onChange,
    value,
    secondValue,
    type,
}) => {
    const inputsContainerRef = useRef();
    const hasTwoInputs = type === 'number' || type === 'amount' || type === 'percent';
    return ReactDOM.createPortal(
        <Modal
            modalContainerStyle={{ background: 'transparent' }}
            style={{
                minWidth: 0,
                transform: 'translateX(-50%)',
                left: getFilterPos(filterCaller).x,
                top: getFilterPos(filterCaller).y,
                boxShadow: '0px 10px 39px -4px rgba(0, 0, 0, 0.4)',
            }}
            show={show}
            onClose={onClose}
        >
            <div className="filter-container">
                <div className="inputs-container" ref={inputsContainerRef}>
                    {type === 'date' ? (
                        <>
                            <DateInput
                                value={value}
                                onChange={(newValue) => {
                                    onChange({
                                        key: filterKey,
                                        value: newValue,
                                        secondValue,
                                        label,
                                        range: true,
                                    });
                                }}
                            />
                            <div className="arrow">{'->'}</div>
                            <DateInput
                                value={secondValue}
                                onChange={(newValue) => {
                                    onChange({
                                        key: filterKey,
                                        secondValue: newValue,
                                        value,
                                        label,
                                        range: true,
                                    });
                                }}
                            />
                        </>
                    ) : (
                        <Input
                            style={{ width: !hasTwoInputs && 150 }}
                            submitOnEnter
                            focusOnMount
                            format={type}
                            value={value || ''}
                            onChange={(value) => {
                                onChange({
                                    key: filterKey,
                                    value,
                                    label,
                                    secondValue,
                                    range: hasTwoInputs,
                                });
                                if (hasTwoInputs) {
                                    const secondInput =
                                        inputsContainerRef.current.querySelectorAll('input')[1];
                                    secondInput && secondInput.focus();
                                } else onClose();
                            }}
                        />
                    )}
                    {hasTwoInputs && (
                        <>
                            <div className="arrow">{'->'}</div>
                            <Input
                                format={type}
                                value={secondValue || ''}
                                onChange={(val) => {
                                    onChange({
                                        key: filterKey,
                                        secondValue: val,
                                        value,
                                        label,
                                        range: hasTwoInputs,
                                    });
                                    onClose();
                                }}
                            />
                        </>
                    )}
                </div>
            </div>
        </Modal>,

        document.querySelector('#filter-root')
    );
};
