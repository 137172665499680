import { BooleanSelect } from '../components/BooleanSelect';
import React from 'react';
import { deleteEmptyValues } from '../utils';
import { CompanySelect } from '../components/CompanySelect';

const BooleanRender = ({ value }) => {
    return value === true ? (
        <div style={{ color: 'green', textAlign: 'center', height: 0, marginTop: '-4px' }}>
            <span className="material-icons">check_box</span>
        </div>
    ) : null;
};
export const facturesHeaders = [
    {
        label: 'Num Facture',
        accessor: 'num_facture',
        width: 130,
    },
    {
        label: 'Date',
        accessor: 'created_at',
        width: 160,
        format: 'date',
    },
    {
        label: 'Client',
        accessor: 'client',
        width: 250,
    },

    {
        label: 'Total',
        accessor: 'total_amount',
        width: 120,
        format: 'amount',
    },
    {
        label: 'Livrée',
        accessor: 'delivered',
        render: BooleanRender,
        width: 90,
    },
    {
        label: 'Payée',
        accessor: 'paid',
        render: BooleanRender,
        width: 90,
    },
    {
        label: 'Société',
        accessor: 'company',
        width: 150,
        writeable: true,
    },
    {
        label: 'TVA 20%',
        accessor: 'total_tva_20',
        width: 110,
        format: 'amount',
    },
    {
        label: 'TVA 7%',
        accessor: 'total_tva_7',
        width: 110,
        format: 'amount',
    },
    {
        label: 'Nbr prods',
        accessor: 'total_products',
        width: 110,
        format: 'number',
    },
    {
        label: 'Commentaires',
        accessor: 'comments',
        width: 300,
    },
];

export const factureProductsHeaders = [
    {
        label: 'BL',
        accessor: 'num_bl',
        width: 110,
        unsortable: true,
        editable: true,
    },
    {
        label: 'Désignation',
        accessor: 'product_name',
        width: 300,
        unsortable: true,
        editable: true,
    },
    {
        label: 'Qté',
        accessor: 'quantity',
        width: 75,
        unsortable: true,
        format: 'number',
        editable: true,
    },
    {
        label: 'Prix U. TTC',
        accessor: 'selling_price',
        width: 120,
        unsortable: true,
        format: 'amount',
        editable: true,
    },
    {
        label: 'Total TTC',
        accessor: 'total_ttc',
        width: 130,
        unsortable: true,
        format: 'amount',
    },
    {
        label: 'TVA',
        accessor: 'tva',
        width: 70,
        unsortable: true,
        format: 'percent',
        editable: true,
    },
    {
        label: 'Total TVA',
        accessor: 'total_tva',
        width: 130,
        unsortable: true,
        format: 'amount',
    },
    {
        label: 'Total HT',
        accessor: 'total_ht',
        width: 130,
        unsortable: true,
        format: 'amount',
    },
];

export const updateFactureHeaders = [
    {
        label: 'Num facture',
        accessor: 'num_facture',
        width: 200,
        writeable: true,
        inputWidth: 80,
        format: 'number',
    },
    {
        label: 'Date de facture',
        accessor: 'created_at',
        width: 200,
        writeable: true,
        format: 'date',
    },
    {
        label: 'Client',
        accessor: 'client',
        width: 220,
        writeable: true,
    },
    {
        label: 'Société',
        accessor: 'company',
        width: 220,
        getter: (data) => data?.company,
        renderLine: CompanySelect,
        writeable: true,
    },
    {
        label: 'Livrée ?',
        accessor: 'delivered',
        renderLine: BooleanSelect,
        writeable: true,
    },
    {
        label: 'Payée ?',
        accessor: 'paid',
        renderLine: BooleanSelect,
        writeable: true,
    },
    {
        label: 'Commentaires',
        accessor: 'comments',
        width: 250,
        writeable: true,
    },
];

export const validateFactureData = (data) => {
    const newProducts = [...data.productline];
    const validProducts = newProducts.map(deleteEmptyValues);
    return { ...data, productline: validProducts };
};
