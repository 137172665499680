import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export const METHODS = [
    {
        value: 'cheque',
        label: 'Chèque',
    },
    {
        value: 'virement',
        label: 'Virement banquaire',
    },
    {
        value: 'cash',
        label: 'Espèces',
    },
];

const getMethod = (value) => {
    return METHODS.find((m) => m.value === value) || '';
};

export const MethodSelect = ({ write, value, onChange }) => {
    const [localValue, setLocalValue] = useState(getMethod(value));

    useEffect(() => {
        onChange(localValue?.value);
    }, [localValue]);

    useEffect(() => {
        setLocalValue(getMethod(value));
    }, [value]);

    return write ? (
        <Select
            styles={{
                control: (base) => ({
                    ...base,
                    height: '35px',
                    paddingLeft: '8px',
                    borderRadius: '4px',
                    background: '#f0f2f4',
                    boxShadow: 'inset 0px 1px 2px rgb(0 0 0 / 15%)',
                    border: 'none',
                }),
                menu: (base) => ({ ...base, zIndex: 9999 }),
            }}
            classNamePrefix="__custom_select"
            value={localValue}
            isSearchable={false}
            onChange={setLocalValue}
            options={METHODS}
        />
    ) : (
        <>{localValue?.label}</>
    );
};
