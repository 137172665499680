import axios from 'axios';
import { API_URL } from './constants';

export const authAxios = axios.create({
    baseURL: API_URL,
});

authAxios.interceptors.request.use(
    async (config) => {
        config.headers = {
            Authorization: `Token ${localStorage.getItem('token')}`,
        };
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

export const setToken = async () => {
    return axios
        .post(API_URL + '/login', {
            username: 'h2ss',
            password: 'h2ss',
        })
        .then((res) => {
            const token = res?.data?.token;
            localStorage.setItem('token', token);
            return token;
        })
        .catch((err) => {
            console.log(err?.response?.status);
        });
};

const refreshAccessToken = async () => {
    const token = await setToken();
    return token;
};

authAxios.interceptors.response.use(
    (response) => response,
    async function (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const access_token = await refreshAccessToken();
            authAxios.defaults.headers.common['Authorization'] = 'Token ' + access_token;
            return authAxios(originalRequest);
        }
        return Promise.reject(error);
    }
);
