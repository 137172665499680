import React from 'react';
import { Pill } from '../components/Pill';
import { getFilterLabel } from '../utils';

export const FiltersList = ({ filters, setFilters, headers }) => {
    return (
        filters.length > 0 && (
            <div className="filters-container">
                {filters.map((f, i) => (
                    <Pill
                        key={f.key}
                        onClick={() => {
                            setFilters((filters) => {
                                const newFilters = [...filters];
                                newFilters.splice(i, 1);
                                return newFilters;
                            });
                        }}
                        label={getFilterLabel(f, headers)}
                        withDelete
                    />
                ))}
            </div>
        )
    );
};
