import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getSuppliers } from '../api/suppliers';
import Select from 'react-select';

const valueAsOption = (val) => {
    return {
        ...val,
        value: val?.id_supplier,
        label: val?.name,
    };
};

export const SupplierSelect = ({ write, value, onChange }) => {
    const [localValue, setLocalValue] = useState(value || '');
    const [inputValue, setInputValue] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);
    const { data, isLoading } = useQuery(['suppliers', `&name=${inputValue}`], getSuppliers, {
        enabled: menuOpen,
    });


    useEffect(() => {
        value && setLocalValue(value);
    }, [value]);

    useEffect(() => {
        localValue && onChange(localValue);
    }, [localValue]);

    const getOptions = () =>
        data?.result.map((p) => ({ ...p, value: p.id_supplier, label: p.name })) || [];

    return write ? (
        <Select
            styles={{
                control: (base) => ({
                    ...base,
                    height: '35px',
                    paddingLeft: '8px',
                    borderRadius: '4px',
                    background: '#f0f2f4',
                    boxShadow: 'inset 0px 1px 2px rgb(0 0 0 / 15%)',
                    border: 'none',
                }),
                menu: (base) => ({ ...base, zIndex: 9999 }),
            }}
            onMenuClose={() => setMenuOpen(false)}
            onMenuOpen={() => setMenuOpen(true)}
            classNamePrefix="__custom_select"
            isSearchable
            onInputChange={setInputValue}
            inputValue={inputValue}
            value={valueAsOption(localValue)}
            onChange={(val) => setLocalValue({ name: val?.name, id_supplier: val?.id_supplier })}
            options={getOptions()}
            isLoading={isLoading}
        />
    ) : (
        <>{localValue?.name}</>
    );
};
