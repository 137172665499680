import React, { useEffect, useState } from 'react';

import { Button } from '../Button';
import { Modal } from '../Modal';
import { InfoModalContent } from '../InfoModalContent';
import { paniersHeaders } from '../../config/products';

export const NewPanierModal = ({
    loading,
    setLoading,
    show,
    onClose,
    data: panierData,
    onChange,
}) => {
    const [updateData, setUpdateData] = useState();

    useEffect(() => {
        if (panierData) setUpdateData({ ...panierData });
        else setUpdateData(null);
    }, [panierData]);

    const closeAndReset = () => {
        setLoading(false);
        onClose();
    };

    const buttons = (
        <>
            <Button
                onClick={() => {
                    setUpdateData(panierData);
                    closeAndReset();
                }}
                label={'Annuler'}
            />
            <Button
                variant="green-full"
                onClick={() => {
                    setLoading(true);
                    onChange(updateData);
                }}
                label={loading ? 'En cours ...' : 'confirmer'}
                loading={loading}
                disabled={loading}
            />
        </>
    );

    return (
        <Modal
            closeOnOutsideClick
            show={show}
            onClose={closeAndReset}
            title="Nouveau panier"
            buttons={buttons}
        >
            <InfoModalContent
                write
                data={updateData}
                setData={setUpdateData}
                lines={[paniersHeaders[0]]}
            />
        </Modal>
    );
};
