export const suppliersHeaders = [
    {
        label: 'Nom du fournisseur',
        accessor: 'name',
        width: 250,
        writeable: true,
    },
    {
        label: 'Nom du contact',
        accessor: 'contact_name',
        width: 160,
        writeable: true,
    },
    {
        label: 'Téléphone',
        accessor: 'phone',
        width: 200,
        writeable: true,
    },
    {
        label: 'Fax',
        accessor: 'fax',
        width: 200,
        writeable: true,
    },
    {
        label: 'Ville',
        accessor: 'city',
        width: 120,
        writeable: true,
        inputWidth: 120,
    },
    {
        label: 'ICE',
        accessor: 'ice',
        width: 150,
        writeable: true,
        unfilterable: true,
    },
    {
        label: 'Adresse',
        accessor: 'address',
        width: 300,
        writeable: true,
    },
    {
        label: 'Commentaires',
        accessor: 'comments',
        width: 300,
        writeable: true,
    },
];
