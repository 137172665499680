import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { updateClient, deleteClient } from '../../api/clients';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { InfoModalContent } from '../InfoModalContent';
import { clientsHeaders } from '../../config/clients';

export const UpdateClientModal = ({ show, onClose, data: clientData }) => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const queryClient = useQueryClient();

    const [updateData, setUpdateData] = useState();

    useEffect(() => {
        if (clientData) setUpdateData({ ...clientData });
        else setUpdateData(null);
    }, [clientData]);

    const closeAndReset = () => {
        setSubmitLoading(false);
        setIsUpdating(false);
        onClose();
    };

    // UPDATE CLIENT
    const updateMutation = useMutation(updateClient, {
        onSuccess: (data) => {
            console.log('update successful: ', data);
            queryClient.invalidateQueries('clients');
            toast.success('Client a été modifié avec succès!');
            closeAndReset();
        },
        onError: (err) => {
            console.log('update failed with error: ', err.response);
            toast.error('update failed with error: ' + JSON.stringify(err.response?.data));
            closeAndReset();
        },
    });

    // DELETE CLIENT
    const deleteMutation = useMutation(deleteClient, {
        onSuccess: (res) => {
            console.log('delete mutation successful: ', res);
            queryClient.invalidateQueries('clients');
            toast.success('Client a été supprimé avec succès!');
            closeAndReset();
        },
        onError: (err) => {
            console.log('delete failed with error: ', err.response);
            toast.error('delete failed with error: ' + JSON.stringify(err.response?.data));
            closeAndReset();
        },
    });

    const isSubmitDisabled = () => {
        if (!updateData?.name) return true;
        return false;
    };

    const updateButtons = (
        <>
            <Button
                label="annuler"
                onClick={() => {
                    setIsUpdating(false);
                    setUpdateData(clientData);
                }}
            />
            <Button
                variant="green-full"
                onClick={() => {
                    setSubmitLoading(true);
                    const newData = { ...updateData };
                    if (!newData?.comments) delete newData.comments;
                    if (!newData?.address) delete newData.address;
                    if (!newData?.city) delete newData.city;
                    if (!newData?.phone) delete newData.phone;

                    updateMutation.mutate(newData);
                }}
                label={submitLoading ? 'En cours ...' : 'confirmer'}
                loading={submitLoading}
                disabled={isSubmitDisabled() || submitLoading}
            />
        </>
    );

    const viewButtons = (
        <>
            <Button
                onClick={() => {
                    console.log('fiche client', clientData);
                    const win = window.open(
                        `/docs/fiche?name=${clientData?.name}&address=${clientData?.address}&phone=${clientData?.phone}`,
                        '_blank'
                    );
                    win.focus();
                }}
                label={'Fiche'}
            />
            <Button
                variant="red-full"
                onClick={() => {
                    setSubmitLoading(true);
                    deleteMutation.mutate(clientData.id_client);
                }}
                label={submitLoading ? 'En cours ...' : 'supprimer'}
                loading={submitLoading}
                disabled={submitLoading}
            />
            <Button variant="green-full" onClick={() => setIsUpdating(true)} label="modifier" />
        </>
    );

    return (
        <Modal
            closeOnOutsideClick={!isUpdating}
            show={show}
            onClose={closeAndReset}
            title={isUpdating ? 'Modifier un client' : ''}
            buttons={isUpdating ? updateButtons : viewButtons}
        >
            <InfoModalContent
                write={isUpdating}
                data={isUpdating ? updateData : clientData}
                setData={setUpdateData}
                lines={clientsHeaders}
            />
        </Modal>
    );
};
