import { SupplierSelect } from '../components/SupplierSelect';
import { ProductSelect } from '../components/ProductSelect';
import { deleteEmptyValues } from '../utils';
import { MethodSelect } from '../components/MethodSelect';
import { CompanySelect } from '../components/CompanySelect';

export const purchasesHeaders = [
    {
        label: 'Numero BL',
        accessor: 'num_bl',
        width: 130,
        inputWidth: 130,
        writeable: false,
        spaced: true,
        style: {
            textAlign: 'right',
        },
    },
    {
        label: "Date d'achat",
        accessor: 'buying_date',
        width: 200,
        writeable: true,
        format: 'date',
    },
    {
        label: 'Fournisseur',
        accessor: 'supplier__name',
        getter: (data) => data?.supplier?.name,
        width: 220,
        writeable: true,
    },
    {
        label: 'Total',
        accessor: 'total_amount',
        width: 140,
        inputWidth: 120,
        format: 'amount',
    },
    {
        label: 'Nbr prods',
        accessor: 'total_products',
        width: 110,
        inputWidth: 120,
        format: 'number',
    },
    {
        label: 'TVA 20%',
        accessor: 'total_tva_20',
        width: 110,
        format: 'amount',
    },
    {
        label: 'TVA 7%',
        accessor: 'total_tva_7',
        width: 110,
        format: 'amount',
    },
    {
        label: 'Methode paiement',
        accessor: 'method',
        width: 170,
        inputWidth: 120,
    },
    {
        label: 'Ref chèque',
        accessor: 'cheque__number',
        getter: (data) => data?.cheque?.number,
        width: 140,
        inputWidth: 120,
        unsortable: true,
    },
    {
        label: 'Ref virement',
        accessor: 'virement__number',
        getter: (data) => data?.virement?.number,
        width: 140,
        inputWidth: 120,
        unsortable: true,
    },
    {
        label: 'Société',
        accessor: 'company',
        width: 150,
        writeable: true,
    },
    {
        label: 'Commentaires',
        accessor: 'comments',
        width: 250,
        writeable: true,
    },
];

export const purchasesHeadersDetails = [
    {
        label: 'Numero BL',
        accessor: 'bon_commande__num_bl',
        getter: (data) => data?.bon_commande?.num_bl,
        width: 130,
        inputWidth: 130,
        writeable: false,
        spaced: true,
        style: {
            textAlign: 'right',
        },
    },
    {
        label: "Date d'achat",
        accessor: 'bon_commande__buying_date',
        getter: (data) => data?.bon_commande?.buying_date,
        width: 160,
        writeable: true,
        format: 'date',
    },
    {
        label: 'Fournisseur',
        accessor: 'bon_commande__supplier__name',
        getter: (data) => data?.bon_commande?.supplier?.name,
        width: 180,
        writeable: true,
    },
    {
        label: 'Designation',
        accessor: 'product__name',
        getter: (data) => data?.product?.name,
        width: 350,
        inputWidth: 120,
    },
    {
        label: 'P.Achat TTC',
        accessor: 'buying_price_ttc',
        getter: (data) => data?.buying_price_ttc,
        width: 125,
        inputWidth: 120,
        format: 'amount',
    },
    {
        label: 'Qté',
        accessor: 'quantity',
        getter: (data) => data?.quantity,
        width: 80,
        inputWidth: 120,
        format: 'number',
    },
];

export const productsHeaders = [
    {
        label: 'Produit',
        accessor: 'product_name',
        getter: (data) => data,
        width: 450,
        writeable: true,
        unsortable: true,
        unfilterable: true,
        editable: true,
        render: ProductSelect,
    },
    {
        label: "Prix d'achat TTC",
        accessor: 'buying_price_ttc',
        width: 130,
        format: 'amount',
        writeable: true,
        unsortable: true,
        unfilterable: true,
        editable: true,
    },
    {
        label: 'Quantité',
        accessor: 'quantity',
        width: 90,
        writeable: true,
        format: 'number',
        unsortable: true,
        unfilterable: true,
        editable: true,
    },
    {
        label: 'Prix Total TTC',
        accessor: 'total_amount',
        width: 140,
        format: 'amount',
        writeable: true,
        unfilterable: true,
        unsortable: true,
    },
    {
        label: 'TVA',
        accessor: 'tva',
        width: 140,
        format: 'percent',
        unfilterable: true,
        unsortable: true,
    },
];

export const validatePurchaseData = (data) => {
    const newData = { ...data };
    if (!newData.comments) delete newData.comments;
    const newProducts = [...newData.products];
    const validProducts = newProducts.map(deleteEmptyValues);

    return { ...newData, products: validProducts };
};

export const newPurchaseHeaders = [
    {
        label: 'Numero BL',
        accessor: 'num_bl',
        width: 200,
        writeable: true,
    },
    {
        label: "Date d'achat",
        accessor: 'buying_date',
        width: 200,
        writeable: true,
        format: 'date',
    },
    {
        label: 'Fournisseur',
        accessor: 'supplier__name',
        width: 220,
        getter: (data) => data?.supplier,
        renderLine: SupplierSelect,
        writeable: true,
    },
    {
        label: 'Société',
        accessor: 'company',
        width: 220,
        getter: (data) => data?.company,
        renderLine: CompanySelect,
        writeable: true,
    },
    {
        label: 'Méthode de paiement',
        accessor: 'method',
        width: 150,
        renderLine: MethodSelect,
        writeable: true,
    },
    {
        label: 'Ref. chèque',
        accessor: 'cheque__number',
        getter: (data) => data?.cheque?.number,
        width: 150,
        inputWidth: 150,
        writeable: true,
        spaced: true,
    },
    {
        label: 'Ref. virement',
        accessor: 'virement__number',
        getter: (data) => data?.virement?.number,
        width: 150,
        inputWidth: 150,
        writeable: true,
        spaced: true,
    },
    {
        label: 'Commentaires',
        accessor: 'comments',
        width: 250,
        writeable: true,
    },
];

export const getProductToAdd = (productData) => {
    return {
        id_product: productData.id_product,
        name: productData.name,
        buying_price_ttc: productData.buying_price_ttc,
        total_amount: productData.buying_price_ttc,
        quantity: 1,
        tva: productData.tva,
    };
};

export const addProductsheaders = [
    {
        label: 'Produit',
        accessor: 'name',
        width: 350,
        unsortable: true,
        unfilterable: true,
    },
    {
        label: "Prix d'achat",
        accessor: 'buying_price_ttc',
        width: 115,
        format: 'amount',
        unsortable: true,
        unfilterable: true,
        editable: true,
    },
    {
        label: 'Quantité',
        accessor: 'quantity',
        width: 90,
        format: 'number',
        unsortable: true,
        unfilterable: true,
        editable: true,
    },
    {
        label: 'Prix total TTC',
        accessor: 'total_amount',
        width: 140,
        format: 'amount',
        unfilterable: true,
        unsortable: true,
    },
    {
        label: 'TVA',
        accessor: 'tva',
        width: 140,
        format: 'percent',
        unfilterable: true,
        unsortable: true,
    },
];

export const preparePurchaseData = (data) => {
    const newData = { ...data };
    if (!newData?.comments) delete newData.comments;
    if (!newData?.num_bl) delete newData.num_bl;

    if (newData.method === 'cheque' && newData.cheque__number) {
        newData.cheque = {
            ...newData.cheque,
            number: newData.cheque__number,
        };
        delete newData.virement;
    }
    if (newData.method === 'virement' && newData.virement__number) {
        newData.virement = {
            ...newData.virement,
            number: newData.virement__number,
        };
        delete newData.cheque;
    }
    if (!newData.method) {
        newData.method = 'cash';
        delete newData.cheque;
        delete newData.virement;
    }

    return newData;
};
