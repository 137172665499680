import React from 'react';
import H2SS from '../assets/h2ss.png';
import H2SSMED from '../assets/h2ss-med.png';
import DMEXPERT from '../assets/dm-expert.png';

const logos = {
    H2SSMED,
    H2SS,
    DMEXPERT,
};

export const Logos = ({ style, company = 'H2SSMED' }) => {
    return (
        <div style={style}>
            <div style={{ padding: 8, cursor: 'pointer', width: '100%', height: '100%' }}>
                <img src={logos[company]} style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </div>
        </div>
    );
};
