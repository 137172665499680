import { ProductsSelectPanier } from '../components/ProductsSelectPanier';

export const productsHeaders = [
    {
        label: 'Désignation',
        accessor: 'name',
        width: 330,
        writeable: true,
    },
    {
        label: 'Stock',
        accessor: 'inventory',
        width: 90,
        writeable: true,
        format: 'number',
        inputWidth: 90,
    },
    {
        label: 'P Achat TTC',
        accessor: 'buying_price_ttc',
        width: 125,
        format: 'amount',
        inputWidth: 120,
    },
    {
        label: 'Prix de vente',
        accessor: 'selling_price',
        width: 130,
        writeable: true,
        format: 'amount',
        inputWidth: 110,
    },
    {
        label: 'Dernier fournisseur',
        accessor: 'last_supplier',
        width: 180,
    },
    {
        label: 'TVA',
        accessor: 'tva',
        width: 80,
        writeable: true,
        inputWidth: 70,
        format: 'percent',
    },
    {
        label: 'Catégorie',
        accessor: 'category',
        width: 210,
        writeable: true,
    },
    {
        label: 'Stock min.',
        accessor: 'safety_inventory_limit',
        width: 115,
        writeable: true,
        format: 'number',
        inputWidth: 90,
    },

    {
        label: 'P.Achat moyen',
        accessor: 'average_buying_price',
        width: 150,
        format: 'amount',
    },
    {
        label: 'Barcode',
        accessor: 'barcode',
        width: 180,
        writeable: true,
    },
    {
        label: 'Dernière vente',
        accessor: 'last_sell_date',
        width: 190,
        format: 'date',
    },
    {
        label: 'Commentaires',
        accessor: 'comments',
        width: 300,
        writeable: true,
    },
    {
        label: 'Description',
        accessor: 'description',
        width: 300,
        writeable: true,
    },
];

export const paniersHeaders = [
    {
        label: 'Panier',
        accessor: 'name',
        width: 400,
        writeable: true,
        unfilterable: true,
        unsortable: true,
    },
    {
        label: 'Produits',
        accessor: 'products',
        getter: (data) => data?.products?.length,
        format: 'number',
        width: 120,
        writeable: false,
        unfilterable: true,
        unsortable: true,
    },
];

export const productsHeadersPaniers = [
    {
        label: 'Désignation',
        accessor: 'name',
        getter: (data) => data,
        width: 450,
        writeable: true,
        unsortable: true,
        unfilterable: true,
        editable: true,
        render: ProductsSelectPanier,
    },
    {
        label: 'Stock',
        accessor: 'inventory',
        width: 90,
        writeable: true,
        format: 'number',
        inputWidth: 90,
    },
    {
        label: 'P Achat TTC',
        accessor: 'buying_price_ttc',
        width: 125,
        format: 'amount',
        inputWidth: 120,
    },
    {
        label: 'Prix de vente',
        accessor: 'selling_price',
        width: 130,
        writeable: true,
        format: 'amount',
        inputWidth: 110,
    },
    {
        label: 'Dernier fournisseur',
        accessor: 'last_supplier',
        width: 180,
    },
    {
        label: 'TVA',
        accessor: 'tva',
        width: 80,
        writeable: true,
        inputWidth: 70,
        format: 'percent',
    },
    {
        label: 'Catégorie',
        accessor: 'category',
        width: 210,
        writeable: true,
    },
    {
        label: 'Stock min.',
        accessor: 'safety_inventory_limit',
        width: 115,
        writeable: true,
        format: 'number',
        inputWidth: 90,
    },

    {
        label: 'P.Achat moyen',
        accessor: 'average_buying_price',
        width: 150,
        format: 'amount',
    },
    {
        label: 'Barcode',
        accessor: 'barcode',
        width: 180,
        writeable: true,
    },
    {
        label: 'Dernière vente',
        accessor: 'last_sell_date',
        width: 190,
        format: 'date',
    },
    {
        label: 'Commentaires',
        accessor: 'comments',
        width: 300,
        writeable: true,
    },
    {
        label: 'Description',
        accessor: 'description',
        width: 300,
        writeable: true,
    },
];
