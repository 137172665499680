import React, { useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { PageContent } from '../../components/PageContent';
import { Button } from '../../components/Button';
import { Table } from '../../components/Table';
import { getFiltersSortingQuery } from '../../utils';
import { salesHeaders, productsHeaders, validateSaleData } from '../../config/sales';
import { getSales, updateSale } from '../../api/sales';
import { FiltersList } from '../../components/FiltersList';
import { toast } from 'react-toastify';
import { SaleDetailsModal } from '../../components/Ventes/SaleDetailsModal';
import { generateFacture } from '../../api/factures';
import { useHistory } from 'react-router-dom';

export const GestionVentes = () => {
    const [filters, setFilters] = useState([]);
    const [sorting, setSorting] = useState({
        key: 'selling_date',
        type: 'desc',
    });
    const [saleData, setSaleData] = useState({});
    const [selectedLines, setSelectedLines] = useState([]);
    const [productsWriteable, setProductsWriteable] = useState(false);
    const [tempSaleData, setTempSaleData] = useState({});
    const [salesData, setSalesData] = useState([]);
    const [saveProductsLoading, setSaveProductsLoading] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);

    const queryClient = useQueryClient();

    const history = useHistory();

    useEffect(() => {
        setSaleData({});
        setSelectedLines(null);
    }, [sorting, filters]);

    // GET SALES
    const { data, fetchNextPage, isLoading, isFetching } = useInfiniteQuery(
        ['sales', getFiltersSortingQuery(filters, sorting)],
        getSales,
        {
            getNextPageParam: (lastPage) => {
                return lastPage.has_next ? parseInt(lastPage.current_page) + 1 : undefined;
            },
        }
    );

    const generateFactureMutation = useMutation(generateFacture, {
        onSuccess: (data) => {
            console.log('generate facture successful: ', data);
            queryClient.invalidateQueries('factures');
            history.push('/utilitaires/factures');
        },
        onError: (err) => {
            console.log('generate facture failed with error: ', err.response);
            toast.error(
                'generate facture failed with error: ' + JSON.stringify(err.response?.data)
            );
        },
    });

    useEffect(() => {
        setSalesData(data?.pages.map((p) => p.result).flat() || []);
    }, [data]);

    useEffect(() => {
        const data = salesData[selectedLines];
        if (data) setSaleData(getSaleData(data));
    }, [salesData, selectedLines]);

    // UPDATE SALE
    const updateMutation = useMutation(updateSale, {
        onSuccess: (data) => {
            console.log('update successful: ', data);
            queryClient.invalidateQueries('sales');
            toast.success('Vente a été modifiée avec succès!');
            setProductsWriteable(false);
            setSaveProductsLoading(false);
        },
        onError: (err) => {
            console.log('update failed with error: ', err.response);
            toast.error('update failed with error: ' + JSON.stringify(err.response?.data));
            setProductsWriteable(false);
            setSaveProductsLoading(false);
        },
    });

    const handleProductDelete = (i) => {
        setTempSaleData((old) => {
            const products = [...old.products];
            products.splice(i, 1);
            return { ...old, products };
        });
    };

    const handleNewProduct = () => {
        setTempSaleData((old) => ({ ...old, products: [...old.products, {}] }));
    };

    const handleProductChange = (val, i, key) => {
        setTempSaleData((old) => {
            const products = [...old.products];
            const productToUpdate = { ...products[i] };
            productToUpdate[key] = val;
            products[i] = productToUpdate;
            return getSaleData({ ...old, products });
        });
    };

    const handleSaveProducts = () => {
        setSaveProductsLoading(true);
        tempSaleData;
        const dataToSet = validateSaleData(getSaleData(tempSaleData));
        setSaleData(dataToSet);
        updateMutation.mutate(dataToSet);
    };

    const getSaleData = (data) => {
        const products = data.products
            .map((p) => {
                if (!p.product_name) return null;
                return {
                    ...p,
                    total_amount: p.selling_price * p.quantity,
                    product: p.id_product,
                };
            })
            .filter((p) => p);
        return { ...data, products };
    };

    const getProductsHeaders = () => {
        const newProductsHeaders = [...productsHeaders];
        newProductsHeaders[0].onChange = (value, lineIndex) => {
            setTempSaleData((old) => {
                const products = [...old?.products];
                const newProduct = {
                    ...value,
                    total_amount: value.selling_price * value.quantity,
                };
                products[lineIndex] = newProduct;
                return { ...old, products };
            });
        };
        return newProductsHeaders;
    };

    return (
        <PageContent style={{ paddingBottom: 300 }}>
            <SaleDetailsModal
                show={showDetailsModal}
                onClose={() => setShowDetailsModal(false)}
                data={saleData}
            />
            <div className="page-title-container">
                <div className="page-title">Gestion des ventes</div>
            </div>
            <FiltersList filters={filters} setFilters={setFilters} headers={salesHeaders} />
            <Table
                tableStyles={{
                    maxHeight: `235px`,
                }}
                filters={filters}
                onFiltersChange={setFilters}
                style={{ marginTop: 24 }}
                headers={salesHeaders}
                data={salesData}
                onLineSelect={(id, data) => {
                    setSaleData(getSaleData(data));
                    setSelectedLines([id]);
                }}
                selectedLines={selectedLines}
                loadMore={() => fetchNextPage()}
                sorting={sorting}
                onSortingChange={setSorting}
                loading={isLoading || isFetching}
            />
            {saleData.id_bl && (
                <>
                    <div className="separator" />
                    <div className="page-title-container">
                        <div className="page-title">
                            <span style={{ opacity: 0.2, fontWeight: 400 }}>BL #</span>{' '}
                            {saleData.id_bl}
                        </div>
                        <div className="title-buttons">
                            {productsWriteable ? (
                                <>
                                    <Button
                                        label="annuler"
                                        onClick={() => setProductsWriteable(false)}
                                    />

                                    <Button
                                        variant="green-full"
                                        label="Enregistrer"
                                        disabled={saveProductsLoading}
                                        loading={saveProductsLoading}
                                        onClick={handleSaveProducts}
                                    />
                                </>
                            ) : (
                                <>
                                    <Button
                                        variant="green"
                                        label="modifier produits"
                                        onClick={() => {
                                            setTempSaleData({ ...saleData });
                                            setProductsWriteable(true);
                                        }}
                                    />
                                    <Button
                                        onClick={() => setShowDetailsModal(true)}
                                        variant="green"
                                        label="modifier informations"
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <Table
                        key={saleData.id_bl + productsWriteable ? '_temp' : ''}
                        style={{ marginTop: 32 }}
                        tableStyles={{ maxHeight: 'unset' }}
                        headers={getProductsHeaders()}
                        data={
                            productsWriteable ? [...tempSaleData.products] : [...saleData.products]
                        }
                        canEdit={productsWriteable}
                        onDeleteLine={productsWriteable && handleProductDelete}
                        onCellEdit={handleProductChange}
                    />
                    {productsWriteable && (
                        <Button
                            style={{ marginTop: 16 }}
                            onClick={handleNewProduct}
                            label="Ajouter un produit"
                        />
                    )}
                    <div className="bottom-bar">
                        <div className="left-part">
                            <Button variant="red" label="supprimer cette vente" />
                        </div>
                        <div className="buttons-container">
                            <Button
                                icon="print"
                                label="imprimer bon de livraison"
                                onClick={() => {
                                    const win = window.open(
                                        `/docs/bl?id=${saleData.id_bl}`,
                                        '_blank'
                                    );
                                    win.focus();
                                }}
                            />
                            <Button
                                icon="print"
                                label="générer une facture"
                                onClick={() => {
                                    const id_bls = [saleData?.id_bl];
                                    generateFactureMutation.mutate(id_bls);
                                }}
                            />
                        </div>
                    </div>
                </>
            )}
        </PageContent>
    );
};
