import React, { useEffect, useState } from 'react';

import { Button } from '../Button';
import { Modal } from '../Modal';
import { InfoModalContent } from '../InfoModalContent';
import { newPurchaseHeaders } from '../../config/purchases';

export const NewPurchaseModal = ({
    loading,
    setLoading,
    show,
    onClose,
    data: purchaseData,
    onChange,
}) => {
    const [updateData, setUpdateData] = useState({});

    useEffect(() => {
        if (purchaseData) setUpdateData({ ...purchaseData });
        else setUpdateData({});
    }, [purchaseData]);

    const closeAndReset = () => {
        setLoading(false);
        onClose();
    };

    useEffect(() => {
        setUpdateData((oldData) => ({ ...oldData, virement__number: null, cheque__number: null }));
    }, [updateData?.method]);

    console.log('updateData', updateData);

    const isSubmitDisabled = () => {
        if (!updateData?.id_supplier) return true;
        if (updateData?.method === 'cheque' && !updateData?.cheque__number) return true;
        if (updateData?.method === 'virement' && !updateData?.virement__number) return true;
        return false;
    };

    const buttons = (
        <>
            <Button
                onClick={() => {
                    setUpdateData(purchaseData);
                    onClose();
                }}
                label={'Annuler'}
            />
            <Button
                variant="green-full"
                onClick={() => {
                    setLoading(true);
                    onChange(updateData);
                }}
                label={loading ? 'En cours ...' : 'confirmer'}
                loading={loading}
                disabled={loading || isSubmitDisabled()}
            />
        </>
    );

    const updateSupplier = (newSupplier) => {
        console.log('newSupplier', newSupplier);

        newSupplier &&
            setUpdateData((oldData) => ({
                ...oldData,
                id_supplier: newSupplier?.id_supplier,
                supplier: newSupplier,
                supplier__name: newSupplier?.name,
            }));
    };

    const getHeaders = () => {
        const newHeaders = [...newPurchaseHeaders];
        newHeaders.find((el) => el.accessor === 'supplier__name').onLineChange = updateSupplier;
        const showChequeInput = updateData.method === 'cheque';
        const showVirementInput = updateData.method === 'virement';
        return newHeaders.filter((h) => {
            if (!showChequeInput && h.accessor === 'cheque__number') return false;
            if (!showVirementInput && h.accessor === 'virement__number') return false;
            return true;
        });
    };

    return (
        <Modal
            closeOnOutsideClick
            show={show}
            onClose={closeAndReset}
            title={
                <>
                    Informations
                    <br />
                    supplémentaires
                </>
            }
            buttons={buttons}
        >
            <InfoModalContent
                write
                data={updateData}
                setData={setUpdateData}
                lines={getHeaders()}
            />
        </Modal>
    );
};
