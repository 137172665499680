import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { updatePaniers } from '../../api/products';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { InfoModalContent } from '../InfoModalContent';
import { paniersHeaders } from '../../config/products';

export const PanierDetailsModal = ({ show, onClose, data: panierData }) => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const queryClient = useQueryClient();

    const [updateData, setUpdateData] = useState();

    useEffect(() => {
        if (panierData) setUpdateData({ ...panierData });
        else setUpdateData(null);
    }, [panierData]);

    const closeAndReset = () => {
        setSubmitLoading(false);
        onClose();
    };

    // UPDATE SALE
    const updateMutation = useMutation(updatePaniers, {
        onSuccess: (data) => {
            console.log('update successful: ', data);
            queryClient.invalidateQueries('paniers');
            toast.success('Vente a été modifié avec succès!');
            closeAndReset();
        },
        onError: (err) => {
            console.log('update failed with error: ', err.response);
            toast.error('update failed with error: ' + JSON.stringify(err.response?.data));
            closeAndReset();
        },
    });

    const buttons = (
        <>
            <Button
                onClick={() => {
                    setUpdateData(panierData);
                    onClose();
                }}
                label={'Annuler'}
            />
            <Button
                variant="green-full"
                onClick={() => {
                    setSubmitLoading(true);
                    const dataToUpdate = { ...updateData };
                    dataToUpdate.products = dataToUpdate.products.map((c) => c.id_product);
                    updateMutation.mutate(dataToUpdate);
                }}
                label={submitLoading ? 'En cours ...' : 'confirmer'}
                loading={submitLoading}
                disabled={submitLoading}
            />
        </>
    );

    return (
        <Modal
            closeOnOutsideClick
            show={show}
            onClose={closeAndReset}
            title={'Modifier panier'}
            buttons={buttons}
        >
            <InfoModalContent
                write
                data={updateData}
                setData={setUpdateData}
                lines={paniersHeaders}
            />
        </Modal>
    );
};
