import React from 'react';
import { PageContent } from '../../components/PageContent';
import { useQuery } from 'react-query';
import { Table } from '../../components/Table';
import {
    achatsHeaders,
    chequesHeaders,
    depensesHeaders,
    paymentsCashHeaders,
    ventesHeaders,
} from '../../config/caisse';
import { getDisplayValue } from '../../utils';
import { getCaisseJour } from '../../api/caisse';

export const Caisse = () => {
    const { data, isLoading } = useQuery(['caisse-jour'], getCaisseJour);

    return (
        <PageContent style={{ paddingBottom: 100 }}>
            <div className="page-title-container">
                <div className="page-title">Caisse du jour</div>
            </div>
            <div className="separator" />

            {/* --------------- DEPENSES ----------------- */}
            <div className="table-title">
                <div className="title-part">Dépenses</div>
                {!!data?.total_depense && (
                    <div className="info-part">
                        <div className="info-label">Total dépenses</div>
                        <div className="info-value">
                            {getDisplayValue(data?.total_depense, 'amount')}
                        </div>
                    </div>
                )}
            </div>
            <Table
                style={{ marginTop: 24 }}
                headers={depensesHeaders}
                data={data?.depense || []}
                loading={isLoading}
            />
            <div className="separator" />

            {/* --------------- CASH RECUS ----------------- */}
            <div className="table-title">
                <div className="title-part">Cash reçu</div>
                {!!data?.total_cash && (
                    <div className="info-part">
                        <div className="info-label">Total cash reçu</div>
                        <div className="info-value">{data?.total_cash}</div>
                    </div>
                )}
            </div>
            <Table
                style={{ marginTop: 24 }}
                headers={paymentsCashHeaders}
                data={data?.cash || []}
                loading={isLoading}
            />
            <div className="separator" />

            {/* --------------- CHEQUES RECUS ----------------- */}
            <div className="table-title">
                <div className="title-part">Chèques reçus</div>
                {!!data?.total_cheque && (
                    <div className="info-part">
                        <div className="info-label">Nb chèques recus</div>
                        <div className="info-value">{data?.total_cheque}</div>
                    </div>
                )}
            </div>
            <Table
                style={{ marginTop: 24 }}
                headers={chequesHeaders}
                data={data?.cheque || []}
                loading={isLoading}
            />
            <div className="separator" />

            {/* --------------- VENTES DU JOUR ----------------- */}
            <div className="table-title">
                <div className="title-part">Ventes du jour</div>
                {!!data?.today_total_sales && (
                    <div className="info-part">
                        <div className="info-label">Total ventes du jour</div>
                        <div className="info-value">{data?.today_total_sales}</div>
                    </div>
                )}
            </div>
            <Table
                style={{ marginTop: 24 }}
                headers={ventesHeaders}
                data={data?.today_sales || []}
                loading={isLoading}
            />
            <div className="separator" />

            {/* --------------- ACHATS DU JOUR ----------------- */}
            <div className="table-title">
                <div className="title-part">Achats du jour</div>
                {!!data?.today_total_achat && (
                    <div className="info-part">
                        <div className="info-label">Total achats du jour</div>
                        <div className="info-value">{data?.today_total_achat}</div>
                    </div>
                )}
            </div>
            <Table
                style={{ marginTop: 24 }}
                headers={achatsHeaders}
                data={data?.today_achat || []}
                loading={isLoading}
            />
            {data && (
                <div className="bottom-bar">
                    <div className="left-part">
                        <span className="footer-info-line">
                            <span className="info-line-key blue">Cash en caisse</span>
                            <span className="info-line-value">
                                {getDisplayValue(data?.total_cash, 'amount')}
                            </span>
                        </span>
                        <span className="footer-info-line">
                            <span className="info-line-key blue">
                                {"Chiffre d'affaire du jour"}
                            </span>
                            <span className="info-line-value">
                                {getDisplayValue(data?.today_total_sales, 'amount')}
                            </span>
                        </span>
                    </div>
                </div>
            )}
        </PageContent>
    );
};
