import React from 'react';

const footers = {
    H2SSMED:
        'CNSS 2152177 - T.P. 13101295 - IF 47255912 - RC 64473 - Email: medh2ss@gmail.com\nN5 RCE NOUZHA RUE ABDELWAHED IRAQI APPT 1 VN FES - Fes - Tel.: 05 35 62 37 10 - Fax: 05 35 62 36 88\nRIB 007 270 0004718000000340 85 / A.W.B AGENCE FES ATLAS\n002621539000026',
    H2SS: 'CNSS 7830723 - T.P. 13232103 - IF 40176466 - RC 299919 - Email: medh2ss@gmail.com\n Av Omar Drissi Imb 50 Mag. N* 4 - Fés - Tel.: 05 35 62 37 10 - Fax: 05 35 62 36 88\nRIB 007 270 0004711000000173 39 - A.W.B AGENCE FES ATLAS\n ICE: 000519656000047',
    DMEXPERT:
        'T.P. 14285324 - RC 44243 - IF 15177855 - CNSS 4073631\nLots El Wafae, 5 Lot 37, Fès - Tel. 05 35 62 37 10 - Fax: 05 35 62 36 88\nEmail: medh2ss@gmail.com\nRIB - 007 270 0004714000000258 60 A.W.B\nICE 000517414000053',
};

export const FootersInfo = ({ style, company = 'H2SSMED' }) => {
    return <div style={style}>{footers[company]}</div>;
};
