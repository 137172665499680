import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Logos } from '../../components/Logos';
import { PrintButton } from '../../components/PrintButton';

const StyledFicheClient = styled.div`
    @page {
        size: A4 landscape;
        margin: 0mm;
    }
    .container {
        height: 20.9cm;
        width: 29.6cm;
        padding: 40mm;
        padding-top: 30mm;
        .content {
            .header {
                display: flex;
                width: 100%;
                justify-content: space-between;
                .company-info {
                    font-weight: bold;
                    height: 30mm;
                    text-align: right;
                    .company-name {
                        font-size: 32px;
                        margin-bottom: 2mm;
                    }
                    .company-phone {
                        font-size: 24px;
                    }
                }
            }
            .body {
                border: 2px solid rgba(0, 0, 0, 0.3);
                padding: 15mm;
                text-align: center;
                position: relative;
                .destinataire-label {
                    position: absolute;
                    border: 2px solid rgba(0, 0, 0, 0.3);
                    background: white;
                    padding: 3mm;
                    top: 0;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0);
                    font-weight: bold;
                    font-size: 16px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    color: rgba(0, 0, 0, 0.3);
                }
                .client-name {
                    font-weight: bold;
                    font-size: 32px;
                }
                .phone {
                    font-weight: bold;
                    font-size: 28px;
                }
                .address {
                    background: rgba(0, 0, 0, 0.05);
                    padding: 10mm;
                    font-size: 24px;
                    font-weight: bold;
                    margin: 5mm 0;
                }
            }
        }
    }
`;

export const FicheClient = () => {
    const history = useHistory();
    const name = new URLSearchParams(history.location.search).get('name');
    const phone = new URLSearchParams(history.location.search).get('phone');
    const address = new URLSearchParams(history.location.search).get('address');
    console.log(name, phone, address);

    return (
        <StyledFicheClient>
            <div className="container">
                <div className="content">
                    <div className="header">
                        <div className="logo-part">
                            <Logos style={{ transform: 'translateY(-20%)' }} />
                        </div>
                        <div className="company-info">
                            <div className="company-name" contentEditable>
                                Société H2SS
                            </div>
                            <div className="company-phone" contentEditable>
                                05 37 75 10 98
                            </div>
                        </div>
                    </div>
                    <div className="body">
                        <div className="destinataire-label">Destinataire</div>
                        <div className="client-name" contentEditable>
                            {name}
                        </div>
                        <div className="address" contentEditable>
                            {address}
                        </div>
                        <div className="phone" contentEditable>
                            {phone}
                        </div>
                    </div>
                </div>
            </div>
            <PrintButton />
        </StyledFicheClient>
    );
};
