import React from 'react';
import styled, { css } from 'styled-components';

const selectedLineStyles = css`
    background: #dff6df;
    box-shadow: inset 0px 0px 0px 1px rgba(47, 167, 47, 0.5);
    &:nth-child(2n) {
        background: #dff6df;
    }
    &:hover:not(:first-child) {
        background: #dff6df;
        box-shadow: inset 0px 0px 0px 1px rgba(47, 167, 47, 0.5);
    }
`;

const StyledTableLine = styled.div`
    display: inline-flex;
    border-bottom: 1px solid #d3d7de;
    background: #ffffff;
    padding-right: 16px;
    min-width: 100%;
    &:nth-child(2n) {
        background: #f6f7f8;
    }
    &:first-child {
        border-radius: 5px 5px 0 0;
        z-index: 3;
        position: sticky;
        top: 0;
    }
    &:last-child {
        border-radius: 0 0 5px 5px;
        border-bottom: none;
    }
    will-change: transform;
    &:hover:not(:first-child) {
        cursor: pointer;
        position: relative;
        z-index: 2;
        background: #ffffff;
        box-shadow: 0px 0px 15px hsl(218deg 14% 86%);
    }

    ${({ selected }) => selected && selectedLineStyles}
`;

export const TableLine = ({ children, onClick, selected }) => {
    return (
        <StyledTableLine selected={selected} onClick={onClick}>
            {children}
        </StyledTableLine>
    );
};
