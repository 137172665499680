import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { getSale } from '../../api/sales';
import { FootersInfo } from '../../components/FootersInfo';
import { Logos } from '../../components/Logos';
import { PrintButton } from '../../components/PrintButton';
import { formatDate, getDisplayValue } from '../../utils';

const StyledBL = styled.div`
    width: 14.8cm;
    margin: auto;
    box-shadow: 0px 0px 200px 0px rgba(0, 0, 0, 0.25);
    @page {
        size: A5;
        margin: 2mm;
    }
    .page-header {
        height: 155px;
        display: flex;
        flex-direction: column;
        .main-header {
            flex: 1;
            display: flex;
            .left-part {
                flex: 1;
            }
            .right-part {
                padding: 24px;
                text-align: right;
                .date-container {
                    font-weight: bold;
                    margin-bottom: 16px;
                }
                .bl-number {
                    font-weight: bold;
                    font-size: 24px;
                }
            }
        }
        > div {
            display: flex;
            align-items: center;
            border-top: 1px solid lightgray;
        }
        .client-block {
            justify-content: center;
            padding: 4px 0;
            font-weight: bold;
            font-size: 14px;
            .client-title {
                opacity: 0.5;
                margin-right: 16px;
                display: inline-block;
            }
        }
    }
    .page-header-space {
        height: 170px;
    }

    .page-footer {
        height: 50px;
    }
    .page-footer-space {
        height: 70px;
    }

    .page-footer {
        position: fixed;
        bottom: 0;
        width: 14.8cm;

        border-top: 1px solid black;
        background: white;
    }

    .page-header {
        position: fixed;
        top: 0mm;
        width: 14.8cm;

        border-bottom: 1px solid black;
        background: white;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    .page {
        font-size: 12px;
        page-break-after: always;
        .content {
            padding: 0 16px;
            .main-table {
                border: 1px solid black;
                td {
                    padding: 8px;
                    text-align: right;
                }
                th {
                    padding: 8px;
                    border-bottom: 1px solid black;
                    white-space: nowrap;
                }
                tbody {
                    tr {
                        border-bottom: 1px solid lightgray;
                        &:last-child {
                            border-bottom: none;
                        }
                        &:first-child {
                            border-top: 1px solid lightgray;
                        }
                    }
                }
                td.prod-name-cell {
                    text-align: left;
                }
                th.header-price {
                    text-align: right;
                }

                td {
                    white-space: nowrap;
                    &:first-child {
                        white-space: normal;
                    }
                }

                th.header-total-price {
                    text-align: right;
                }

                th.header-quantity {
                    text-align: right;
                }
            }
            .total-table {
                display: flex;
                justify-content: flex-end;
                > div {
                    display: flex;
                    border: 1px solid black;
                    &:first-child {
                        border-right: none;
                    }
                    border-top: none;
                    > .label {
                        margin-right: 16px;
                    }
                    > .value {
                        font-weight: bold;
                    }
                    > div {
                        padding: 8px;
                    }
                }
            }
        }
    }

    .spacer {
        height: 4cm;
    }

    @media print {
        box-shadow: none;
        counter-reset: page;

        thead {
            display: table-header-group;
        }
        tfoot {
            display: table-footer-group;
        }

        button {
            display: none;
        }
    }
`;

export const BL = () => {
    const history = useHistory();
    const id = new URLSearchParams(history.location.search).get('id');

    const [data, setData] = useState([]);

    const { data: ventesData } = useQuery(['vente', id], getSale);

    useEffect(() => {
        const ventes = ventesData?.result;
        console.log('ventes', ventes);

        const vente = ventes?.find((f) => String(f.id_bl) === String(id));

        console.log('vente', vente);
        setData(vente || []);
    }, [ventesData, id]);

    return (
        <StyledBL>
            <PrintButton />
            <div className="page-header">
                <div className="main-header">
                    <div className="left-part">
                        <Logos
                            company={data?.company}
                            style={{ paddingLeft: 20, height: 110, width: 300 }}
                        />
                    </div>
                    <div className="right-part">
                        <div className="date-container">{formatDate(data?.selling_date)}</div>
                        <div className="bl-number-container">
                            <div className="bl-number-title">BON DE LIVRAISON Nº</div>
                            <div className="bl-number">{data?.id_bl}</div>
                        </div>
                    </div>
                </div>
                <div className="client-block">
                    <span className="client-title">Client</span>
                    {data?.client?.name}
                </div>
            </div>

            <table>
                <thead>
                    <div className="page-header-space"></div>
                </thead>

                <tbody>
                    <div className="page">
                        <div className="content">
                            <table className="main-table">
                                <thead>
                                    <tr>
                                        <th className="header-name">Désignation</th>
                                        <th className="header-price">Prix U.</th>
                                        <th className="header-quantity">Qté</th>
                                        <th className="header-total-price">Prix Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.products?.map((r, i) => (
                                        <tr key={`row_${i}`}>
                                            <td className="prod-name-cell">{r.product_name}</td>
                                            <td>{getDisplayValue(r.selling_price, 'amount')}</td>
                                            <td>{r.quantity}</td>
                                            <td>
                                                {getDisplayValue(
                                                    r.selling_price * r.quantity,
                                                    'amount'
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="total-table">
                                <div className="total-qty">
                                    <div className="label">Total Qté</div>
                                    <div className="value">{data?.total_products}</div>
                                </div>
                                <div className="total-ttc">
                                    <div className="label">Total TTC</div>
                                    <div className="value">
                                        {getDisplayValue(data?.total_amount, 'amount')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </tbody>

                <tfoot>
                    <div className="page-footer-space"></div>
                </tfoot>

                <div className="page-footer">
                    <FootersInfo company={data?.company} />
                </div>
            </table>
            <div className="spacer" />
        </StyledBL>
    );
};
