import { authAxios } from '../auth';

export const getFactures = async ({ pageParam, queryKey }) => {
    const queryString = queryKey[1]?.length > 1 ? queryKey[1] : '';
    const res = await authAxios(`/factures/?page_size=50&page=${pageParam || 1}${queryString}`);
    return res?.data;
};

export const generateFacture = async (id_bls) => {
    const res = await authAxios.post(`/factures/`, { id_bls });
    return res?.data;
};

export const updateFacture = async (factureData) => {
    const res = await authAxios.put(`/factures/`, factureData);
    return res?.data;
};

export const getFacture = async ({ queryKey }) => {
    const res = await authAxios(
        `/factures/?id_facture=${queryKey[1]}&page_size=50&sort=id_facture`
    );
    return res?.data;
};
