import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TableLine } from './TableLine';
import { TableCell, HeaderCell } from './TableCell';
import { ReactComponent as SortAsc } from '../assets/sort-asc.svg';
import { ReactComponent as SortDesc } from '../assets/sort-desc.svg';
import { ReactComponent as SortEmpty } from '../assets/sort-empty.svg';
import { useInView } from 'react-intersection-observer';
import { FilterModal } from './FilterModal';
import { convertDownloadCSV, handleFilters } from '../utils';

const StyledTable = styled.div`
    box-shadow: 0px 1px 2px 1px hsl(218deg 14% 84%);
    border-radius: 5px;
    overflow: auto;
    max-width: 100%;
    position: relative;
    max-height: 300px;
`;

const TableContainer = styled.div`
    position: relative;
    .download-button {
        padding: 0px 2px;
        box-shadow: 0px 4px 11px hsl(204deg 19% 85%);
        position: absolute;
        right: -40px;
        background: transparent;
        border-radius: 3px;
        color: hsl(204deg 19% 75%);
        cursor: pointer;
        transition: 0.2s all;
        &:hover {
            background: white;
        }
    }
    .loading-text {
        position: absolute;
        bottom: -25px;
        right: 0;
        z-index: 4;
        opacity: 0.5;
        font-size: 12px;
        font-style: italic;
    }
    .delete-icons-container {
        position: absolute;
        z-index: 2;
        left: -35px;
        top: 38px;
        .delete-icon {
            height: 37px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            font-size: 18px;
            cursor: pointer;
            color: #b5bfc9;
            font-weight: 900;
            transition: color 0.15s;
            &:hover {
                color: #7a8c9f;
            }
        }
    }
`;

export const Table = ({
    headers,
    data,
    onLineSelect,
    selectedLines = [],
    canEdit,
    onCellEdit,
    onSortingChange,
    sorting,
    onDeleteLine,
    style,
    tableStyles,
    filters,
    onFiltersChange,
    loadMore,
    loading,
}) => {
    const [loadingIndicatorRef, loadingIndicatorInView] = useInView();
    const [filterKey, setFilterKey] = useState();
    const [filterCaller, setFilterCaller] = useState();

    const [newHeaderWidths, setNewHeaderWidths] = useState({});

    const localHandleFilters = (filter) => {
        return handleFilters(filter, onFiltersChange);
    };

    const getFilterValue = () => {
        if (filters) {
            const filter = filters.find((el) => el.key === filterKey);
            if (filter)
                return {
                    value: filter.value,
                    secondValue: filter.secondValue,
                };
            return {};
        }
    };

    useEffect(() => {
        loadMore && loadingIndicatorInView && loadMore();
    }, [loadingIndicatorInView]);

    return (
        <TableContainer style={style} className="table">
            <button className="download-button" onClick={() => convertDownloadCSV(headers, data)}>
                <span className="material-icons-outlined">file_download</span>
            </button>
            {loading && <div className="loading-text">Chargement en cours...</div>}
            {filters && (
                <FilterModal
                    show={!!filterKey}
                    filterKey={filterKey}
                    filterCaller={filterCaller}
                    label={headers.find((el) => el.accessor === filterKey)?.label}
                    type={headers.find((el) => el.accessor === filterKey)?.format}
                    onClose={() => {
                        setFilterKey();
                        setFilterCaller();
                    }}
                    onChange={localHandleFilters}
                    value={getFilterValue().value}
                    secondValue={getFilterValue().secondValue}
                />
            )}
            {onDeleteLine && (
                <div className="delete-icons-container">
                    {data.map((line, lineIndex) => (
                        <div
                            key={'delete_icon_line_' + lineIndex}
                            onClick={() => onDeleteLine(lineIndex)}
                            className="delete-icon material-icons-round"
                        >
                            close
                        </div>
                    ))}
                </div>
            )}
            <StyledTable style={tableStyles}>
                <TableLine>
                    {headers.map((header) => (
                        <HeaderCell
                            key={`header_${header.label}`}
                            identifier={`header_${header.label}`}
                            width={newHeaderWidths[header.accessor] || header.width}
                            onClick={(e) => {
                                if (!header.unfilterable) {
                                    setFilterCaller(e.target);
                                    setFilterKey(header.accessor);
                                }
                            }}
                            onResize={(newWidth) =>
                                setNewHeaderWidths((old) => {
                                    const newHeaderWidths = { ...old };
                                    newHeaderWidths[header.accessor] = newWidth;
                                    return newHeaderWidths;
                                })
                            }
                        >
                            <div className="header-label-container">{header.label}</div>
                            {!header.unsortable && (
                                <div
                                    className="header-icon-container"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        let newKey = header.accessor;
                                        let newType = '';
                                        if (sorting?.key !== newKey) newType = 'desc';
                                        else {
                                            if (sorting?.type === 'desc') newType = 'asc';
                                            if (sorting?.type === 'asc') {
                                                newType = '';
                                                newKey = '';
                                            }
                                        }
                                        onSortingChange &&
                                            onSortingChange({
                                                key: newKey,
                                                type: newType,
                                            });
                                    }}
                                >
                                    {sorting?.key === header.accessor ? (
                                        <>{sorting?.type === 'asc' ? <SortAsc /> : <SortDesc />}</>
                                    ) : (
                                        <SortEmpty />
                                    )}
                                </div>
                            )}
                        </HeaderCell>
                    ))}
                </TableLine>
                {data.map((entry, entryIndex) => (
                    <TableLine
                        key={`row_${entryIndex}`}
                        onClick={() => onLineSelect && onLineSelect(entryIndex, entry)}
                        selected={selectedLines?.includes(entryIndex)}
                    >
                        {headers.map((header, headerIndex) => {
                            return (
                                <TableCell
                                    key={`row_${entryIndex}_cell_${headerIndex}`}
                                    format={header.format}
                                    spaced={header.spaced}
                                    // width={header.width}
                                    width={newHeaderWidths[header.accessor] || header.width}
                                    style={header.style}
                                    canEdit={canEdit}
                                    editable={header.editable}
                                    onEdit={(val) => {
                                        header.onChange
                                            ? header.onChange(val, entryIndex, header.accessor)
                                            : onCellEdit(val, entryIndex, header.accessor);
                                    }}
                                    CustomCell={header.render}
                                    value={
                                        header.getter
                                            ? header.getter(entry)
                                            : entry[header.accessor]
                                    }
                                />
                            );
                        })}
                    </TableLine>
                ))}
                <div ref={loadingIndicatorRef} />
            </StyledTable>
        </TableContainer>
    );
};
