import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { addProduct } from '../../api/products';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { InfoModalContent } from '../InfoModalContent';
import { productsHeaders } from '../../config/products';
import { deleteEmptyValues } from '../../utils';

export const AddProductModal = ({ show, onClose }) => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const queryClient = useQueryClient();

    const [data, setData] = useState({});

    const closeModal = () => {
        setData({});
        onClose();
    };

    const createMutation = useMutation(addProduct, {
        onSuccess: (data) => {
            console.log('create successful: ', data);
            setSubmitLoading(false);
            queryClient.invalidateQueries('products');
            toast.success('Produit a été créé avec succès!');
            closeModal();
        },
        onError: (err) => {
            console.log('create failed with error: ', err.response);
            setSubmitLoading(false);
            toast.error('create failed with error: ' + JSON.stringify(err.response?.data));
            closeModal();
        },
    });

    const isSubmitDisabled = () => {
        if (!data?.name) return true;
        return false;
    };

    return (
        <Modal
            closeOnOutsideClick={false}
            show={show}
            onClose={closeModal}
            title="Nouveau produit"
            buttons={
                <>
                    <Button label="annuler" onClick={closeModal} />
                    <Button
                        variant="green-full"
                        onClick={() => {
                            setSubmitLoading(true);
                            createMutation.mutate(deleteEmptyValues({ ...data }));
                        }}
                        label={submitLoading ? 'En cours ...' : 'Ajouter'}
                        loading={submitLoading}
                        disabled={isSubmitDisabled() || submitLoading}
                    />
                </>
            }
        >
            <InfoModalContent
                write={true}
                data={data}
                setData={setData}
                lines={productsHeaders.filter((h) => h.writeable)}
            />
        </Modal>
    );
};
