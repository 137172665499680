import React, { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { PageContent } from '../../components/PageContent';
import { Table } from '../../components/Table';
import { getFiltersSortingQuery } from '../../utils';
import { purchasesHeadersDetails } from '../../config/purchases';
import { FiltersList } from '../../components/FiltersList';
import { getPurchasesDetails } from '../../api/purchases';

export const DetailsAchats = () => {
    const [filters, setFilters] = useState([]);
    const [sorting, setSorting] = useState({
        key: 'bon_commande__buying_date',
        type: 'desc',
    });
    const [purchasesData, setPurchasesData] = useState([]);

    // GET PURCHASES
    const { data, fetchNextPage, isLoading, isFetching } = useInfiniteQuery(
        ['purchasesdetails', getFiltersSortingQuery(filters, sorting)],
        getPurchasesDetails,
        {
            getNextPageParam: (lastPage) => {
                return lastPage.has_next ? parseInt(lastPage.current_page) + 1 : undefined;
            },
        }
    );

    useEffect(() => {
        setPurchasesData(data?.pages.map((p) => p.result).flat() || []);
    }, [data]);

    return (
        <PageContent style={{ paddingBottom: 100 }}>
            <div className="page-title-container">
                <div className="page-title">Gestion des achats avec details</div>
            </div>
            <FiltersList
                filters={filters}
                setFilters={setFilters}
                headers={purchasesHeadersDetails}
            />
            <Table
                tableStyles={{
                    maxHeight: `235px`,
                }}
                filters={filters}
                onFiltersChange={setFilters}
                style={{ marginTop: 24 }}
                headers={purchasesHeadersDetails}
                data={purchasesData}
                loadMore={() => fetchNextPage()}
                sorting={sorting}
                onSortingChange={setSorting}
                loading={isLoading || isFetching}
            />
        </PageContent>
    );
};
