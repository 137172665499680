import React, { useState } from 'react';
import styled from 'styled-components';
import { PageContent } from '../../components/PageContent';
import { DateInput } from '../../components/DateInput';
import { ClientsSelect } from '../../components/ClientsSelect';
import { useMutation, useQuery } from 'react-query';
import { getSituation } from '../../api/clients';
import { Table } from '../../components/Table';
import { situationPaymentsHeaders, situationPurchasesHeaders } from '../../config/clients';
import { getDisplayValue } from '../../utils';
import { Button } from '../../components/Button';
import { generateFacture } from '../../api/factures';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const transformSituationVentes = (ventes) => {
    const allVentes = [];
    ventes?.forEach((v) => {
        allVentes.push(
            ...v?.products?.map((p) => ({
                ...p,
                id_bl: v?.id_bl,
                selling_date: v?.selling_date,
                total_price: p?.quantity * p?.selling_price,
            }))
        );
    });

    return allVentes;
};

const InputsContainer = styled.div`
    input {
        background: white;
        border: 1px solid #d3d7de;
        box-shadow: none;
    }
    > div {
        margin-left: 32px;
    }
    .label {
        font-weight: 600;
        margin-bottom: 5px;
    }
`;

export const Situation = () => {
    const [client, setClient] = useState();
    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState(new Date().toISOString());

    const history = useHistory();

    const isEnabled = () => {
        if (!client?.id_client && client?.id_client !== 0) return false;
        if (!dateFrom || !dateTo) return false;
        return true;
    };

    // GET CLIENTS
    const { data, isLoading } = useQuery(
        ['situation', `id_client=${client?.id_client}&date_min=${dateFrom}&date_max=${dateTo}`],
        getSituation,
        {
            enabled: isEnabled(),
        }
    );

    const generateFactureMutation = useMutation(generateFacture, {
        onSuccess: (data) => {
            console.log('generate facture successful: ', data);
            history.push('/utilitaires/factures');
        },
        onError: (err) => {
            console.log('generate facture failed with error: ', err.response);
            toast.error(
                'generate facture failed with error: ' + JSON.stringify(err.response?.data)
            );
        },
    });

    return (
        <PageContent style={{ paddingBottom: 100 }}>
            <div className="page-title-container">
                <div className="page-title">Situation client</div>
                <InputsContainer className="title-buttons">
                    <div className="client" style={{ width: 200 }}>
                        <div className="label">Client</div>
                        <ClientsSelect
                            controlStyles={{
                                background: 'white',
                                boxShadow: 'inset 0 0 0 1px #d3d7de',
                            }}
                            write
                            value={client}
                            onChange={setClient}
                        />
                    </div>
                    <div className="start-date">
                        <div className="label">Date de debut</div>
                        <DateInput value={dateFrom} onChange={setDateFrom} />
                    </div>
                    <div className="end-date">
                        <div className="label">Date de fin</div>

                        <DateInput value={dateTo} onChange={setDateTo} />
                    </div>
                </InputsContainer>
            </div>
            {data && (
                <>
                    <div className="separator" />
                    <div className="table-title">
                        <div className="title-part">Ventes</div>
                        {!!data?.total_ventes && (
                            <div className="info-part">
                                <div className="info-label">Total ventes</div>
                                <div className="info-value">
                                    {getDisplayValue(data?.total_ventes, 'amount')}
                                </div>
                            </div>
                        )}
                    </div>
                    <Table
                        style={{ marginTop: 24 }}
                        headers={situationPurchasesHeaders}
                        data={transformSituationVentes(data?.ventes || [])}
                        loading={isLoading}
                    />
                </>
            )}

            {data && (
                <>
                    <div className="separator" />
                    <div className="table-title">
                        <div className="title-part">Paiements</div>
                        {!!data?.total_payments && (
                            <div className="info-part">
                                <div className="info-label">Total paiements</div>
                                <div className="info-value">
                                    {getDisplayValue(data?.total_payments, 'amount')}
                                </div>
                            </div>
                        )}
                    </div>
                    <Table
                        style={{ marginTop: 24 }}
                        headers={situationPaymentsHeaders}
                        data={data?.payments || []}
                        loading={isLoading}
                    />
                </>
            )}

            {data && (
                <div className="bottom-bar">
                    <div className="left-part">
                        <span className="footer-info-line">
                            <span className="info-line-key blue">Solde initial</span>
                            <span className="info-line-value">
                                {getDisplayValue(data?.initial_solde, 'amount')}
                            </span>
                        </span>
                        <span className="footer-info-line">
                            <span className="info-line-key blue">Solde actuel</span>
                            <span className="info-line-value">
                                {getDisplayValue(data?.actual_solde, 'amount')}
                            </span>
                        </span>
                    </div>
                    <div className="buttons-container">
                        <Button
                            icon="print"
                            label="générer un facture"
                            onClick={() => {
                                const id_bls = data?.ventes?.map((el) => el.id_bl);
                                generateFactureMutation.mutate(id_bls);
                            }}
                        />
                        <Button
                            icon="print"
                            label="imprimer situation"
                            onClick={() => {
                                const win = window.open(
                                    `/docs/situation?client=${client?.id_client}&from=${dateFrom}&to=${dateTo}&client_name=${client?.name}`,
                                    '_blank'
                                );
                                win.focus();
                            }}
                        />
                    </div>
                </div>
            )}
        </PageContent>
    );
};
