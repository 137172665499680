import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';

const StyledDateInput = styled.div`
    input {
        display: inline-block;
        height: 35px;
        padding: 0 16px;
        border-radius: 3px;
        background: #f0f2f4;
        box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        width: 150px;
    }
`;

export const DateInput = ({ value, onChange, showTime }) => {
    return (
        <StyledDateInput>
            <DatePicker
                timeFormat="HH:mm"
                showTimeSelect={showTime}
                dateFormat={showTime ? "dd/MM/yyyy HH'h'mm" : 'dd/MM/yyyy'}
                selected={Date.parse(value)}
                onChange={(d) => onChange(d?.toISOString())}
                startDate={Date.parse(value)}
                closeOnScroll
                popperPlacement="bottom-end"
            />
        </StyledDateInput>
    );
};
