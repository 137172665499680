import { authAxios } from '../auth';

// purchases

export const getPurchases = async ({ pageParam, queryKey }) => {
    const queryString = queryKey[1]?.length > 1 ? queryKey[1] : '';
    const res = await authAxios(`/achat/?page_size=50&page=${pageParam || 1}${queryString}`);
    return res?.data;
};

export const addPurchase = async (newPurchase) => {
    const res = await authAxios.post('/achat/', newPurchase);
    return res?.data;
};

export const updatePurchase = async (purchaseData) => {
    const res = await authAxios.put(`/achat/`, purchaseData);
    return res?.data;
};

export const deletePurchase = async (purchaseID) => {
    const res = await authAxios.delete(`/achat/${purchaseID}/`);
    return res;
};

//  echeancier

export const getChequesAchat = async ({ pageParam, queryKey }) => {
    const queryString = queryKey[1]?.length > 1 ? queryKey[1] : '';
    const res = await authAxios(
        `/achat_cheques/?page_size=50&page=${pageParam || 1}${queryString}`
    );
    return res?.data;
};

export const updateChequeAchat = async (chequeData) => {
    const res = await authAxios.put(`/achat_cheques/${chequeData.id}/`, chequeData);
    return res?.data;
};

export const getPurchasesDetails = async ({ pageParam, queryKey }) => {
    const queryString = queryKey[1]?.length > 1 ? queryKey[1] : '';
    const res = await authAxios(`/achat/details?page_size=50&page=${pageParam || 1}${queryString}`);
    return res?.data;
};
