import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { getSituation } from '../../api/clients';
import { FootersInfo } from '../../components/FootersInfo';
import { Logos } from '../../components/Logos';
import { PrintButton } from '../../components/PrintButton';
import { formatDate, getDisplayValue } from '../../utils';

const StyledSituation = styled.div`
    width: 210mm;
    margin: auto;
    box-shadow: 0px 0px 200px 0px rgba(0, 0, 0, 0.25);
    min-height: 100vh;
    @page {
        size: A4;
        margin: 2mm;
    }
    .page-header {
        height: 155px;
        display: flex;
        flex-direction: column;
        .main-header {
            flex: 1;
            display: flex;
            .left-part {
                flex: 1;
            }
            .right-part {
                padding: 24px;
                text-align: right;
                .date-container {
                    font-weight: bold;
                    margin-bottom: 16px;
                }
                .facture-number {
                    font-size: 16px;
                    margin-top: 4px;
                }
                .situation-title {
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }
        > div {
            display: flex;
            align-items: center;
            border-top: 1px solid lightgray;
        }
        .client-block {
            justify-content: center;
            padding: 4px 0;
            font-weight: bold;
            font-size: 14px;
            .client-title {
                opacity: 0.5;
                margin-right: 16px;
                display: inline-block;
            }
        }
    }
    .page-header-space {
        height: 180px;
    }

    .page-footer {
        height: 50px;
    }
    .page-footer-space {
        height: 80px;
    }

    .page-footer {
        position: fixed;
        bottom: 0;
        width: 210mm;

        border-top: 1px solid black;
        background: white;
    }

    .page-header {
        position: fixed;
        top: 0mm;
        width: 210mm;

        border-bottom: 1px solid black;
        background: white;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    .page {
        font-size: 14px;
        page-break-after: always;
        .content {
            padding: 0 16px;
            .table-title {
                margin-bottom: 16px;
                font-weight: bold;
                text-decoration: underline;
                &.ventes-title {
                    margin-top: 32px;
                }
            }
            .main-table {
                border: 1px solid black;
                td {
                    padding: 8px;
                    border-right: 1px solid black;
                }
                th {
                    padding: 8px;
                    border-bottom: 1px solid black;
                    white-space: nowrap;
                    border-right: 1px solid black;
                }
                tbody {
                    tr {
                        border-bottom: 1px solid lightgray;
                        &:last-child {
                            border-bottom: none;
                        }
                        &:first-child {
                            border-top: 1px solid lightgray;
                        }
                    }
                }
                td {
                    white-space: nowrap;
                    &:nth-child(3) {
                        white-space: normal;
                    }
                    &.right-align {
                        text-align: right;
                    }
                }
            }
            .total-table {
                margin-top: 16px;
                margin-left: auto;
                width: 300px;
                div {
                    white-space: nowrap;
                    text-align: right;
                }
                border-right: 1px solid black;
                border-bottom: 1px solid black;
                .total-line {
                    > div:first-child {
                        text-align: left;
                    }
                    display: flex;
                    > div {
                        border: 1px solid black;
                        padding: 6px 16px;
                        border-right: 0;
                        border-bottom: 0;
                        flex: 1;
                    }
                    &.underline > div {
                        font-weight: bold;
                    }
                }

                .total-line {
                }
            }
        }
    }

    .spacer {
        height: 4cm;
    }

    @media print {
        box-shadow: none;

        thead {
            display: table-header-group;
        }
        tfoot {
            display: table-footer-group;
        }

        button {
            display: none;
        }
    }
`;

const getVentes = (ventes) => {
    const allVentes = [];
    ventes?.forEach((v) => {
        allVentes.push(
            ...v?.products?.map((p) => ({ ...p, id_bl: v?.id_bl, date: v?.selling_date }))
        );
    });
    return allVentes;
};

export const Situation = () => {
    const history = useHistory();
    const dateFrom = new URLSearchParams(history.location.search).get('from');
    const client = new URLSearchParams(history.location.search).get('client');
    const dateTo = new URLSearchParams(history.location.search).get('to');
    const clientName = new URLSearchParams(history.location.search).get('client_name');

    const { data } = useQuery(
        ['situation', `id_client=${client}&date_min=${dateFrom}&date_max=${dateTo}`],
        getSituation
    );

    return (
        <StyledSituation>
            <PrintButton />
            <div className="page-header">
                <div className="main-header">
                    <div className="left-part">
                        <Logos
                            company={data?.company}
                            style={{ paddingLeft: 20, height: 110, width: 300 }}
                        />
                    </div>
                    <div className="right-part">
                        <div className="date-container">{formatDate(new Date().toISOString())}</div>
                        <div className="situation-title">SITUATION CLIENT</div>
                        <div className="facture-number">
                            {formatDate(dateFrom).slice(0, 10)} {'->'}{' '}
                            {formatDate(dateTo).slice(0, 10)}
                        </div>
                    </div>
                </div>
                <div className="client-block">
                    <span className="client-title">Client</span>
                    {clientName}
                </div>
            </div>

            <div className="page-footer">
                <FootersInfo company={data?.company} />
            </div>

            <table>
                <thead>
                    <div className="page-header-space"></div>
                </thead>

                <tbody>
                    <div className="page">
                        <div className="content">
                            <div className="table-title">Paiements</div>
                            <table className="main-table">
                                <thead>
                                    <tr>
                                        <th>Date de paiement</th>
                                        <th>Méthode de paiement</th>
                                        <th>Référence chèque/virement</th>
                                        <th>Montant payé</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.payments?.map((r, i) => (
                                        <tr key={`row_${i}`}>
                                            <td>{formatDate(r.payment_date)}</td>
                                            <td>{r.method}</td>
                                            <td>{r.cheque?.number || r.virement?.number}</td>
                                            <td className="right-align">
                                                {getDisplayValue(r.total, 'amount')}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="table-title ventes-title">Ventes</div>
                            <table className="main-table">
                                <thead>
                                    <tr>
                                        <th>Numero BL</th>
                                        <th>Date</th>
                                        <th>Désignation</th>
                                        <th>Qté</th>
                                        <th>Prix U. TTC</th>
                                        <th>Total TTC</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getVentes(data?.ventes)?.map((r, i) => (
                                        <tr key={`row_${i}`}>
                                            <td>{r.id_bl}</td>
                                            <td>{formatDate(r.date).slice(0, 10)}</td>
                                            <td>{r.product_name}</td>
                                            <td className="right-align">{r.quantity}</td>
                                            <td className="right-align">
                                                {getDisplayValue(r.selling_price, 'amount')}
                                            </td>
                                            <td className="right-align">
                                                {getDisplayValue(
                                                    r.quantity * r.selling_price,
                                                    'amount'
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="total-table">
                                <div className="total-line">
                                    <div>Solde initial</div>
                                    <div>{getDisplayValue(data?.initial_solde, 'amount')}</div>
                                </div>
                                <div className="total-line">
                                    <div>Total paiements</div>
                                    <div>{getDisplayValue(data?.total_payments, 'amount')}</div>
                                </div>
                                <div className="total-line">
                                    <div>Total achats</div>
                                    <div>{getDisplayValue(data?.total_ventes, 'amount')}</div>
                                </div>
                                <div className="total-line underline">
                                    <div>Solde actuel</div>
                                    <div>{getDisplayValue(data?.actual_solde, 'amount')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </tbody>

                <tfoot>
                    <div className="page-footer-space"></div>
                </tfoot>
            </table>
            <div className="spacer" />
        </StyledSituation>
    );
};
