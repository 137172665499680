import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { addDepense } from '../../api/caisse';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { InfoModalContent } from '../InfoModalContent';
import { depensesHeaders } from '../../config/caisse';
import { deleteEmptyValues } from '../../utils';

export const AddDepenseModal = ({ show, onClose }) => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const queryClient = useQueryClient();

    const [data, setData] = useState({
        depense_date: new Date().toISOString(),
    });

    const closeModal = () => {
        setData({
            depense_date: new Date().toISOString(),
        });
        onClose();
    };

    const createMutation = useMutation(addDepense, {
        onSuccess: (data) => {
            console.log('create successful: ', data);
            setSubmitLoading(false);
            queryClient.invalidateQueries('depenses');
            toast.success('Dépense a été créé avec succès!');
            closeModal();
        },
        onError: (err) => {
            console.log('create failed with error: ', err.response);
            setSubmitLoading(false);
            toast.error('create failed with error: ' + JSON.stringify(err.response?.data));
            closeModal();
        },
    });

    return (
        <Modal
            closeOnOutsideClick={false}
            show={show}
            onClose={closeModal}
            title="Ajouter une dépense"
            buttons={
                <>
                    <Button label="annuler" onClick={closeModal} />
                    <Button
                        variant="green-full"
                        onClick={() => {
                            setSubmitLoading(true);
                            createMutation.mutate(deleteEmptyValues({ ...data }));
                        }}
                        label={submitLoading ? 'En cours ...' : 'Ajouter'}
                        loading={submitLoading}
                        disabled={submitLoading}
                    />
                </>
            }
        >
            <InfoModalContent write={true} data={data} setData={setData} lines={depensesHeaders} />
        </Modal>
    );
};
