import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { updateDepense, deleteDepense } from '../../api/caisse';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { InfoModalContent } from '../InfoModalContent';
import { depensesHeaders } from '../../config/caisse';
import { deleteEmptyValues } from '../../utils';

export const UpdateDepenseModal = ({ show, onClose, data: depenseData }) => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const queryClient = useQueryClient();

    const [updateData, setUpdateData] = useState();

    useEffect(() => {
        if (depenseData) setUpdateData({ ...depenseData });
        else setUpdateData(null);
    }, [depenseData]);

    const closeAndReset = () => {
        setSubmitLoading(false);
        setIsUpdating(false);
        onClose();
    };

    // UPDATE DEPENSE
    const updateMutation = useMutation(updateDepense, {
        onSuccess: (data) => {
            console.log('update successful: ', data);
            queryClient.invalidateQueries('depenses');
            toast.success('Dépense a été modifié avec succès!');
            closeAndReset();
        },
        onError: (err) => {
            console.log('update failed with error: ', err.response);
            toast.error('update failed with error: ' + JSON.stringify(err.response?.data));
            closeAndReset();
        },
    });

    // DELETE DEPENSE
    const deleteMutation = useMutation(deleteDepense, {
        onSuccess: (res) => {
            console.log('delete mutation successful: ', res);
            queryClient.invalidateQueries('depenses');
            toast.success('Dépense a été supprimé avec succès!');
            closeAndReset();
        },
        onError: (err) => {
            console.log('delete failed with error: ', err.response);
            toast.error('delete failed with error: ' + JSON.stringify(err.response?.data));
            closeAndReset();
        },
    });

    const updateButtons = (
        <>
            <Button
                label="annuler"
                onClick={() => {
                    setIsUpdating(false);
                    setUpdateData(depenseData);
                }}
            />
            <Button
                variant="green-full"
                onClick={() => {
                    setSubmitLoading(true);
                    updateMutation.mutate(deleteEmptyValues({ ...updateData }));
                }}
                label={submitLoading ? 'En cours ...' : 'confirmer'}
                loading={submitLoading}
                disabled={submitLoading}
            />
        </>
    );

    const viewButtons = (
        <>
            <Button
                variant="red-full"
                onClick={() => {
                    setSubmitLoading(true);
                    deleteMutation.mutate(depenseData.id);
                }}
                label={submitLoading ? 'En cours ...' : 'supprimer'}
                loading={submitLoading}
                disabled={submitLoading}
            />
            <Button variant="green-full" onClick={() => setIsUpdating(true)} label="modifier" />
        </>
    );

    return (
        <Modal
            closeOnOutsideClick={!isUpdating}
            show={show}
            onClose={closeAndReset}
            title={isUpdating ? 'Modifier une dépense' : ''}
            buttons={isUpdating ? updateButtons : viewButtons}
        >
            <InfoModalContent
                write={isUpdating}
                data={isUpdating ? updateData : depenseData}
                setData={setUpdateData}
                lines={depensesHeaders}
            />
        </Modal>
    );
};
