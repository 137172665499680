import React, { useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { PageContent } from '../../components/PageContent';
import { Button } from '../../components/Button';
import { Table } from '../../components/Table';
import { categoriesHeaders, clientsHeadersCategories } from '../../config/clients';
import { getCategories, updateCategory } from '../../api/clients';
import { toast } from 'react-toastify';
import { CategoryDetailsModal } from '../../components/Clients/CategoryDetailsModal';

export const CategoriesClients = () => {
    const [categoryData, setCategoryData] = useState({});
    const [selectedLines, setSelectedLines] = useState([]);
    const [clientsWritable, setClientsWriteable] = useState(false);
    const [tempCategoryData, setTempCategoryData] = useState({});
    const [categoriesData, setCategoriesData] = useState([]);
    const [saveClientsLoading, setSaveClientsLoading] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const queryClient = useQueryClient();

    // GET CATEGORIES
    const { data, fetchNextPage, isLoading, isFetching } = useInfiniteQuery(
        ['categories'],
        getCategories,
        {
            getNextPageParam: (lastPage) => {
                return lastPage.has_next ? parseInt(lastPage.current_page) + 1 : undefined;
            },
        }
    );

    useEffect(() => {
        setCategoriesData(data?.pages.flat() || []);
    }, [data]);

    useEffect(() => {
        const data = categoriesData[selectedLines];
        if (data) setCategoryData(data);
    }, [categoriesData, selectedLines]);

    // UPDATE CATEGORY
    const updateMutation = useMutation(updateCategory, {
        onSuccess: (data) => {
            console.log('update successful: ', data);
            queryClient.invalidateQueries('categories');
            toast.success('Vente a été modifiée avec succès!');
            setCategoryData({});
            setSelectedLines([]);
            setClientsWriteable(false);
            setSaveClientsLoading(false);
        },
        onError: (err) => {
            console.log('update failed with error: ', err.response);
            toast.error('update failed with error: ' + JSON.stringify(err.response?.data));
            setClientsWriteable(false);
            setSaveClientsLoading(false);
        },
    });

    const handleClientDelete = (i) => {
        setTempCategoryData((old) => {
            const clients = [...old.clients];
            clients.splice(i, 1);
            return { ...old, clients };
        });
    };

    const handleNewClient = () => {
        setTempCategoryData((old) => ({ ...old, clients: [...old.clients, {}] }));
    };

    const handleClientChange = (val, i, key) => {
        setTempCategoryData((old) => {
            const clients = [...old.clients];
            const clientsToUpdate = { ...clients[i] };
            clientsToUpdate[key] = val;
            clients[i] = clientsToUpdate;
            return { ...old, clients };
        });
    };

    const handleSaveClients = () => {
        setSaveClientsLoading(true);
        const dataToSet = tempCategoryData;
        setCategoryData(dataToSet);
        dataToSet.clients = dataToSet.clients.map((client) => client.id_client);
        updateMutation.mutate(dataToSet);
    };

    const getClientsHeaders = () => {
        const newProductsHeaders = [...clientsHeadersCategories];
        newProductsHeaders[0].onChange = (value, lineIndex) => {
            setTempCategoryData((old) => {
                const clients = [...old?.clients];
                clients[lineIndex] = value;
                return { ...old, clients };
            });
        };
        return newProductsHeaders;
    };

    return (
        <PageContent style={{ paddingBottom: 300 }}>
            <CategoryDetailsModal
                show={showDetailsModal}
                onClose={() => {
                    setCategoryData({});
                    setSelectedLines([]);
                    setShowDetailsModal(false);
                }}
                data={categoryData}
            />
            <div className="page-title-container">
                <div className="page-title">Categories de clients</div>
            </div>
            <Table
                tableStyles={{
                    maxHeight: `235px`,
                }}
                style={{ marginTop: 24 }}
                headers={categoriesHeaders}
                data={categoriesData}
                onLineSelect={(id, data) => {
                    setCategoryData(data);
                    setSelectedLines([id]);
                }}
                selectedLines={selectedLines}
                loadMore={() => fetchNextPage()}
                loading={isLoading || isFetching}
            />
            {categoryData?.name && (
                <>
                    <div className="separator" />
                    <div className="page-title-container">
                        <div className="page-title">{categoryData.name}</div>
                        <div className="title-buttons">
                            {clientsWritable ? (
                                <>
                                    <Button
                                        label="annuler"
                                        onClick={() => setClientsWriteable(false)}
                                    />

                                    <Button
                                        variant="green-full"
                                        label="Enregistrer"
                                        disabled={saveClientsLoading}
                                        loading={saveClientsLoading}
                                        onClick={handleSaveClients}
                                    />
                                </>
                            ) : (
                                <>
                                    <Button
                                        variant="green"
                                        label="modifier clients"
                                        onClick={() => {
                                            setTempCategoryData({ ...categoryData });
                                            setClientsWriteable(true);
                                        }}
                                    />
                                    <Button
                                        onClick={() => setShowDetailsModal(true)}
                                        variant="green"
                                        label="modifier informations"
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <Table
                        key={categoryData.name + clientsWritable ? '_temp' : ''}
                        style={{ marginTop: 32 }}
                        tableStyles={{ maxHeight: 'unset' }}
                        headers={getClientsHeaders()}
                        data={
                            clientsWritable
                                ? [...tempCategoryData.clients]
                                : [...categoryData.clients]
                        }
                        canEdit={clientsWritable}
                        onDeleteLine={clientsWritable && handleClientDelete}
                        onCellEdit={handleClientChange}
                    />
                    {clientsWritable && (
                        <Button
                            style={{ marginTop: 16 }}
                            onClick={handleNewClient}
                            label="Ajouter un client"
                        />
                    )}
                    <div className="bottom-bar">
                        <div className="left-part">
                            <Button variant="red" label="supprimer cette categorie" />
                        </div>
                        <div className="buttons-container"></div>
                    </div>
                </>
            )}
        </PageContent>
    );
};
