import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getProducts } from '../api/products';
import Select from 'react-select';

const valueAsOption = (val) => {
    return {
        ...val,
        value: val?.id_product,
        label: val?.product_name,
    };
};

export const ProductSelect = ({ editable, value, onChange }) => {
    const [localValue, setLocalValue] = useState(value || '');
    const [inputValue, setInputValue] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);
    const { data, isLoading } = useQuery(['products', `&name=${inputValue}`], getProducts, {
        enabled: menuOpen,
    });

    useEffect(() => {
        value && setLocalValue(value);
    }, [value]);

    const handleChange = (val) => {
        onChange({
            ...val,
            product_name: val?.name,
            product: val?.id_product,
            quantity: 1,
            total_amount: val?.selling_price,
        });
    };

    const getOptions = () =>
        data?.result.map((p) => ({ ...p, value: p.id_product, label: p.name })) || [];

    return editable ? (
        <Select
            menuPortalTarget={document.body}
            styles={{
                control: (base) => ({
                    ...base,
                    height: 28,
                    minHeight: 28,
                    background: 'hsl(218deg 14% 92%)',
                    boxShadow: 'inset 0px 1px 2px rgba(0, 0, 0, 0.15)',
                }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                menu: (base) => ({ ...base, zIndex: 9999 }),
            }}
            onMenuClose={() => setMenuOpen(false)}
            onMenuOpen={() => setMenuOpen(true)}
            classNamePrefix="__custom_select"
            isSearchable
            onInputChange={setInputValue}
            inputValue={inputValue}
            value={valueAsOption(localValue)}
            onChange={handleChange}
            options={getOptions()}
            isLoading={isLoading}
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        />
    ) : (
        <>{localValue?.product_name || localValue?.name}</>
    );
};
