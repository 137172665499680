import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as Loader } from '../assets/loading.svg';

const greenVariant = css`
    color: hsl(119deg 67% 40%);
    &:hover:not(:disabled) {
        background: hsl(119deg 67% 97%);
    }
`;

const greenFullVariant = css`
    color: white;
    background: hsl(119deg 67% 40%);
    &:hover:not(:disabled) {
        background: hsl(119deg 67% 35%);
    }
`;

const redVariant = css`
    color: hsl(0deg 73% 55%);
    &:hover:not(:disabled) {
        background: hsl(0deg 73% 98%);
    }
`;

const redFullVariant = css`
    color: white;
    background: hsl(0deg 73% 55%);
    &:hover:not(:disabled) {
        background: hsl(0deg 73% 45%);
    }
`;

const blueVariant = css`
    color: hsl(202deg 100% 44%);
    &:hover:not(:disabled) {
        background: hsl(202deg 100% 97%);
    }
`;

const StyledButton = styled.button`
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 16px;
    letter-spacing: 0.05em;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px;
    white-space: nowrap;
    box-shadow: 0px 2px 2px 0px hsl(218deg 14% 85%), 0px 0px 2px 0px hsl(218deg 14% 85%);
    border-radius: 3px;
    cursor: pointer;
    transition: 0.15s all;
    will-change: transform;
    &:hover:not(:disabled) {
        background: #f6f7f8;
    }
    &:active {
        transform: scale(0.96);
    }
    &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
    ${({ variant }) => variant === 'green' && greenVariant}
    ${({ variant }) => variant === 'red' && redVariant}
    ${({ variant }) => variant === 'blue' && blueVariant}
    ${({ variant }) => variant === 'green-full' && greenFullVariant}
    ${({ variant }) => variant === 'red-full' && redFullVariant}
`;

export const Button = ({ loading, disabled, style, label, variant, onClick, icon }) => {
    return (
        <StyledButton disabled={disabled} onClick={onClick} variant={variant} style={style}>
            {icon && (
                <span
                    className="material-icons-round"
                    style={{ fontSize: 18, marginLeft: -4, marginRight: 8 }}
                >
                    {icon}
                </span>
            )}
            {label}
            {loading && <Loader className="_loading-icon" />}
        </StyledButton>
    );
};
