import React, { useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { getClients, addCategory } from '../../api/clients';
import { PageContent } from '../../components/PageContent';
import { getFiltersSortingQuery } from '../../utils';
import { Table } from '../../components/Table';
import { FiltersList } from '../../components/FiltersList';
import { clientsHeaders } from '../../config/clients';
import { Button } from '../../components/Button';
import { toast } from 'react-toastify';
import { NewCategoryModal } from '../../components/Clients/NewCategoryModal';

export const SaisieCategorie = () => {
    const [filters, setFilters] = useState([]);
    const [sorting, setSorting] = useState({});
    const [clientsData, setClientsData] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [newCategoryLoading, setNewCategoryLoading] = useState(false);

    const [categoryDetails, setCategoryDetails] = useState();

    const queryClient = useQueryClient();

    // GET CLIENTS
    const {
        data: rawClientsData,
        fetchNextPage,
        isLoading,
        isFetching,
    } = useInfiniteQuery(['clients', getFiltersSortingQuery(filters, sorting)], getClients, {
        getNextPageParam: (lastPage) => {
            return lastPage.has_next ? parseInt(lastPage.current_page) + 1 : undefined;
        },
    });

    useEffect(() => {
        setClientsData(rawClientsData?.pages.map((p) => p.result).flat() || []);
    }, [rawClientsData]);

    // CREATE CATEGORY
    const createMutation = useMutation(addCategory, {
        onSuccess: (data) => {
            console.log('creation successful: ', data);
            queryClient.invalidateQueries('categories');
            toast.success('Categorie a été créée avec succès!');
            reset();
        },
        onError: (err) => {
            console.log('update failed with error: ', err.response);
            toast.error('update failed with error: ' + JSON.stringify(err.response?.data));
            reset();
        },
    });

    const reset = () => {
        setShowDetailsModal(false);
        setCategoryDetails({
            selling_date: new Date().toISOString(),
        });
        setSelectedClients([]);
        setNewCategoryLoading(false);
    };

    const handleClietnDelete = (i) => {
        setSelectedClients((old) => {
            const newClients = [...old];
            newClients.splice(i, 1);
            return newClients;
        });
    };

    const handleClientChange = (val, i, key) => {
        setSelectedClients((old) => {
            const newClients = [...old];
            const clientToUpdate = { ...newClients[i] };
            clientToUpdate[key] = val;
            newClients[i] = clientToUpdate;
            return newClients;
        });
    };

    return (
        <PageContent style={{ paddingBottom: 100 }}>
            <NewCategoryModal
                loading={newCategoryLoading}
                setLoading={setNewCategoryLoading}
                show={showDetailsModal}
                onClose={() => setShowDetailsModal(false)}
                data={categoryDetails}
                onChange={(data) => {
                    createMutation.mutate({
                        ...data,
                        clients: selectedClients.map((p) => p.id_client),
                    });
                }}
            />
            <div className="page-title-container">
                <div className="page-title">Nouvelle categorie</div>
            </div>

            <FiltersList filters={filters} setFilters={setFilters} headers={clientsHeaders} />
            <Table
                tableStyles={{
                    maxHeight: `235px`,
                }}
                filters={filters}
                onFiltersChange={setFilters}
                style={{ marginTop: 24 }}
                headers={clientsHeaders}
                data={clientsData}
                onLineSelect={(id, data) => {
                    setSelectedClients((old) => {
                        const newData = [...old];
                        const newClientIndex = old.findIndex(
                            (el) => el.id_client === data.id_client
                        );
                        if (newClientIndex !== -1) {
                            newData.splice(newClientIndex, 1);
                        } else {
                            newData.push(data);
                        }
                        return newData;
                    });
                }}
                selectedLines={selectedClients.map((el) => {
                    const index = clientsData.findIndex((p) => p.id_client === el.id_client);
                    return index === -1 || index;
                })}
                loadMore={() => fetchNextPage()}
                sorting={sorting}
                onSortingChange={setSorting}
                loading={isLoading || isFetching}
            />
            {selectedClients?.length > 0 && (
                <>
                    <div className="separator" />
                    <div className="page-title-container">
                        <div className="page-title">Clients séléctionnés</div>
                    </div>
                    <Table
                        style={{ marginTop: 32 }}
                        tableStyles={{ maxHeight: 'unset' }}
                        headers={clientsHeaders}
                        data={selectedClients}
                        canEdit
                        onDeleteLine={handleClietnDelete}
                        onCellEdit={handleClientChange}
                    />
                    <div className="bottom-bar">
                        <div className="left-part"></div>
                        <div className="right-part">
                            <Button
                                icon="skip_next"
                                onClick={() => setShowDetailsModal(true)}
                                variant="green-full"
                                label="étape suivante"
                            />
                        </div>
                    </div>
                </>
            )}
        </PageContent>
    );
};
