import { ClientsSelectCategory } from '../components/ClientsSelectCategory';

export const clientsHeaders = [
    {
        label: 'Nom du client',
        accessor: 'name',
        width: 350,
        writeable: true,
    },
    {
        label: 'Téléphone',
        accessor: 'phone',
        width: 150,
        writeable: true,
    },
    {
        label: 'Ville',
        accessor: 'city',
        width: 100,
        writeable: true,
        inputWidth: 150,
    },
    {
        label: 'Adresse',
        accessor: 'address',
        width: 200,
        writeable: true,
    },
    {
        label: 'ICE',
        accessor: 'ice',
        width: 150,
        writeable: true,
        unfilterable: true,
    },
    {
        label: 'Solde client',
        accessor: 'solde',
        width: 150,
        writeable: true,
        unfilterable: true,
        format: 'amount',
    },
    {
        label: 'Date dernier paiement',
        accessor: 'last_payment',
        width: 200,
        format: 'date',
        writeable: true,
        unfilterable: true,
    },
    {
        label: 'Commentaires',
        accessor: 'comments',
        width: 250,
        writeable: true,
    },
];

export const situationPurchasesHeaders = [
    {
        label: 'Numero BL',
        accessor: 'id_bl',
        width: 130,
        inputWidth: 130,
        writeable: false,
        spaced: true,
        style: {
            textAlign: 'right',
        },
        unfilterable: true,
        unsortable: true,
    },
    {
        label: 'Date de vente',
        accessor: 'selling_date',
        width: 160,
        writeable: true,
        format: 'date',
        unfilterable: true,
        unsortable: true,
    },
    {
        label: 'Désignation',
        accessor: 'product_name',
        width: 230,
        unfilterable: true,
        unsortable: true,
    },
    {
        label: 'Prix U. TTC',
        accessor: 'selling_price',
        width: 130,
        format: 'amount',
        unfilterable: true,
        unsortable: true,
    },
    {
        label: 'Qté',
        accessor: 'quantity',
        format: 'number',
        width: 80,
        unfilterable: true,
        writeable: true,
        unsortable: true,
    },
    {
        label: 'Total TTC',
        accessor: 'total_price',
        width: 130,
        format: 'amount',
        unfilterable: true,
        unsortable: true,
    },
];

export const situationPaymentsHeaders = [
    {
        label: 'Date de paiement',
        accessor: 'payment_date',
        width: 170,
        writeable: true,
        format: 'date',
        unfilterable: true,
        unsortable: true,
    },
    {
        label: 'Montant',
        accessor: 'total',
        width: 140,
        inputWidth: 120,
        writeable: true,
        format: 'amount',
        unfilterable: true,
        unsortable: true,
    },
    {
        label: 'Méthode',
        accessor: 'method',
        width: 150,
        writeable: true,
        unfilterable: true,
        unsortable: true,
    },
    {
        label: 'Ref. chèque',
        accessor: 'cheque__number',
        getter: (data) => data?.cheque?.number,
        width: 150,
        inputWidth: 150,
        writeable: true,
        spaced: true,
        unfilterable: true,
        unsortable: true,
    },
    {
        label: 'Ref. virement',
        accessor: 'virement__number',
        getter: (data) => data?.virement?.number,
        width: 150,
        inputWidth: 150,
        writeable: true,
        spaced: true,
        unfilterable: true,
        unsortable: true,
    },
    {
        label: 'Commentaires',
        accessor: 'comments',
        width: 250,
        unsortable: true,
        writeable: true,
        unfilterable: true,
    },
];

export const categoriesHeaders = [
    {
        label: 'Categorie',
        accessor: 'name',
        width: 400,
        writeable: true,
        unfilterable: true,
        unsortable: true,
    },
    {
        label: 'Clients',
        accessor: 'clients',
        getter: (data) => data?.clients?.length,
        format: 'number',
        width: 120,
        writeable: false,
        unfilterable: true,
        unsortable: true,
    },
];

export const clientsHeadersCategories = [
    {
        label: 'Nom du client',
        accessor: 'name',
        getter: (data) => data,
        width: 450,
        writeable: true,
        unsortable: true,
        unfilterable: true,
        editable: true,
        render: ClientsSelectCategory,
    },
    {
        label: 'Téléphone',
        accessor: 'phone',
        width: 150,
        writeable: true,
    },
    {
        label: 'Ville',
        accessor: 'city',
        width: 100,
        writeable: true,
        inputWidth: 150,
    },
    {
        label: 'Adresse',
        accessor: 'address',
        width: 200,
        writeable: true,
    },
    {
        label: 'ICE',
        accessor: 'ice',
        width: 150,
        writeable: true,
        unfilterable: true,
    },
    {
        label: 'Solde client',
        accessor: 'solde',
        width: 150,
        writeable: true,
        unfilterable: true,
        format: 'amount',
    },
    {
        label: 'Date dernier paiement',
        accessor: 'last_payment',
        width: 200,
        format: 'date',
        writeable: true,
        unfilterable: true,
    },
    {
        label: 'Commentaires',
        accessor: 'comments',
        width: 250,
        writeable: true,
    },
];
