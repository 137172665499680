import React from 'react';
import styled, { css } from 'styled-components';
import { getDisplayValue } from '../utils';
import { Input } from './Input';
import Draggable from 'react-draggable';

const numberFormat = css`
    text-align: right;
    font-family: monospace;
`;

const editStyles = css`
    padding: 4px;
    > input {
        background: hsl(218deg 14% 92%);
        box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.15);
    }
`;

const StyledTableCell = styled.div`
    padding: 10px 16px;
    border-right: 1px solid #d3d7de;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: ${({ width }) => `${width}px`};

    ${({ format }) =>
        (format === 'number' || format === 'amount' || format === 'percent') && numberFormat}
    ${({ editable }) => editable && editStyles}
  ${({ spaced, format }) => (format === 'date' || spaced) && `letter-spacing: 0.05em;`}
`;

const StyledHeaderCell = styled.div`
    padding: 0px 16px;
    border-right: 1px solid #d3d7de;
    height: 38px;
    width: ${({ width }) => `${width}px`};
    font-weight: 600;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    .resize-handle {
        background: black;
        width: 3px;
        opacity: 0.05;
        height: 100%;
        position: absolute;
        right: 0;
        cursor: col-resize;
        z-index: 99999;
        &:hover,
        &:active {
            opacity: 0.2;
        }
    }

    .header-label-container {
        pointer-events: none;
        padding: 2px 0;
    }
    .header-icon-container {
        display: flex;
        align-items: center;
        margin-left: 6px;
        cursor: pointer;
        border-radius: 3px;
        padding: 3px;
        transition: background 0.15s;
        &:hover {
            background: hsl(204deg 19% 96%);
        }
    }
`;

export const TableCell = ({
    value,
    format,
    width,
    canEdit,
    editable,
    onEdit,
    style,
    spaced,
    CustomCell,
}) => {
    if (CustomCell)
        return (
            <StyledTableCell
                style={style}
                editable={canEdit && editable}
                format={format}
                width={width}
                spaced={spaced}
            >
                <CustomCell
                    style={style}
                    editable={canEdit && editable}
                    value={value}
                    width={width}
                    onChange={onEdit}
                />
            </StyledTableCell>
        );
    return (
        <StyledTableCell
            style={style}
            editable={canEdit && editable}
            format={format}
            width={width}
            spaced={spaced}
            className="__table_cell"
        >
            {canEdit && editable ? (
                <Input style={{ height: 28 }} value={value} onChange={onEdit} format={format} />
            ) : (
                getDisplayValue(value, format)
            )}
        </StyledTableCell>
    );
};

export const HeaderCell = ({ identifier, onResize, width, children, ...rest }) => {
    return (
        <StyledHeaderCell {...rest} width={width}>
            {children}
            <Draggable
                key={`${identifier}_${width}`}
                axis="x"
                onStop={(e, data) => {
                    onResize(width + data.x);
                    return false;
                }}
            >
                <div className="resize-handle" />
            </Draggable>
        </StyledHeaderCell>
    );
};
