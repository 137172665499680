import React, { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { PageContent } from '../../components/PageContent';
import { Table } from '../../components/Table';
import { getFiltersSortingQuery } from '../../utils';
import { salesHeadersDetails } from '../../config/sales';
import { FiltersList } from '../../components/FiltersList';
import { getSalesDetails } from '../../api/sales';

export const DetailsVentes = () => {
    const [filters, setFilters] = useState([]);
    const [sorting, setSorting] = useState({
        key: 'bon_livraison__selling_date',
        type: 'desc',
    });
    const [ventesData, setVentesData] = useState([]);

    // GET SALES
    const { data, fetchNextPage, isLoading, isFetching } = useInfiniteQuery(
        ['salesdetails', getFiltersSortingQuery(filters, sorting)],
        getSalesDetails,
        {
            getNextPageParam: (lastPage) => {
                return lastPage.has_next ? parseInt(lastPage.current_page) + 1 : undefined;
            },
        }
    );

    useEffect(() => {
        setVentesData(data?.pages.map((p) => p.result).flat() || []);
    }, [data]);

    return (
        <PageContent style={{ paddingBottom: 100 }}>
            <div className="page-title-container">
                <div className="page-title">Gestion des ventes avec details</div>
            </div>
            <FiltersList filters={filters} setFilters={setFilters} headers={salesHeadersDetails} />
            <Table
                tableStyles={{
                    maxHeight: `235px`,
                }}
                filters={filters}
                onFiltersChange={setFilters}
                style={{ marginTop: 24 }}
                headers={salesHeadersDetails}
                data={ventesData}
                loadMore={() => fetchNextPage()}
                sorting={sorting}
                onSortingChange={setSorting}
                loading={isLoading || isFetching}
            />
        </PageContent>
    );
};
