import React, { useState } from 'react';
import { Button } from '../../components/Button';
import { PageContent } from '../../components/PageContent';
import { Table } from '../../components/Table';
import { useInfiniteQuery } from 'react-query';
import { getFiltersSortingQuery } from '../../utils';
import { paymentsHeaders } from '../../config/sales';
import { getPayments } from '../../api/sales';
import { AddPaymentModal } from '../../components/Paiements/AddPaymentModal';
import { UpdatePaymentModal } from '../../components/Paiements/UpdatePaymentModal';
import { FiltersList } from '../../components/FiltersList';

export const Paiements = () => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [paymentData, setPaymentData] = useState(null);

    const [filters, setFilters] = useState([]);
    const [sorting, setSorting] = useState({
        key: 'payment_date',
        type: 'desc',
    });

    // GET PAYMENTS
    const { data, fetchNextPage, isLoading, isFetching } = useInfiniteQuery(
        ['payments', getFiltersSortingQuery(filters, sorting)],
        getPayments,
        {
            getNextPageParam: (lastPage) => {
                return lastPage.has_next ? parseInt(lastPage.current_page) + 1 : undefined;
            },
        }
    );

    return (
        <PageContent>
            <AddPaymentModal show={showAddModal} onClose={() => setShowAddModal(false)} />
            <UpdatePaymentModal
                show={paymentData !== null}
                onClose={() => setPaymentData(null)}
                data={paymentData}
            />
            <div className="page-title-container">
                <div className="page-title">Gestion des paiements</div>
                <div className="title-buttons">
                    <Button
                        variant="green-full"
                        label="Ajouter un paiement"
                        onClick={() => setShowAddModal(true)}
                    />
                </div>
            </div>
            <FiltersList filters={filters} setFilters={setFilters} headers={paymentsHeaders} />
            <Table
                tableStyles={{
                    maxHeight: `calc(100vh - 180px)`,
                }}
                filters={filters}
                onFiltersChange={setFilters}
                style={{ marginTop: 24 }}
                headers={paymentsHeaders}
                data={data?.pages.map((p) => p.result).flat() || []}
                onLineSelect={(index, data) => {
                    setPaymentData(data);
                }}
                loadMore={() => fetchNextPage()}
                sorting={sorting}
                onSortingChange={setSorting}
                loading={isLoading || isFetching}
            />
        </PageContent>
    );
};
