import React, { useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { getProducts, addPanier } from '../../api/products';
import { PageContent } from '../../components/PageContent';
import { getFiltersSortingQuery } from '../../utils';
import { Table } from '../../components/Table';
import { FiltersList } from '../../components/FiltersList';
import { productsHeaders } from '../../config/products';
import { Button } from '../../components/Button';
import { toast } from 'react-toastify';
import { NewPanierModal } from '../../components/Products/NewPanierModal';

export const SaisiePanier = () => {
    const [filters, setFilters] = useState([]);
    const [sorting, setSorting] = useState({});
    const [productsData, setProductsData] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [newPanierLoading, setNewPanierLoading] = useState(false);

    const [panierDetails, setPanierDetails] = useState();

    const queryClient = useQueryClient();

    // GET PRODUCTS
    const {
        data: rawProductsData,
        fetchNextPage,
        isLoading,
        isFetching,
    } = useInfiniteQuery(['products', getFiltersSortingQuery(filters, sorting)], getProducts, {
        getNextPageParam: (lastPage) => {
            return lastPage.has_next ? parseInt(lastPage.current_page) + 1 : undefined;
        },
    });

    useEffect(() => {
        setProductsData(rawProductsData?.pages.map((p) => p.result).flat() || []);
    }, [rawProductsData]);

    // CREATE PANIER
    const createMutation = useMutation(addPanier, {
        onSuccess: (data) => {
            console.log('creation successful: ', data);
            queryClient.invalidateQueries('paniers');
            toast.success('Panier a été créée avec succès!');
            reset();
        },
        onError: (err) => {
            console.log('update failed with error: ', err.response);
            toast.error('update failed with error: ' + JSON.stringify(err.response?.data));
            reset();
        },
    });

    const reset = () => {
        setShowDetailsModal(false);
        setPanierDetails({
            selling_date: new Date().toISOString(),
        });
        setSelectedProducts([]);
        setNewPanierLoading(false);
    };

    const handleProductDelete = (i) => {
        setSelectedProducts((old) => {
            const newProducts = [...old];
            newProducts.splice(i, 1);
            return newProducts;
        });
    };

    const handleProductChange = (val, i, key) => {
        setSelectedProducts((old) => {
            const newProducts = [...old];
            const productToUpdate = { ...newProducts[i] };
            productToUpdate[key] = val;
            productToUpdate.total_amount = productToUpdate.selling_price * productToUpdate.quantity;
            newProducts[i] = productToUpdate;
            return newProducts;
        });
    };

    return (
        <PageContent style={{ paddingBottom: 100 }}>
            <NewPanierModal
                loading={newPanierLoading}
                setLoading={setNewPanierLoading}
                show={showDetailsModal}
                onClose={() => setShowDetailsModal(false)}
                data={panierDetails}
                onChange={(data) => {
                    createMutation.mutate({
                        ...data,
                        products: selectedProducts.map((p) => p.id_product),
                    });
                }}
            />
            <div className="page-title-container">
                <div className="page-title">Nouveau panier</div>
            </div>

            <FiltersList filters={filters} setFilters={setFilters} headers={productsHeaders} />
            <Table
                tableStyles={{
                    maxHeight: `235px`,
                }}
                filters={filters}
                onFiltersChange={setFilters}
                style={{ marginTop: 24 }}
                headers={productsHeaders}
                data={productsData}
                onLineSelect={(id, data) => {
                    setSelectedProducts((old) => {
                        const newData = [...old];
                        const newProductIndex = old.findIndex(
                            (el) => el.id_product === data.id_product
                        );
                        if (newProductIndex !== -1) {
                            newData.splice(newProductIndex, 1);
                        } else {
                            newData.push(data);
                        }
                        return newData;
                    });
                }}
                selectedLines={selectedProducts.map((el) => {
                    const index = productsData.findIndex((p) => p.id_product === el.id_product);
                    return index === -1 || index;
                })}
                loadMore={() => fetchNextPage()}
                sorting={sorting}
                onSortingChange={setSorting}
                loading={isLoading || isFetching}
            />
            {selectedProducts?.length > 0 && (
                <>
                    <div className="separator" />
                    <div className="page-title-container">
                        <div className="page-title">Produits séléctionnés</div>
                    </div>
                    <Table
                        style={{ marginTop: 32 }}
                        tableStyles={{ maxHeight: 'unset' }}
                        headers={productsHeaders}
                        data={selectedProducts}
                        canEdit
                        onDeleteLine={handleProductDelete}
                        onCellEdit={handleProductChange}
                    />
                    <div className="bottom-bar">
                        <div className="left-part"></div>
                        <div className="right-part">
                            <Button
                                icon="skip_next"
                                onClick={() => setShowDetailsModal(true)}
                                variant="green-full"
                                label="étape suivante"
                            />
                        </div>
                    </div>
                </>
            )}
        </PageContent>
    );
};
