import { authAxios } from '../auth';

export const getSuppliers = async ({ pageParam, queryKey }) => {
    const queryString = queryKey[1]?.length > 1 ? queryKey[1] : '';
    const res = await authAxios(`/suppliers/?page_size=50&page=${pageParam || 1}${queryString}`);
    return res?.data;
};

export const addSupplier = async (newSupplier) => {
    const res = await authAxios.post('/suppliers/', newSupplier);
    return res?.data;
};

export const updateSupplier = async (supplierData) => {
    const res = await authAxios.put(`/suppliers/${supplierData.id_supplier}/`, supplierData);
    return res?.data;
};

export const deleteSupplier = async (supplierID) => {
    const res = await authAxios.delete(`/suppliers/${supplierID}/`);
    return res;
};
