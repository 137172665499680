import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { updateSupplier, deleteSupplier } from '../../api/suppliers';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { InfoModalContent } from '../InfoModalContent';
import { suppliersHeaders } from '../../config/suppliers';
import { deleteEmptyValues } from '../../utils';

export const UpdateSupplierModal = ({ show, onClose, data }) => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const queryClient = useQueryClient();

    const [updateData, setUpdateData] = useState();

    useEffect(() => {
        if (data) setUpdateData({ ...data });
        else setUpdateData(null);
    }, [data]);

    const closeAndReset = () => {
        setSubmitLoading(false);
        setIsUpdating(false);
        onClose();
    };

    // UPDATE SUPPLIER
    const updateMutation = useMutation(updateSupplier, {
        onSuccess: (data) => {
            console.log('update successful: ', data);
            queryClient.invalidateQueries('suppliers');
            toast.success('Fournisseur a été modifié avec succès!');
            closeAndReset();
        },
        onError: (err) => {
            console.log('update failed with error: ', err.response);
            toast.error('update failed with error: ' + JSON.stringify(err.response?.data));
            closeAndReset();
        },
    });

    // DELETE SUPPLIER
    const deleteMutation = useMutation(deleteSupplier, {
        onSuccess: (res) => {
            console.log('delete mutation successful: ', res);
            queryClient.invalidateQueries('suppliers');
            toast.success('Fournisseur a été supprimé avec succès!');
            closeAndReset();
        },
        onError: (err) => {
            console.log('delete failed with error: ', err.response);
            toast.error('delete failed with error: ' + JSON.stringify(err.response?.data));
            closeAndReset();
        },
    });

    const isSubmitDisabled = () => {
        if (!updateData?.name) return true;
        return false;
    };

    const updateButtons = (
        <>
            <Button
                label="annuler"
                onClick={() => {
                    setIsUpdating(false);
                    setUpdateData(data);
                }}
            />
            <Button
                variant="green-full"
                onClick={() => {
                    setSubmitLoading(true);
                    updateMutation.mutate(deleteEmptyValues({ ...updateData }));
                }}
                label={submitLoading ? 'En cours ...' : 'confirmer'}
                loading={submitLoading}
                disabled={isSubmitDisabled() || submitLoading}
            />
        </>
    );

    const viewButtons = (
        <>
            <Button
                variant="red-full"
                onClick={() => {
                    setSubmitLoading(true);
                    deleteMutation.mutate(data.id_supplier);
                }}
                label={submitLoading ? 'En cours ...' : 'supprimer'}
                loading={submitLoading}
                disabled={submitLoading}
            />
            <Button variant="green-full" onClick={() => setIsUpdating(true)} label="modifier" />
        </>
    );

    return (
        <Modal
            closeOnOutsideClick={!isUpdating}
            show={show}
            onClose={closeAndReset}
            title={isUpdating ? 'Modifier un client' : ''}
            buttons={isUpdating ? updateButtons : viewButtons}
        >
            <InfoModalContent
                write={isUpdating}
                data={isUpdating ? updateData : data}
                setData={setUpdateData}
                lines={suppliersHeaders}
            />
        </Modal>
    );
};
