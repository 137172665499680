import React from 'react';
import { Button } from './Button';
import { Modal } from './Modal';
import styled from 'styled-components';

const ModalContent = styled.div`
    padding: 32px;
    > div {
        font-size: 16px;
        text-align: center;
        margin-bottom: 16px;
    }
    .to-delete {
        padding: 16px 8px;
        background: #fff1f1;
        border-radius: 4px;
        font-size: 16px;
        text-align: center;
        font-weight: bold;
        border: 2px solid #ffc6c6;
    }
`;

export const DeleteConfirmationModal = ({ content, onDelete, onClose }) => {
    const buttons = (
        <>
            <Button onClick={onClose} label="Annuler"></Button>
            <Button onClick={onDelete} variant="red-full" label="Oui, je suis sûr"></Button>
        </>
    );

    return (
        <Modal buttons={buttons} show={true} onClose={onClose} title={'Confirmation'}>
            <ModalContent>
                <div>Êtes-vous sûr de vouloir supprimer</div>
                <div className="to-delete">{content}</div>
            </ModalContent>
        </Modal>
    );
};
