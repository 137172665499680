import React, { useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { PageContent } from '../../components/PageContent';
import { Button } from '../../components/Button';
import { Table } from '../../components/Table';
import { getFiltersSortingQuery } from '../../utils';
import {
    purchasesHeaders,
    productsHeaders,
    validatePurchaseData,
    preparePurchaseData,
} from '../../config/purchases';
import { FiltersList } from '../../components/FiltersList';
import { PurchaseDetailsModal } from '../../components/Achats/PurchaseDetailsModal';
import { getPurchases, updatePurchase } from '../../api/purchases';
import { toast } from 'react-toastify';

export const GestionAchats = () => {
    const [filters, setFilters] = useState([]);
    const [sorting, setSorting] = useState({
        key: 'buying_date',
        type: 'desc',
    });
    const [purchaseData, setPurchaseData] = useState({});
    const [selectedLines, setSelectedLines] = useState([]);
    const [productsWriteable, setProductsWriteable] = useState(false);
    const [tempPurchaseData, setTempPurchaseData] = useState({});
    const [purchasesData, setPurchasesData] = useState([]);
    const [saveProductsLoading, setSaveProductsLoading] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);

    const queryClient = useQueryClient();

    useEffect(() => {
        setPurchaseData({});
        setSelectedLines(null);
    }, [sorting, filters]);

    // GET PURCHASES
    const { data, fetchNextPage, isLoading, isFetching } = useInfiniteQuery(
        ['purchases', getFiltersSortingQuery(filters, sorting)],
        getPurchases,
        {
            getNextPageParam: (lastPage) => {
                return lastPage.has_next ? parseInt(lastPage.current_page) + 1 : undefined;
            },
        }
    );

    useEffect(() => {
        setPurchasesData(data?.pages.map((p) => p.result).flat() || []);
    }, [data]);

    useEffect(() => {
        const data = purchasesData[selectedLines];
        if (data) setPurchaseData(getPurchaseData(data));
    }, [purchasesData, selectedLines]);

    // UPDATE PURCHASE
    const updateMutation = useMutation(updatePurchase, {
        onSuccess: (data) => {
            console.log('update successful: ', data);
            queryClient.invalidateQueries('purchases');
            toast.success('Achat a été modifiée avec succès!');
            setProductsWriteable(false);
            setSaveProductsLoading(false);
        },
        onError: (err) => {
            console.log('update failed with error: ', err.response);
            toast.error('update failed with error: ' + JSON.stringify(err.response?.data));
            setProductsWriteable(false);
            setSaveProductsLoading(false);
        },
    });

    const handleProductDelete = (i) => {
        setTempPurchaseData((old) => {
            const products = [...old.products];
            products.splice(i, 1);
            return { ...old, products };
        });
    };

    const handleNewProduct = () => {
        setTempPurchaseData((old) => ({ ...old, products: [...old.products, {}] }));
    };

    const handleProductChange = (val, i, key) => {
        setTempPurchaseData((old) => {
            const products = [...old.products];
            const productToUpdate = { ...products[i] };
            productToUpdate[key] = val;
            products[i] = productToUpdate;
            return getPurchaseData({ ...old, products });
        });
    };

    const handleSaveProducts = () => {
        setSaveProductsLoading(true);
        const dataToSet = validatePurchaseData(getPurchaseData(tempPurchaseData));
        setPurchaseData(dataToSet);

        updateMutation.mutate({
            ...preparePurchaseData(dataToSet),
        });
    };

    const getPurchaseData = (data) => {
        const products = data.products.map((p) => ({
            ...p,
            total_amount: p.buying_price_ttc * p.quantity,
        }));
        return { ...data, products };
    };

    const getProductsHeaders = () => {
        const newProductsHeaders = [...productsHeaders];
        newProductsHeaders[0].onChange = (value, lineIndex) => {
            setTempPurchaseData((old) => {
                const products = [...old?.products];
                const newProduct = {
                    ...value,
                    total_amount: value.buying_price_ttc * value.quantity,
                };
                products[lineIndex] = newProduct;
                return { ...old, products };
            });
        };
        return newProductsHeaders;
    };
    return (
        <PageContent style={{ paddingBottom: 100 }}>
            <PurchaseDetailsModal
                show={showDetailsModal}
                onClose={() => {
                    setShowDetailsModal(false);
                }}
                data={purchaseData}
            />
            <div className="page-title-container">
                <div className="page-title">Gestion des achats</div>
            </div>
            <FiltersList filters={filters} setFilters={setFilters} headers={purchasesHeaders} />
            <Table
                tableStyles={{
                    maxHeight: `235px`,
                }}
                filters={filters}
                onFiltersChange={setFilters}
                style={{ marginTop: 24 }}
                headers={purchasesHeaders}
                data={purchasesData}
                onLineSelect={(id, data) => {
                    setPurchaseData(getPurchaseData(data));
                    setSelectedLines([id]);
                }}
                selectedLines={selectedLines}
                loadMore={() => fetchNextPage()}
                sorting={sorting}
                onSortingChange={setSorting}
                loading={isLoading || isFetching}
            />
            {purchaseData.id_bc && (
                <>
                    <div className="separator" />
                    <div className="page-title-container">
                        <div className="page-title">
                            <span style={{ opacity: 0.2, fontWeight: 400 }}>BL #</span>{' '}
                            {purchaseData.num_bl}
                        </div>
                        <div className="title-buttons">
                            {productsWriteable ? (
                                <>
                                    <Button
                                        label="annuler"
                                        onClick={() => setProductsWriteable(false)}
                                    />

                                    <Button
                                        variant="green-full"
                                        label="Enregistrer"
                                        disabled={saveProductsLoading}
                                        loading={saveProductsLoading}
                                        onClick={handleSaveProducts}
                                    />
                                </>
                            ) : (
                                <>
                                    <Button
                                        variant="green"
                                        label="modifier produits"
                                        onClick={() => {
                                            setTempPurchaseData({ ...purchaseData });
                                            setProductsWriteable(true);
                                        }}
                                    />
                                    <Button
                                        onClick={() => setShowDetailsModal(true)}
                                        variant="green"
                                        label="modifier informations"
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <Table
                        key={purchaseData.id_bc + productsWriteable ? '_temp' : ''}
                        style={{ marginTop: 32 }}
                        tableStyles={{ maxHeight: 'unset' }}
                        headers={getProductsHeaders()}
                        data={
                            productsWriteable
                                ? [...tempPurchaseData.products]
                                : [...purchaseData.products]
                        }
                        canEdit={productsWriteable}
                        onDeleteLine={productsWriteable && handleProductDelete}
                        onCellEdit={handleProductChange}
                    />
                    {productsWriteable && (
                        <Button
                            style={{ marginTop: 16 }}
                            onClick={handleNewProduct}
                            label="Ajouter un produit"
                        />
                    )}
                    <div className="bottom-bar">
                        <div className="left-part">
                            <Button variant="red" label="supprimer cet achat" />
                        </div>
                        <div className="right-part" />
                    </div>
                </>
            )}
        </PageContent>
    );
};
