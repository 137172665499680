import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { addClient } from '../../api/clients';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { InfoModalContent } from '../InfoModalContent';
import { clientsHeaders } from '../../config/clients';

export const AddClientModal = ({ show, onClose }) => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const queryClient = useQueryClient();

    const [data, setData] = useState({});

    const closeModal = () => {
        setData({});
        onClose();
    };

    const createMutation = useMutation(addClient, {
        onSuccess: (data) => {
            console.log('create successful: ', data);
            setSubmitLoading(false);
            queryClient.invalidateQueries('clients');
            toast.success('Client a été créé avec succès!');
            closeModal();
        },
        onError: (err) => {
            console.log('create failed with error: ', err.response);
            setSubmitLoading(false);
            toast.error('create failed with error: ' + JSON.stringify(err.response?.data));
            closeModal();
        },
    });

    const isSubmitDisabled = () => {
        if (!data?.name) return true;
        return false;
    };

    return (
        <Modal
            closeOnOutsideClick={false}
            show={show}
            onClose={closeModal}
            title="Nouveau client"
            buttons={
                <>
                    <Button label="annuler" onClick={closeModal} />
                    <Button
                        variant="green-full"
                        onClick={() => {
                            setSubmitLoading(true);

                            const newData = { ...data };
                            if (!newData?.comments) delete newData.comments;
                            if (!newData?.address) delete newData.address;
                            if (!newData?.city) delete newData.city;
                            if (!newData?.phone) delete newData.phone;
                            createMutation.mutate(newData);
                        }}
                        label={submitLoading ? 'En cours ...' : 'Ajouter'}
                        loading={submitLoading}
                        disabled={isSubmitDisabled() || submitLoading}
                    />
                </>
            }
        >
            <InfoModalContent write={true} data={data} setData={setData} lines={clientsHeaders} />
        </Modal>
    );
};
