import { authAxios } from '../auth';

export const getClients = async ({ pageParam, queryKey }) => {
    const queryString = queryKey[1]?.length > 1 ? queryKey[1] : '';
    const res = await authAxios(`/clients/?page_size=50&page=${pageParam || 1}${queryString}`);
    return res?.data;
};

export const addClient = async (newClient) => {
    const res = await authAxios.post('/clients/', newClient);
    return res?.data;
};

export const updateClient = async (clientData) => {
    const res = await authAxios.put(`/clients/${clientData.id_client}/`, clientData);
    return res?.data;
};

export const deleteClient = async (clientID) => {
    const res = await authAxios.delete(`/clients/${clientID}/`);
    return res;
};

export const getSituation = async ({ queryKey }) => {
    const res = await authAxios(`/situation/?${queryKey[1]}`);
    return res?.data;
};

export const getCategories = async () => {
    const res = await authAxios(`/categories/`);
    return res?.data;
};

export const updateCategory = async (categoryData) => {
    const id = categoryData.id_category;
    delete categoryData.id_category;
    const res = await authAxios.put(`/categories/${id}/`, categoryData);
    return res?.data;
};

export const addCategory = async (newPanier) => {
    const res = await authAxios.post('/categories/', newPanier);
    return res?.data;
};
