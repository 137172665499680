import React from 'react';
import styled from 'styled-components';

const zeroPad = (num) => {
    let n = num;
    if (typeof num !== 'number') n = parseInt(num);
    return n <= 9 ? '0' + n : n;
};

export const formatDate = (date) => {
    const newDate = getDate(date);
    const time = getTime(date);
    return newDate + ' ' + time;
};

export const getDate = (dateISO) => {
    const date = new Date(dateISO);
    const day = !isNaN(zeroPad(date.getDate())) ? zeroPad(date.getDate()) : '__';
    const month = !isNaN(zeroPad(date.getMonth())) ? zeroPad(date.getMonth() + 1) : '__';
    const year = !isNaN(zeroPad(date.getFullYear())) ? zeroPad(date.getFullYear()) : '____';

    return `${day}/${month}/${year}`;
};

export const getTime = (dateISO) => {
    const date = new Date(dateISO);
    const hours = !isNaN(zeroPad(date.getHours())) ? zeroPad(date.getHours()) : '__';
    const minutes = !isNaN(zeroPad(date.getMinutes())) ? zeroPad(date.getMinutes()) : '__';

    return `${hours}h${minutes}`;
};

export const getCorrectValue = (value, format, currentValue) => {
    if (!format || format === '' || format === 'string') {
        return value;
    }
    if (value === '') return '';

    if (format === 'number') {
        const newValue = parseFloat(value);
        if (isNaN(newValue)) return currentValue;
        return newValue;
    }
    if (format === 'amount' || format === 'percent') {
        const val = String(value).replace(',', '.');
        const newValue = parseFloat(val);
        if (isNaN(newValue)) return currentValue;
        return newValue;
    }
};

export const getDisplayValue = (value, format) => {
    if (value === undefined || value === null || value === '') return '';
    if (format === 'amount') {
        const valAsNumber = typeof value === 'number' ? value : parseFloat(value);
        return valAsNumber
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    if (format === 'percent') {
        return value + '%';
    }

    if (format === 'date') {
        return formatDate(value);
    }
    return value;
};

const FilterKey = styled.span`
    display: inline-block;
    background: #0090e1;
    color: white;
    margin: 0px;
    margin-right: 4px;
    margin-left: -8px;
    padding: 4px 8px;
    border-radius: 4px;
`;

const PillContent = ({ firstValue, secondValue, label, firstWord, secondWord }) => {
    return (
        <>
            <span style={{ marginRight: 8 }}>
                <FilterKey>{label}</FilterKey> {firstWord}{' '}
            </span>
            <b style={{ marginRight: 8 }}>{firstValue}</b> {secondWord}
            {secondValue && <b style={{ marginLeft: 8 }}>{secondValue}</b>}
        </>
    );
};

const RangeFilterPillContent = ({ to, from, label, isDate }) => {
    if (!to && to !== 0 && (from || from === 0))
        return (
            <PillContent
                firstValue={isDate ? formatDate(from) : from}
                label={label}
                firstWord={isDate ? 'après' : 'superieur à'}
            />
        );

    if ((to || to === 0) && !from && from !== 0)
        return (
            <PillContent
                firstValue={isDate ? formatDate(to) : to}
                label={label}
                firstWord={isDate ? 'avant' : 'inferieur à'}
            />
        );

    return (
        <PillContent
            firstValue={isDate ? formatDate(from) : from}
            secondValue={isDate ? formatDate(to) : to}
            label={label}
            secondWord={'&'}
            firstWord="entre"
        />
    );
};

export const getFilterLabel = (filter, headers) => {
    const secondValue = filter.secondValue ? filter.secondValue : '';
    const type = headers.find((el) => el.accessor === filter.key)?.format;

    if (type === 'number' || type === 'amount' || type === 'percent' || type === 'date') {
        return (
            <RangeFilterPillContent
                label={filter.label}
                from={filter.value}
                to={filter.secondValue}
                isDate={type === 'date'}
            />
        );
    }

    return (
        <>
            <span style={{ marginRight: 8 }}>
                <FilterKey>{filter.label}</FilterKey> contient{' '}
            </span>
            <b>
                {filter.value}
                {secondValue}
            </b>
        </>
    );
};

export const filterData = (data, filters, sortingKey, sortingType, productsHeaders) => {
    let newData = [...data];
    if (sortingKey) {
        newData = [...newData].sort((a, b) => {
            const descMultiplier = sortingType === 'desc' ? -1 : 1;
            const firstElement =
                typeof a[sortingKey] === 'string' ? a[sortingKey].toLowerCase() : a[sortingKey];
            const secondElement =
                typeof b[sortingKey] === 'string' ? b[sortingKey].toLowerCase() : b[sortingKey];

            return (secondElement > firstElement ? -1 : 1) * descMultiplier;
        });
    }

    filters.forEach((filter) => {
        const type = productsHeaders.find((el) => el.accessor === filter.key)?.format;
        if (type === 'number' || type === 'amount' || type === 'percent') {
            if (
                (filter.value || filter.value === 0) &&
                !filter.secondValue &&
                filter.secondValue !== 0
            ) {
                newData = newData.filter((el) => el[filter.key] >= filter.value);
            } else if (
                !filter.value &&
                filter.value !== 0 &&
                (filter.secondValue || filter.secondValue === 0)
            )
                newData = newData.filter((el) => el[filter.key] <= filter.secondValue);
            else if (
                (filter.value || filter.value === 0) &&
                (filter.secondValue || filter.secondValue === 0)
            )
                newData = newData.filter(
                    (el) => el[filter.key] <= filter.secondValue && el[filter.key] >= filter.value
                );
        } else
            newData = newData.filter((el) => {
                return el[filter.key].toLowerCase().includes(filter.value.toLowerCase());
            });
    });

    return newData;
};

export const handleFilters = (filter, setFilters) => {
    setFilters((filters) => {
        const newFilters = [...filters];
        const indexOfFilter = filters.findIndex((el) => el.key === filter.key);
        const withValue =
            filter.value || filter.value === 0 || filter.secondValue || filter.secondValue === 0;
        if (indexOfFilter === -1) {
            if (withValue) newFilters.push(filter);
        } else {
            if (withValue) newFilters[indexOfFilter] = filter;
            else newFilters.splice(indexOfFilter, 1);
        }
        return newFilters;
    });
};

export const getFiltersSortingQuery = (filters, sorting) => {
    const queries = [];

    if (sorting?.key) {
        const sortingModifier = sorting?.type === 'asc' ? '' : '-';
        queries.push(`sort=${sortingModifier}${sorting?.key}`);
    }

    filters.forEach((f) => {
        if (f.range) {
            if (f.value || f.value === 0) queries.push(`${f.key}_min=${f.value}`);
            if (f.secondValue || f.secondValue === 0) queries.push(`${f.key}_max=${f.secondValue}`);
        } else {
            queries.push(`${f.key}=${f.value}`);
        }
    });
    return '&' + queries.join('&');
};

export const deleteEmptyValues = (data) => {
    const newData = { ...data };
    Object.keys(newData).forEach((key) => {
        if (newData[key] === '') delete newData[key];
    });
    return newData;
};

export const convertDownloadCSV = (headers, data) => {
    const newData = [];
    newData.push(headers.map((h) => h.label));
    data.forEach((d) => {
        const dataLine = [];
        headers.forEach((h) => {
            dataLine.push(getDisplayValue(h.getter ? h.getter(d) : d[h.accessor], h.format));
        });
        newData.push(dataLine);
    });

    let csvContent = 'data:text/csv;charset=utf-8,' + '\ufeff';

    newData.forEach(function (rowArray) {
        let row = rowArray.join(';');
        csvContent += row + '\r\n';
    });

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'table_export.csv');
    document.body.appendChild(link);

    console.log(encodedUri);
    link.click();
};
