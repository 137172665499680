import React, { useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { getDisplayValue, getCorrectValue } from '../utils';
import { DateInput } from './DateInput';

const numberFormat = css`
    text-align: right;
    letter-spacing: 0.05em;
`;

const StyledInput = styled.input`
    display: inline-block;
    height: 35px;
    padding: 0 16px;
    border-radius: 3px;
    background: #f0f2f4;
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    ${({ format }) =>
        (format === 'number' || format === 'amount' || format === 'percent') && numberFormat}
    ${({ width }) => `
    width: ${width ? width + 'px' : '100%'}
  `}
`;

export const Input = ({
    width,
    format,
    onChange,
    value: rawValue,
    focusOnMount,
    onFocus,
    onBlur,
    onActualChange,
    disableInput,
    ...rest
}) => {
    const [showRealValue, setShowRealValue] = useState(false);
    const [value, setValue] = useState();
    const ref = useRef();

    useEffect(() => {
        setValue(rawValue);
    }, [rawValue]);

    useEffect(() => {
        if (showRealValue) {
            ref.current.select();
        }
    }, [showRealValue]);

    useEffect(() => {
        if (focusOnMount) {
            ref.current.select();
        }
    }, [focusOnMount]);

    const getValue = () => {
        const val = showRealValue ? value : getDisplayValue(value, format);
        if (!val && val !== 0) return '';
        return val;
    };

    const handleOnBlur = (e) => {
        const correctValue = getCorrectValue(e.target.value, format, rawValue);
        onChange && onChange(correctValue);
        setValue(correctValue);
        setShowRealValue(false);
        onBlur && onBlur(e);
    };

    return format === 'date' ? (
        <DateInput
            showTime
            value={rawValue}
            onChange={(val) => {
                onChange && onChange(val);
            }}
        />
    ) : (
        <StyledInput
            width={width}
            onFocus={(e) => {
                setShowRealValue(true);
                onFocus && onFocus(e);
            }}
            ref={ref}
            onBlur={handleOnBlur}
            onChange={(e) => {
                !disableInput && setValue(e.target.value);
                !disableInput && onActualChange && onActualChange(e.target.value);
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    ref.current.blur();
                    handleOnBlur(e);
                }
            }}
            value={getValue()}
            format={format}
            {...rest}
        />
    );
};
