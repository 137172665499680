import React from 'react';
import styled from 'styled-components';

const StyledPageContent = styled.div`
    .page-title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .page-title {
            font-size: 22px;
            font-weight: 600;
            margin-right: 8px;
        }
        .title-buttons {
            display: flex;
            align-items: center;
            button {
                margin-left: 16px;
            }
        }
    }
    .filters-container {
        margin: 16px 0 -8px;
        > div {
            margin-bottom: 8px;
            margin-right: 16px;
        }
    }
    .separator {
        height: 1px;
        background: #d3d7de;
        margin: 32px 0px;
    }

    .table-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title-part {
            font-size: 18px;
            font-weight: 500;
        }
        .info-part {
            display: flex;
            font-size: 14px;
            .info-label {
                color: #0090e1;
                border-bottom: 1px solid #0090e180;
                margin-right: 16px;
                font-weight: 600;
            }
        }
    }

    .bottom-bar {
        position: fixed;
        width: calc(100vw - 250px);
        height: 70px;
        bottom: 0;
        left: 250px;
        background: #f0f2f4;
        box-shadow: 0px 2px 90px rgb(181, 189, 197), inset 0px 1px 0px white;
        z-index: 4;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 60px;
        padding-left: 50px;
        .footer-info-line {
            display: inline-flex;
            align-items: center;
            margin-right: 32px;
            .info-line-key {
                font-weight: bold;
                margin-right: 16px;
                border-bottom: 1px solid lightgray;
                &.blue {
                    color: #0090e1;
                    border-bottom: 1px solid #0090e180;
                }
            }
        }
        .buttons-container {
            display: flex;
            button {
                margin-left: 16px;
            }
        }
    }
`;

export const PageContent = ({ style, children }) => {
    return <StyledPageContent style={style}>{children}</StyledPageContent>;
};
