import React from 'react';
import styled, { css } from 'styled-components';

const smallStyles = css`
    height: 22px;
    .label-container {
        padding: 0 6px;
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 0.03em;
        font-weight: 700;
    }
`;

const StyledPill = styled.div`
    border: 1px solid #0090e1;
    display: inline-flex;
    border-radius: 5px;
    height: 26px;
    align-items: stretch;
    justify-content: center;
    color: #0090e1;
    font-weight: 500;
    cursor: pointer;
    .close-icon {
        border-left: 1px solid #0090e1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 26px;
    }
    .label-container {
        padding: 0 8px;
        display: flex;
        align-items: center;
    }
    ${({ small }) => small && smallStyles};
`;

export const Pill = ({ label, withDelete, ...rest }) => {
    return (
        <StyledPill {...rest}>
            <div className="label-container">{label}</div>
            {withDelete && (
                <div className="close-icon">
                    <span
                        className="material-icons-round"
                        style={{ fontSize: 14, fontWeight: 700 }}
                    >
                        close
                    </span>
                </div>
            )}
        </StyledPill>
    );
};
