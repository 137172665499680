import React, { useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { PageContent } from '../../components/PageContent';
import { Button } from '../../components/Button';
import { Table } from '../../components/Table';
import { paniersHeaders, productsHeadersPaniers } from '../../config/products';
import { getPaniers, updatePaniers } from '../../api/products';
import { toast } from 'react-toastify';
import { PanierDetailsModal } from '../../components/Products/PanierDetailsModal';

export const PaniersProduits = () => {
    const [panierData, setPanierData] = useState({});
    const [selectedLines, setSelectedLines] = useState([]);
    const [productsWritable, setProductsWriteable] = useState(false);
    const [tempPanierData, setTempPanierData] = useState({});
    const [paniersData, setPaniersData] = useState([]);
    const [saveProductsLoading, setSaveProductsLoading] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const queryClient = useQueryClient();

    // GET CATEGORIES
    const { data, fetchNextPage, isLoading, isFetching } = useInfiniteQuery(
        ['paniers'],
        getPaniers,
        {
            getNextPageParam: (lastPage) => {
                return lastPage.has_next ? parseInt(lastPage.current_page) + 1 : undefined;
            },
        }
    );

    useEffect(() => {
        setPaniersData(data?.pages.flat() || []);
    }, [data]);

    useEffect(() => {
        const data = paniersData[selectedLines];
        if (data) setPanierData(data);
    }, [paniersData, selectedLines]);

    // UPDATE CATEGORY
    const updateMutation = useMutation(updatePaniers, {
        onSuccess: (data) => {
            console.log('update successful: ', data);
            queryClient.invalidateQueries('paniers');
            toast.success('Vente a été modifiée avec succès!');
            setPanierData({});
            setSelectedLines([]);
            setProductsWriteable(false);
            setSaveProductsLoading(false);
        },
        onError: (err) => {
            console.log('update failed with error: ', err.response);
            toast.error('update failed with error: ' + JSON.stringify(err.response?.data));
            setProductsWriteable(false);
            setSaveProductsLoading(false);
        },
    });

    const handleClientDelete = (i) => {
        setTempPanierData((old) => {
            const products = [...old.products];
            products.splice(i, 1);
            return { ...old, products };
        });
    };

    const handleNewClient = () => {
        setTempPanierData((old) => ({ ...old, products: [...old.products, {}] }));
    };

    const handleClientChange = (val, i, key) => {
        setTempPanierData((old) => {
            const products = [...old.products];
            const productsToUpdate = { ...products[i] };
            productsToUpdate[key] = val;
            products[i] = productsToUpdate;
            return { ...old, products };
        });
    };

    const handleSaveProducts = () => {
        setSaveProductsLoading(true);
        const dataToSet = tempPanierData;
        setPanierData(dataToSet);
        dataToSet.products = dataToSet.products.map((product) => product.id_product);
        updateMutation.mutate(dataToSet);
    };

    const getProductsHeaders = () => {
        const newProductsHeaders = [...productsHeadersPaniers];
        newProductsHeaders[0].onChange = (value, lineIndex) => {
            setTempPanierData((old) => {
                const products = [...old?.products];
                products[lineIndex] = value;
                return { ...old, products };
            });
        };
        return newProductsHeaders;
    };

    return (
        <PageContent style={{ paddingBottom: 300 }}>
            <PanierDetailsModal
                show={showDetailsModal}
                onClose={() => {
                    setPanierData({});
                    setSelectedLines([]);
                    setShowDetailsModal(false);
                }}
                data={panierData}
            />
            <div className="page-title-container">
                <div className="page-title">Paniers de produits</div>
            </div>
            <Table
                tableStyles={{
                    maxHeight: `235px`,
                }}
                style={{ marginTop: 24 }}
                headers={paniersHeaders}
                data={paniersData}
                onLineSelect={(id, data) => {
                    setPanierData(data);
                    setSelectedLines([id]);
                }}
                selectedLines={selectedLines}
                loadMore={() => fetchNextPage()}
                loading={isLoading || isFetching}
            />
            {panierData?.name && (
                <>
                    <div className="separator" />
                    <div className="page-title-container">
                        <div className="page-title">{panierData.name}</div>
                        <div className="title-buttons">
                            {productsWritable ? (
                                <>
                                    <Button
                                        label="annuler"
                                        onClick={() => setProductsWriteable(false)}
                                    />

                                    <Button
                                        variant="green-full"
                                        label="Enregistrer"
                                        disabled={saveProductsLoading}
                                        loading={saveProductsLoading}
                                        onClick={handleSaveProducts}
                                    />
                                </>
                            ) : (
                                <>
                                    <Button
                                        variant="green"
                                        label="modifier products"
                                        onClick={() => {
                                            setTempPanierData({ ...panierData });
                                            setProductsWriteable(true);
                                        }}
                                    />
                                    <Button
                                        onClick={() => setShowDetailsModal(true)}
                                        variant="green"
                                        label="modifier informations"
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <Table
                        key={panierData.name + productsWritable ? '_temp' : ''}
                        style={{ marginTop: 32 }}
                        tableStyles={{ maxHeight: 'unset' }}
                        headers={getProductsHeaders()}
                        data={
                            productsWritable
                                ? [...tempPanierData.products]
                                : [...panierData.products]
                        }
                        canEdit={productsWritable}
                        onDeleteLine={productsWritable && handleClientDelete}
                        onCellEdit={handleClientChange}
                    />
                    {productsWritable && (
                        <Button
                            style={{ marginTop: 16 }}
                            onClick={handleNewClient}
                            label="Ajouter un product"
                        />
                    )}
                    <div className="bottom-bar">
                        <div className="left-part">
                            <Button variant="red" label="supprimer ce panier" />
                        </div>
                        <div className="buttons-container"></div>
                    </div>
                </>
            )}
        </PageContent>
    );
};
